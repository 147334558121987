import { Component, OnInit } from '@angular/core';
import { ApiCall } from '../services/api-call';
import { QuickQuotes } from '../models/quickquote';
import { DataAccess } from '../helpers/dataAccess';
import { FormBuilder, Validators } from '@angular/forms';
import { QuickQuotesApi } from './quick-quote-api';
@Component({
  selector: 'app-quick-quote',
  templateUrl: './quick-quote.component.html',
  styleUrls: ['./quick-quote.component.scss'],
})

export class QuickQuoteComponent implements OnInit {
  apiError:boolean=false;
  errorMsg;
  spinning: boolean = false;
  dataAccess = new DataAccess();
  userId = this.dataAccess.getLocalStorageValue('userId');
  grandTotal;
  weight;
  value;
  quantity;
  receiverStationId;
  senderStationId;
  Total = false;
  errors = false;
  errorMessage;
  public data = [];
  public data2 = [];
  public data3 = [];
  public deliveryOptionData = [];
  public haulageData = [];
  vat: any;
  insurance: any;
  constructor(private apiCall: ApiCall, private fb: FormBuilder,private quickQuotesApi:QuickQuotesApi) {}
  quickQuoteForm = this.fb.group({
    weight: ['', [Validators.required]],
    value: ['', [Validators.required]],
    quantity: ['', [Validators.required]],
  });
  
  ServiceCenter() {
   
    this.spinning = true;
    this.quickQuotesApi.getServiceCenter().subscribe((data) => {
      if ((data.Code == 200)) {
        this.spinning = false;

        this.data3 = data.Object;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }
 
  onOptionsSelected(event) {
    // console.log(this.quickQuoteModel.SenderStationId);
    // console.log(this.quickQuoteModel.ReceiverStationId);
  }
  CallQuickQuotes() {
    this.spinning = true;
    const payload = {
      IsHomeDelivery: false,
      ReceiverStationId: this.receiverStationId,
      ReceiverLocation: null,
      SenderStationId: this.senderStationId,
      SenderLocation: null,
      PreShipmentItems: [
        {
          CalculatedPrice: 0.0,
          SpecialPackageId: 0,
          Quantity: this.quantity,
          ShipmentType: 'Regular',
          Value: this.value,
          Weight: this.weight,
          WeightRange: '0',
        },
      ],
      UserId: this.userId,
      VehicleType: null,
    };

    this.quickQuotesApi.getQuickQuotes(payload).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.grandTotal = data.Object.GrandTotal;
        this.Total = true;
      } else {
        this.spinning = false;
        this.errorMessage = data.ShortDescription;
        this.errors = true;
      }
    });
  }
  
  deliveryOptions() {
    this.quickQuotesApi.getDeliveryOptions().subscribe((data) => {
      this.deliveryOptionData = data.Object;
    });
  }
  
  closePopUp() {
    this.errors = false;
    this.apiError=false;
  }

  ngOnInit(): void {
    this.ServiceCenter();
    this.deliveryOptions();
   
 
  }
}
