import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCall } from '../services/api-call';
import { MatPaginator } from '@angular/material/paginator';
export interface Information {
  position: number;
  departure: string;
  destination: string;
  status: string;
  amount: string;
  partners: string;
  date: any;
  items: string;
}

@Component({
  selector: 'app-codremittances',
  templateUrl: './codremittances.component.html',
  styleUrls: ['./codremittances.component.scss'],
})
export class CodremittancesComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  codRemittanceData;
  spinning: boolean = true;
  // responsible for table column
  displayedColumns: string[] = [
    'position',
    'create',
    'center',
    'waybill',
    'name',
    'amount',
    'user',
  ];
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // responsible for filtering
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor(private apiCall: ApiCall) {}

  ngOnInit(): void {
    this.apiCall.getCodRemmitance().subscribe((data) => {
      this.spinning = true;
      if ((data.Code == 200)) {
        this.spinning = false;
        this.codRemittanceData = data.Object;
      }
      //data source
      this.dataSource = new MatTableDataSource(this.codRemittanceData);
    });
  }
}
