import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DataAccess } from '../helpers/dataAccess';
import { ApiCall } from '../services/api-call';
import { MatPaginator } from '@angular/material/paginator';
import { AuthHelpers } from '../helpers/auth';
import { PaymentService } from '../services/payment.service';
import { General } from '../helpers/general';
import { WalletApi } from './wallet-api';
import { Flutterwave, InlinePaymentOptions, PaymentSuccessResponse } from "flutterwave-angular-v3";
declare var PaystackPop: any;

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})

export class WalletComponent implements OnInit {
  apiError;
  errorMessage;
  dataSource: MatTableDataSource<any>;
  walletData;
  walletBalance;
  money;
  walletNumber;
  walletOwnerName;
  title;
  ussdData;
  amount;
  dataAccess = new DataAccess();
  userId = this.dataAccess.getLocalStorageValue('userId');
  walletId;
  paystackAmount;
  public authHelpers = new AuthHelpers();
  testkey = 'pk_test_7140f98d7c52c6e559d00182fce18ec6f98443ae';
  testkey_live_Nigeria = 'pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b';
  testkey_live_ghana = 'pk_live_385c2ba036608c0b5fc902b32fd4b4fb07fcc01d';

  phoneNumber = this.authHelpers.getAccessToken('email');
  countryId: string = localStorage.getItem('countryId');
  currencySymbol = localStorage.getItem('currencySymbol');
  currencyCode = localStorage.getItem('currencyCode');
  customerFullName = this.dataAccess.getLocalStorageValue('CustomerFullName');
  email = this.authHelpers.getAccessToken('email');

  testkey_live = 'pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b';
  gatewaycode;
  fundWallet: boolean = false;
  paymentOptions: boolean = false;
  fund: boolean = true;
  gatewayCode: string[];
  pay: boolean = false;
  spinning: boolean = true;
  refCode: string = 'WA-' + Math.floor(Math.random() * 1000000000 + 1) + '-WEB';
  ussdPay: boolean = false;
  secondFlutterAmount;
  payment: boolean = false;
  verifyPopup: boolean = false;
  public general: General = new General();
  // responsible for table column
  displayedColumns: string[] = [
    'sn',
    'date',
    'center',
    'amount',
    'credit',
    'status',
    'description',
    'payment',
    'ref',
  ];
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  paymentType: string;
  walletError: string;
  // responsible for filtering
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor(private apiCall: ApiCall, private walletApi: WalletApi, private flutterWave: Flutterwave, private paymentKey: PaymentService) {}
  closePopUp() {
    this.apiError = false;
  }
  activatePaymentOptions() {
    if (this.amount == null || this.amount == '') {
      return;
    }
    if (this.amount < 1) {
      this.walletError = 'Enter a valid amount';
      return;
    }
    this.payment = true;

  }
  loadWallet() {
    this.walletApi.getWallet().subscribe((data) => {
      this.spinning = true;

      if (data.Code == 200) {
        this.walletData = data.Object.WalletTransactions;
        this.walletBalance = Math.round(data.Object.WalletBalance);
        this.walletNumber = data.Object.WalletNumber;
        this.currencySymbol = data.Object.CurrencySymbol;
        this.walletId = data.Object.WalletId;
        this.walletOwnerName = data.Object.WalletOwnerName;
        console.log(data);
        
   
        
        this.spinning = false;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.walletData);
      this.dataSource.paginator = this.paginator;
    });
  }
  fundWalletButton() {
    this.fundWallet = !this.fundWallet;
    this.pay = !this.pay;
    this.fund = !this.fund;
    if (this.paymentOptions) {
      this.paymentOptions = false;
    }
  }
  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  closeUssd() {
    this.ussdPay = false;
  }
  paymentLog(x) {
    
    if (this.amount == null || this.amount == '') {
      return;
    }
  
    if (x == 1) {
      this.paymentType = 'PayStack'
    }
    else if (x == 0) {
      this.paymentType = 'Flutterwave'
    }
    this.payment=false;
     this.paystackAmount= Math.round(this.amount);
    this.spinning = true;
    const payload = {
      Amount: this.paystackAmount,
      IsWalletCredited: false,
      Reference: this.refCode,
      TransactionStatus: null,
      OnlinePaymentType: this.paymentType,
      Wallet: {
        WalletBalance: this.walletBalance,
        WalletId: this.walletId,
        WalletNumber: this.walletNumber,
        WalletOwnerName: this.walletOwnerName,
      },
      WalletBalance: this.walletBalance,
      WalletId: this.walletId,
      WalletNumber: this.walletNumber,
      WalletOwnerName: this.walletOwnerName,
    };

    this.walletApi.addwalletpaymentlog(payload).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        if (x == 1) {
          this.payWithPaystack();
        }
        else if (x == 0) {
          this.payWithFlutterWave();
        }
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }
  closePaymentOptions = () => {
    this.payment = false;
  }

  //flutterWave_test = 'FLWPUBK_TEST-0797c1579353fe90e2a60da4c7586fff-X';

  payWithFlutterWave = () => {
    this.payment = false;
    //console.log(this.refCode);
    this.secondFlutterAmount = Math.round(this.amount);
    var paymentData: InlinePaymentOptions = {
      public_key: this.paymentKey.flutter1,
      tx_ref: this.refCode,
      amount: this.secondFlutterAmount,
      currency: this.currencyCode,
      payment_options: 'card,ussd',
      redirect_url: '',
      // meta: this.meta,
      customer: {
        email: this.email,
        phone_number: this.phoneNumber,
        name: this.customerFullName,
      },
      customizations: {
        title: "GIG Logistics",
        description: "Wallet Funding",
        logo: "https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/GIGLogin.png",
      },
      callback: this.makePaymentCallback,
      onclose: this.closedPaymentModal,
      callbackContext: this
    }
    this.flutterWave.inlinePay(paymentData)

  }
  verifyReload: boolean = false;
  makePaymentCallback(response: PaymentSuccessResponse): void {
    this.verifyReload = true;
    console.log("Payment callback", response);
  }

  closedPaymentModal(): void {
    console.log('payment is closed');
    if (this.verifyReload) {
      document.getElementById('validatepaymentweb').style.display = 'block';
    }
    else {
      location.reload();
    }

  }
  payWithPaystack()  {
    //var payStackKey;

    // if (this.countryId == '1') {
    //   payStackKey = this.testkey_live_Nigeria;
    // } else if (this.countryId == '76') {
    //   payStackKey = this.testkey_live_ghana;
    // }
    var handler = PaystackPop.setup({
      key: this.paymentKey.getPaystackKey(),
      email: this.email,
      ref: '' + this.refCode,
      amount: this.paystackAmount * 100,
      currency: this.currencyCode,
      metadata: {
        custom_fields: [
          {
            display_name: 'Mobile Number',
            variable_name: 'mobile_number',
            value: this.phoneNumber,
          },
        ],
      },
      callback: function (response) {
        //location.reload();
      },
      onClose: function () {
        alert('Payment Cancelled');
        location.reload();
      },
      onSuccess: function () {
        //location.reload();
        document.getElementById('validatepaymentweb').style.display = 'block';
      },
    });
    handler.openIframe();
  }

  amountChange(event: KeyboardEvent) {
    if (
      this.amount == null ||
      (event.target as HTMLInputElement).value.length == 0
    ) {
      this.paymentOptions = false;
    }
  }

  fundNow() {
    if (this.amount == null) {
      this.paymentOptions = false;
      return;
    }
    this.paymentOptions = true;
  }

  onOptionsSelected(event) {}

  openUssd() {
    this.ussdPay = true;
  }

  payWithUssd() {
    const payload = {
      Amount: 9000,
      PaymentCountryId: 1,
      Email: 'it@giglogistics.com',
      ExternalReference: 'String',
      GatewayCode: this.gatewaycode,
      OnlinePaymentType: 'USSD',
      PhoneNumber: '2349087339777',
      Reference: this.refCode,
      UserId: this.userId,
      WalletId: this.walletId,
    };

    this.walletApi.initiateUSSD(payload).subscribe((data) => {
      this.ussdData = data.Object.data.Dialing_Code;
    });
  }
  verifyPayment() {
    document.getElementById('validatepaymentweb').style.display = 'none';

    this.spinning = true;
    this.apiCall.getVerifyPayment(this.refCode).subscribe(
      data => {
        if (data.Code == 200) {
          this.spinning = false;
          //console.log(data);
          
          location.reload();
        }
        else {
          this.spinning = false;
          this.apiError = true;
          this.errorMessage = data.ShortDescription;
        }

      }
    )
  }
  ngOnInit(): void {
    document.getElementById('validatepaymentweb').style.display = 'none';
    this.loadWallet();
  }
}
