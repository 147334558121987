import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCall } from '../services/api-call';
import { MatPaginator } from '@angular/material/paginator';
import { DataAccess } from '../helpers/dataAccess';
import { Router } from '@angular/router';
import { InvoiceApi } from './invoice-api';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})


export class InvoiceComponent implements OnInit {
  apiError:boolean = false;
  errorMessage;
  dataSource: MatTableDataSource<any>;
  invoiceData;
  spinning: boolean = true;
  dataAccess = new DataAccess();
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // responsible for table column
  displayedColumns: string[] = [
    'position',
    'invoice',
    'amount',
    'status',
    'waybill',
    'method',
    'date',
    'option',
  ];
  waybillNumber: any;
  // responsible for filtering
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  closePopUp() {
    this.apiError = false;
  }
  // stores waybill number and navigates to print invoice page
  getWaybill(invoiceId) {
    this.waybillNumber = this.invoiceData[invoiceId].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      'waybillNumberInvoice',
      this.waybillNumber
    );
    this.router.navigate(['/mainpage/printInvoice']);
  }
  constructor(private apiCall: ApiCall, private router: Router,private invoiceApi:InvoiceApi) {}

  ngOnInit(): void {
    this.invoiceApi.getInvoice().subscribe((data) => {
      this.spinning = true;
      if ((data.Code == 200)) {
        this.spinning = false;
        this.invoiceData = data.Object;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.invoiceData);
    });
  }
}
