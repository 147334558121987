<div class="screen-loader">
  <div class="justify-content-center spinner">
      <div class="spinner-container">
          <div class="spinner-grow spin" role="status">
            <span class="sr-only">Loading...</span>
          </div>
      </div>
  </div>
</div>

<!-- <div class="loader">Loading...</div> -->