import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-publictrack',
  templateUrl: './publictrack.component.html',
  styleUrls: ['./publictrack.component.scss']
})
export class PublictrackComponent implements OnInit {

  constructor(private route:ActivatedRoute) { 
    this.route.params.subscribe(params=>console.log(params))
  }

  ngOnInit(): void {
    
  }

}
