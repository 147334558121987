import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiCall } from '../services/api-call';
import { DataAccess } from '../helpers/dataAccess';
import { ViewInvoiceApi } from './view-invoice-api';

@Component({
  selector: 'app-view-invoice',
  templateUrl: './view-invoice.component.html',
  styleUrls: ['./view-invoice.component.scss'],
})

export class ViewInvoiceComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  waybillNumber;
  tableData;
  apiError = false;
  paymentMode;
  date;
  declaredValue;
  cashOnDelivery;
  category;
  invoiceNo;
  paymentStatus;
  senderName;
  senderAddress;
  senderNumber;
  receiverName;
  receiverAddress;
  receiverNumber;
  grandTotal;
  discount;
  vat;
  preparedBy;
  totalMoney;
  offDiscount;
  spinning: boolean = true;
  selection;
  dataAccess = new DataAccess();
  errorMessage: any;
  constructor(private router: Router, private apiCall: ApiCall,private viewInvoiceApi:ViewInvoiceApi) {}
  // responsible for table column
  displayedColumns: string[] = [
    'position',
    'description',
    'nature',
    'quantity',
    'unit',
    'amount',
    'total',
  ];
  returnToInvoicePage() {
    this.dataAccess.setLocalStorageValue('Selection', 4);
    this.router.navigate(['/mainpage/invoice']);
    // location.reload();
  }
  closePopUp() {
    this.apiError = false;
  }
  // printPage() {
  //   window.print();
  // }
  ngOnInit(): void {
    // this.selection = this.dataAccess.getLocalStorageValue('Selection');
    this.waybillNumber = this.dataAccess.getLocalStorageValue(
      'waybillNumberInvoice'
    );
    this.viewInvoiceApi.getByWaybill(this.waybillNumber).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.tableData = data.Object.Shipment.ShipmentItems;
        this.invoiceNo = data.Object.InvoiceNo;
        this.category = data.Object.Customer.CompanyType;
        this.paymentMode = data.Object.PaymentMethod;
        this.cashOnDelivery = data.Object.Shipment.CashOnDeliveryAmount;
        this.declaredValue = data.Object.Shipment.DeclarationOfValueCheck;
        this.date = data.Object.DateCreated;
        this.paymentStatus = data.Object.PaymentStatus;
        this.senderAddress = data.Object.Customer.Address;
        this.senderName = data.Object.Customer.CustomerName;
        this.senderNumber = data.Object.Customer.PhoneNumber;
        this.receiverAddress = data.Object.Shipment.ReceiverAddress;
        this.receiverName = data.Object.Shipment.ReceiverName;
        this.receiverNumber = data.Object.Shipment.ReceiverPhoneNumber;
        this.grandTotal = data.Object.Shipment.GrandTotal;
        this.discount = data.Object.Shipment.DiscountValue;
        this.vat = data.Object.Shipment.Vat;
        this.preparedBy = data.Object.Shipment.UserId;
        this.totalMoney = data.Object.Shipment.Total;
        this.offDiscount = data.Object.Shipment.offInvoiceDiscountvalue_display;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.tableData);
    });
  }
}
