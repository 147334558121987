import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable, from } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  merchantFlag :any
  constructor(public auth: AuthService, public router: Router) {
    if(localStorage.getItem('hideside')) {
      this.merchantFlag = localStorage.getItem('hideside');
    }
  }
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.merchantFlag  == 'false' || this.merchantFlag == 'true') {
      return true;
    } else {
      if (this.auth.isAutenticated()) {
        return true;
      } else {
        this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
        return false;
      }
    }
   
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      if(this.merchantFlag  == 'false' || this.merchantFlag == 'true') {
        return true;
      }
      else {
        if (this.auth.isAutenticated()) {
          return true;
        } else {
          this.router.navigate(['login']);
          return false;
        }
      }
    
  }
}
