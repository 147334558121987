import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCall } from '../services/api-call';
import { Dashboard } from '../models/dashboard';
import {PaymentService} from '../services/payment.service';
import { General } from '../helpers/general';
import { AuthHelpers } from '../helpers/auth';
import { DataAccess } from '../helpers/dataAccess';
import { TransactionHistory } from '../models/transactionhistory';
import { Router } from '@angular/router';
import {DashboardApi} from './dashboard-api';
import { Flutterwave, InlinePaymentOptions, PaymentSuccessResponse } from "flutterwave-angular-v3";
declare var PaystackPop: any;
import { LocationStrategy } from '@angular/common';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})

export class DashboardComponent implements OnInit {
  apiError: boolean = false;
  dataSource: MatTableDataSource<any>;
  dataAccess = new DataAccess();
  errorMessage;
   noData:boolean=false;
  public authHelpers = new AuthHelpers();
  spinning: boolean = true;
  amount;
  paystackAmount;
  paymentOptions: boolean = false;
  payment: boolean = false;
  verifyPopup:boolean=false;
  payStackFlutter;
  validatePopup:boolean =false;
   public transactionModel: TransactionHistory = new TransactionHistory();
  // responsible for table column
  displayedColumns: string[] = [
    'sn',
    'waybill',
    'departure',
    'destination',
    'amount',
    'receiver',
    'description',
    'date',
    'options',
  ];
  dashboardData;
  walletId;
  walletBalance;
  walletOwnerName;
  walletData;
  walletNumber;
  waybillNumber;
  customerFullName = this.dataAccess.getLocalStorageValue('CustomerFullName');
  currencySymbol;
  currencyCode;
  email = this.authHelpers.getAccessToken('email');
  countryId;
  phoneNumber = this.authHelpers.getAccessToken('phoneNumber');
  waybillNumberInvoice;
  money;
  hide = true;
  shipmentOrdered;
  flutterWaveAmount;
  secondFlutterAmount;
  testkey = 'pk_test_7140f98d7c52c6e559d00182fce18ec6f98443ae';
  testkey_live_Nigeria = 'pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b';
  testkey_live_ghana = 'pk_live_385c2ba036608c0b5fc902b32fd4b4fb07fcc01d';
  public dashboard: Dashboard = new Dashboard();
  public general: General = new General();
  customerName: any;
  customerCode: any;
  refCode: string = 'WA-' + Math.floor(Math.random() * 1000000000 + 1) + '-WEB';
dashboardDataInfo;
  paymentType: any;
  walletError: boolean=false;
  // responsible for filtering
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  trackWaybill(i) {
    this.waybillNumber = this.dashboardData[i].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue('waybillNumber', this.waybillNumber);
    this.router.navigate(['/mainpage/shipmenttracking']);
  }
  closePopUp() {
    this.apiError = false;
  }
  fundWallet() {
    this.router.navigate(['/mainpage/walletsummary']);
  }
  activatePaymentOptions() {
    if (this.amount == null || this.amount == '') {
      return;
    }
    if (this.amount < 1) {
      this.walletError = true;
      console.log('something');
      
      return;
    }
    this.payment = true;    
   
  }
  closePaymentOptions=()=>{
    this.payment = false;
  }
  hideButtons() {
    this.hide = false;
  }
  // flutterWave_test = 'FLWPUBK_TEST-0797c1579353fe90e2a60da4c7586fff-X';
  // flutterWave_live ='FLWPUBK-cddb1a65501aecf88e7c1dbd00e5cdcc-X'

  payWithFlutterWave=()=>{
    this.payment=false;
    //console.log(this.refCode);
    this.secondFlutterAmount = Math.round(this.amount);
    var paymentData: InlinePaymentOptions = {
      public_key: this.paymentKey.flutter1,
      tx_ref: this.refCode,
      amount: this.secondFlutterAmount,
      currency: this.currencyCode,
      payment_options: 'card,ussd',
      redirect_url: '',
      // meta: this.meta,
      customer: {
        email: this.email,
        phone_number: this.phoneNumber,
        name: this.customerFullName,
      },
      customizations: {
         title: "GIG Logistics",
        description: "Wallet Funding",
        logo: "https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/GIGLogin.png",
       },
      callback: this.makePaymentCallback,
      onclose: this.closedPaymentModal,
      callbackContext: this
    }
    this.flutterWave.inlinePay(paymentData)

  }

  verifyReload:boolean=false;



  makePaymentCallback(response: PaymentSuccessResponse): void {
    this.verifyReload=true;
    this.validatePopup=true;
    console.log("Payment callback", response); 
  }

  closedPaymentModal(): void {
    console.log('payment is closed');
    if(this.verifyReload){
      this.validatePopup=true;
      document.getElementById('validatepayment').style.display = 'block';
    }
    else{
      location.reload();
    }
  
  }


  public payWithPaystack() {
    this.payStackFlutter=1;
    this.payment=false;
  
    
    //console.log(this.email,this.phoneNumber);
    // var payStackKey;
    // if (this.countryId === 1) {
    //   payStackKey = this.testkey_live_Nigeria ;
    // } else if (this.countryId === 76) {
    //   payStackKey = this.testkey_live_ghana;
    // }

    var handler = PaystackPop.setup({
      key: this.paymentKey.getPaystackKey(),
      email: this.email,
      ref: '' + this.refCode,
      amount: this.paystackAmount * 100,
      currency: this.currencyCode,
      metadata: {
        custom_fields: [
          {
            display_name: 'Mobile Number',
            variable_name: 'mobile_number',
            value: this.phoneNumber,
          },
        ],
      },
      callback: function (response) {
        //location.reload();
      },
      onClose: function (e) {
        alert('Payment Cancelled');
        location.reload();
      },
      onSuccess: function () {
        //location.reload();
        document.getElementById('validatepayment').style.display = 'block';
      },
    });
    handler.openIframe();
  }
  verifyPayment() {
    document.getElementById('validatepayment').style.display = 'none';
   
    this.spinning = true;
    this.apiCall.getVerifyPayment(this.refCode).subscribe(
      data => {
        if (data.Code == 200) {
          this.spinning = false;
          //console.log(data);
          
          location.reload();
        }
        else {
          this.spinning = false;
          this.apiError = true;
          this.errorMessage = data.ShortDescription;
        }

      }
    )
  }
  // stores waybill number and navigates to print invoice page
  getWaybill(invoiceId) {
    this.waybillNumberInvoice = this.dashboardData[invoiceId].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      'waybillNumberInvoice',
      this.waybillNumberInvoice
    );
    this.router.navigate(['/mainpage/printInvoice']);
  }

 
  constructor(private apiCall: ApiCall, private router: Router,private dashboardApi:DashboardApi,private locationDash: LocationStrategy,private flutterWave: Flutterwave, private paymentKey: PaymentService) {}

  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.locationDash .onPopState(() => {
      history.pushState(null, null, location.href);
    })
  }
  amountChange(event: KeyboardEvent) {
    if (
      this.amount == null ||
      (event.target as HTMLInputElement).value.length == 0
    ) {
      this.paymentOptions = false;
    }
    return event.charCode >= 48 && event.charCode <= 57;
  }

  loadWallet() {
    this.dashboardApi.getWallet().subscribe((data) => {
      if (data.Code == 200) {
        this.walletData = data.Object.WalletTransactions;
        this.walletBalance = Math.round(data.Object.WalletBalance); 
        this.walletNumber = data.Object.WalletNumber;
        this.currencySymbol = data.Object.CurrencySymbol;
        this.walletId = data.Object.WalletId;
        // console.log(data);
        
        this.walletOwnerName = data.Object.WalletOwnerName;
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }

  paymentLog(x) {
    if (this.amount == null || this.amount == '') {
      return;
    }
    if (x == 1) {
      this.paymentType = 'PayStack'
    }
    else if (x == 0) {
      this.paymentType = 'Flutterwave'
    }
    this.paystackAmount= Math.round(this.amount);
    this.payment = false;
    this.spinning = true;
    const payload = {
      Amount: this.paystackAmount,
      IsWalletCredited: false,
      Reference: this.refCode,
      TransactionStatus: null,
      OnlinePaymentType: this.paymentType,
      Wallet: {
        WalletBalance: this.money,
        WalletId: this.walletId,
        WalletNumber: this.walletNumber,
        WalletOwnerName: this.walletOwnerName,
      },
      WalletBalance: this.money,
      WalletId: this.walletId,
      WalletNumber: this.walletNumber,
      WalletOwnerName: this.walletOwnerName,
    };

    this.dashboardApi.addwalletpaymentlog(payload).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        if(x==1){
          this.payWithPaystack();
        }
        else if(x==0){
          this.payWithFlutterWave();
        }
         
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }
  
  

  ngOnInit(): void {
    // console.log(this.paymentKey.getPaystackKey())
    
  
    document.getElementById('validatepayment').style.display = 'none';
    this.preventBackButton();
    this.spinning = true;
  
    this.dashboardApi.getDashboard().subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.countryId = data.Object.UserActiveCountry.CountryId;
        this.money = data.Object.WalletBalance;
        this.currencyCode = data.Object.UserActiveCountry.CurrencyCode;
           
        this.currencySymbol = data.Object.UserActiveCountry.CurrencySymbol;
        localStorage.setItem('currencyCode', this.currencyCode);
        localStorage.setItem('currencySymbol', this.currencySymbol);
        localStorage.setItem('Country', data.Object.UserActiveCountry.CountryCode);
        localStorage.setItem('countryId', this.countryId);
      
        this.shipmentOrdered = data.Object.TotalShipmentOrdered;
        this.walletBalance =  Math.round(this.money);
       

        this.loadWallet();
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });

    this.dashboardApi.getTransactionHistory(this.transactionModel).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning=false;
        this.dashboardDataInfo= data.Object.Shipments
      this.dashboardData=this.dashboardDataInfo

// if(this.dashboardData.length>5){

//   this.dashboardData=5;
//   console.log(this.dashboardData);
  
// }
// this.dashboardData.splice(0,5)
if(this.dashboardDataInfo.length > 5 ){
  
  // this.dashboardData=5;
  this.dashboardData.length=5;
  
}

        
      
       
        
        
        for (let i = 0; i <= this.dashboardData.length; i++) {
          this.customerCode = this.dashboardData[0].CustomerCode;
        }
      
      
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.dashboardData);
    });
    this.dashboardApi.getCod().subscribe((data) => {
      if ((data.Code == 200)) {
        const { CashOnDeliveryDetail } = data.Object;
        // gets customer's name
        this.customerName = CashOnDeliveryDetail.Wallet.CustomerName;
        // console.log(this.customerName);
        
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
    });
  }
}
