<div class="container-class contain">
  <div class="sideBar contain-sidebar flex-1" [ngClass]="{ showVal: display }">
    <div class="sidebar-content">
      <div class="brand-title">
        <span class="gig">GIG</span><span class="log">Logistics</span>
      </div>

      <div class="sidebar-links" [ngClass]="{ hideSidebar: readLocalStorageValue('hideside')  == 'true' }" >
        <div class="sidebar-options">
          <span
            class="links"
            [ngClass]="{ active: change === 0, inactive: change != 0 }"
          >
            <a
              routerLink="/mainpage/"
              routerLinkActive="list-item-active"
              (click)="changeColor(0)"
              ><i class="fas fa-columns"></i>Dashboard</a
            >
          </span>
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 1, inactive: change != 1 }"
          >
            <a
              (click)="changeColor(1)"
              routerLink="/mainpage/codreceipthistory"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>COD Remittances</a
            >
          </span> -->
          <span
            class="links active-link"
            [ngClass]="{ active: change === 1, inactive: change != 1 }"
          >
            <a
              (click)="changeColor(1)"
              routerLink="/mainpage/customertransactions"
              routerLinkActive="list-item-active"
              ><i class="far fa-chart-bar"></i>Transaction History</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 2, inactive: change != 2 }"
          >
            <a
              (click)="changeColor(2)"
              routerLink="/mainpage/walletsummary"
              routerLinkActive="list-item-active"
              ><i class="fas fa-wallet"></i>Wallet</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 3, inactive: change != 3 }"
          >
            <a
              (click)="changeColor(3)"
              routerLink="/mainpage/paymentlog"
              routerLinkActive="list-item-active"
              ><i class="fas fa-credit-card"></i>Payment Logs</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 11, inactive: change != 11 }"
          >
            <a
              (click)="changeColor(11)"
              routerLink="/mainpage/processterminalshipment"
              routerLinkActive="list-item-active"
              ><i class="fas fa-credit-card"></i>Process terminal shipment</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 4, inactive: change != 4 }"
          >
            <a
              (click)="changeColor(4)"
              routerLink="/mainpage/invoice"
              routerLinkActive="list-item-active"
              ><i class="fas fa-briefcase"></i>Invoice</a
            >
          </span>
          <span
            class="links"
            [ngClass]="{ active: change === 5, inactive: change != 5 }"
            ><a
              (click)="changeColor(5)"
              routerLink="/mainpage/shipmenttracking"
              routerLinkActive="list-item-active"
              ><i class="fa fa-map-marker"></i> Tracking</a
            ></span
          >
          <span
            class="links"
            [ngClass]="{ active: change === 6, inactive: change != 6 }"
            ><a
              (click)="changeColor(6)"
              routerLink="/mainpage/quickquote"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>Quick Quotes</a
            ></span
          >
          <span *ngIf="CustomerType =='Ecommerce'"
            class="links"
            [ngClass]="{ active: change === 7, inactive: change != 7 }"
            ><a
              (click)="changeColor(7)"
              routerLink="/mainpage/cashondelivery"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>COD</a
            ></span
          >
          <span *ngIf="CustomerType =='Ecommerce'"
            class="links"
            [ngClass]="{ active: change === 12, inactive: change != 12 }"
          >
            <span
              class="shipment-transaction"
              routerLink="/mainpage/subscription"
              routerLinkActive="list-item-active"
              (click)="changeColor(12)"
              ><img style="width: 17px; height: 17px;" src="../../assets/new-image/ic_payment_f.png" alt=""> <span class="ml-4">Subscription</span></span
            >
          </span>

          <!-- <span
            class="links"
            [ngClass]="{ active: change === 9, inactive: change != 9 }"
            ><a
              (click)="changeColor(9)"
              routerLink="/mainpage/sla"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>SLA</a
            ></span
          > -->
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 10, inactive: change != 10 }"
            ><a
              (click)="changeColor(10)"
              routerLink="/mainpage/createshipment"
              routerLinkActive="list-item-active"
              ><i class="fa fa-plus"></i>Create Shipment</a
            ></span
          >
          <span
            class="links"
            [ngClass]="{ active: change === 11, inactive: change != 11 }"
            ><a
              (click)="changeColor(11)"
              routerLink="/mainpage/pickuprequest"
              routerLinkActive="list-item-active"
              ><i class="fas fa-people-carry"></i>Pickup request</a
            ></spans
          > -->
        </div>
      </div>
    </div>
  </div>

  <div
    class="contain-sidebar-mobile for-mobile"
    [ngClass]="{ showVal: displayMobile }"
  >
    <div class="sidebar-content">
      <div class="brand-title">
        <span class="gig">GIG</span><span class="log">Logistics</span>
        <i (click)="toggleMobile()" class="fa fa-times close-menu"></i>
      </div>

      <div class="sidebar-links"  [ngClass]="{ hideSidebar: readLocalStorageValue('hideside')  == 'true' }">
        <div class="sidebar-options">
          <span class="links">
            <a
              routerLink="/mainpage/"
              (click)="toggleMobile()"
              (click)="changeColor(0)"
              routerLinkActive="list-item-active"
              ><i class="fas fa-columns"></i>Dashboard</a
            >
          </span>
          <!-- <span class="links">
            <a
              (click)="toggleMobile()"
              routerLink="/mainpage/codreceipthistory"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>COD Remittances</a
            >
          </span> -->
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(1)"
              routerLink="/mainpage/customertransactions"
              routerLinkActive="list-item-active"
              ><i class="far fa-chart-bar"></i>Transaction History</a
            >
          </span>
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(2)"
              routerLink="/mainpage/walletsummary"
              routerLinkActive="list-item-active"
              ><i class="fas fa-wallet"></i>Wallet</a
            >
          </span>
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(3)"
              routerLink="/mainpage/paymentlog"
              routerLinkActive="list-item-active"
              ><i class="fas fa-credit-card"></i>Payment Logs</a
            >
          </span>
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(11)"
              routerLink="/mainpage/processterminalshipment"
              routerLinkActive="list-item-active"
              ><i class="fas fa-credit-card"></i>Process Terminal Shipment</a
            >
          </span>
          <span class="links">
            <a
              (click)="toggleMobile()"
              (click)="changeColor(4)"
              routerLink="/mainpage/invoice"
              routerLinkActive="list-item-active"
              ><i class="fas fa-briefcase"></i>Invoice</a
            >
          </span>
          <span class="links"
            ><a
              (click)="toggleMobile()"
              (click)="changeColor(5)"
              routerLink="/mainpage/shipmenttracking"
              routerLinkActive="list-item-active"
              ><i class="fa fa-map-marker"></i> Tracking</a
            ></span
          >
          <span class="links"
            ><a
            (click)="changeColor(6)"
              (click)="toggleMobile()"
              routerLink="/mainpage/quickquote"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>Quick Quotes</a
            ></span
          >
          <span class="links" *ngIf="CustomerType =='Ecommerce'"
            ><a
              (click)="toggleMobile()"
              (click)="changeColor(7)"
              routerLink="/mainpage/cashondelivery"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>COD</a
            ></span
          >
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 8, inactive: change != 8 }"
          >
            <span
              class="shipment-transaction"
              (click)="changeColor(8)"
              (click)="toggleMobile()"
              (click)="registerShipment()"
              ><i class="fas fa-ship"></i>Oversea Shipping</span
            >
          </span><!-->
          <span class="links" *ngIf="CustomerType =='Ecommerce'"
          ><a
            (click)="toggleMobile()"
            routerLink="/mainpage/subscription"
            routerLinkActive="list-item-active"
            > <img style="height: 17px; width: 17px;" src="../../assets/new-image/ic_payment_f.png" alt=""> <span class="ml-4">Subscription</span></a
          ></span
        >
          <!-- <span
            class="links"
            [ngClass]="{ active: change === 6, inactive: change != 6 }"
          >
            <a
              (click)="toggleMobile()"
              (click)="changeColor(7)"
              routerLink="/mainpage/shipmenttransaction"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>Shipment Transaction</a
            ></span
          > -->
          <!-- <span class="links"
            ><a
              (click)="toggleMobile()"
              routerLink="/mainpage/sla"
              routerLinkActive="list-item-active"
              ><i class="fa fa-dashboard"></i>SLA</a
            ></span
          > -->
          <!-- <span class="links"
            ><a
              (click)="toggleMobile()"
              routerLink="/mainpage/createshipment"
              routerLinkActive="list-item-active"
              ><i class="fa fa-plus"></i>Create Shipment</a
            ></span
          >
          <span class="links"
            ><a
              (click)="toggleMobile()"
              routerLink="/mainpage/pickuprequest"
              routerLinkActive="list-item-active"
              ><i class="fas fa-people-carry"></i>Pickup request</a
            ></span
          > -->
        </div>
      </div>
    </div>
  </div>
  

  <div class="nav-content flex-10" [ngClass]="{ content: toggle }">
    <div class="nav-navbar">
     
      <div class="navbar-item1"><i (click)="toggleSideBar()" class="fas fa-bars for-desktop"></i>
      <i (click)="toggleMobile()" class="fas fa-bars for-mobile"></i>
      <span class="date-time">{{ getTime() | date }}</span></div>
      <div class="navbar-item2">
        <div class="item1"><div *ngIf="hideside == true " style="cursor:pointer" (click)="logOut()"> Login</div></div>
        <div class="item2" *ngIf="hideside !== true">
          <div class="subscription-type-option">
            <span *ngIf="CustomerType !='Ecommerce'"> Individual User Account</span>
            <span *ngIf="CustomerType =='Ecommerce'"> {{rank}} e-commerce Account</span>
            <div class="upgrade-class">
              <span class="" style="font-size:1.3rem" *ngIf="CustomerType !='Ecommerce'"><a style="color:white;text-decoration: underline;"
                  routerLink="/mainpage/registerAsEcommerce">Become an Ecommerce Merchant</a></span>
            </div>
            <div class="upgrade-class">
              <span class="" style="font-size:1.3rem" *ngIf="rank == 'Basic'"><a style="color:white;text-decoration: underline;"
                  routerLink="/mainpage/subscription">Upgrade to Class</a></span>
            </div>
            <div class="customer-user-info">
              <div>
                <i class="fa fa-user-circle"> </i>
                <span class="channel-code" [matMenuTriggerFor]="menu">{{userCode}}</span>
                <i class="fas fa-caret-down info-drop-down" [matMenuTriggerFor]="menu"></i>
              </div>
            </div>
             
             
          </div>
          <div class="logout-icon">
          <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/Group 16061.png" alt="" (click)="logOutPopUp()">
          </div>
         
          <mat-menu #menu="matMenu">
            <button class="icon" mat-menu-item (click)="profile()" (click)="changeColor(9)">
              <i class="fas fa-id-card drop-down"></i>Profile
            </button>
            <button class="icon" mat-menu-item (click)="changePassword()" (click)="changeColor(10)">
              <i class="far fa-edit drop-down"></i>Change Password
            </button>
            <button class="icon" mat-menu-item (click)="logOutPopUp()">
              <i class="fas fa-power-off drop-down"></i>logout
            </button>
          </mat-menu>
        </div>
      </div>
      
      
    </div>

    <div class="template">
      <div class="header-text" *ngIf="hideside !== true ">
        <span>Home</span> >
        {{ navPage }}
      </div>
      
<div class="welcome-text" *ngIf="hideside !== true " >
  <h3 class="intro-text"> Good {{displayMessage}}, <span class="customer-name"> {{customerName | titlecase}}!</span> </h3>
</div>
      <div class="overlay" *ngIf="logout">
        <div class="logout-message">
          <div class="message">Do you really want to logout</div>
          <button class="yes-button" (click)="logOut()">Yes</button>
          <button class="no-button" (click)="backToNav()">No</button>
        </div>
      </div>

      <!-- <div
        class="information-display"
        [ngStyle]="{ display: displayInfo == 'show' ? 'block' : 'none' }"
      >
        <div class="text-container">
          <div class="text-close">
            <i (click)="removeDisplay()" class="fa fa-close"></i>
          </div>
          <p class="header-text">Dear Esteemed Customer</p>
          <p>
            Please note that from friday 9th October 2020.
          </p>
          <p>
            All senders will need to give delivery code sent to them from GIGLogistics when they create any shipment to their beneficiary.
          </p>
          <p>
            This delivery code will be required for shipment collection either from our service centers or from our last mile delivery team.
          </p>
          <p>
            Thank you for trusting GIGL as your logistics partner.
          </p>
        
        </div>
      </div> -->
     
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<!-- <router-outlet></router-outlet> -->




