import { Component, OnInit } from '@angular/core';
import {ActivatedRoute,Router} from "@angular/router";
import { AuthHelpers } from '../helpers/auth';
import { ApiCall } from '../services/api-call';
import { PaymentService } from '../services/payment.service';
import { Flutterwave, InlinePaymentOptions, PaymentSuccessResponse, AsyncPaymentOptions } from "flutterwave-angular-v3";
declare var PaystackPop: any;
@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss']
})
export class PaymentPageComponent implements OnInit {
  public authHelpers = new AuthHelpers();
  countryId: string = localStorage.getItem('countryId');
  currencyCode = localStorage.getItem('currencyCode');
  email = this.authHelpers.getAccessToken('email');
spinning:boolean =  false;
waybill;
errorPopUp:boolean=false;
openPopUp:boolean = false;
waybillDetails;
succesfulUpload;
dataCode;
payment:boolean=false;
  customerFullName = this.authHelpers.getAccessToken('CustomerFullName');
  phoneNumber = this.authHelpers.getAccessToken('email');
  openPopup:boolean=false;
  refCode: string;
  paystackAmount: any;
  successfulPaymentPopup:boolean=false;
  testkey_live_Nigeria = 'pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b';
  testkey_live_ghana = 'pk_live_385c2ba036608c0b5fc902b32fd4b4fb07fcc01d';
  secondFlutterAmount: number;
  paymentType: string;
  constructor(private route: ActivatedRoute, private apiCall: ApiCall, private router: Router, private flutterWave: Flutterwave,
    private paymentKey: PaymentService) {
    this.route.params.subscribe(params=>this.waybill = params.waybill)
  }
  closePaymentPopup = () => {
    this.openPopup = false;
  }
  openPaymentPopup() {
    this.openPopup = true;
  }
  activatePaymentOptions() {
    this.openPopup = false;
    this.payment = true;

  }
  closePaymentOptions = () => {
    this.payment = false;
  }
  getWaybillDetails(id) {
    this.spinning = true;
    this.apiCall.getWaybillDetails(id).subscribe((data) => {
      this.spinning = false;
      this.dataCode = data.Code;
      if (data.Code == 200){ 
        this.waybillDetails = data.Object;
      }
        else{
        this.succesfulUpload=data
      }
    },
     (error) => {
        //Error callback
        this.spinning = false;
       this.errorPopUp=true;
        throw error;
      });
  }
  reload(){
    location.reload();
  }
  returnToLogin(){
 localStorage.clear();
    // return false;

    this.router.navigate(['/login']);
    location.reload();
  }
  verifyPayment(){
    document.getElementById('validatepaymentpage').style.display = 'none';
    this.spinning=true;
    this.apiCall.getVerifyPayment(this.refCode2).subscribe(
      data=>{  
        if(data.Code==200){
          this.spinning=false;
          //console.log(data);
          location.reload();
        }
        else{
          this.succesfulUpload = data;
        }
        
      }
    )
  }
  processPayment(){
    this.openPopup = false;
    this.spinning = true;
    this.apiCall.payForShipment(this.waybill).subscribe((data)=>{
      this.spinning = false;
      this.openPopUp = true
      if(data.Code == 200){
        this.succesfulUpload = data;
      }
      else{
        this.succesfulUpload=data
      }
    })

  }
  payWithCard(x){
    this.openPopup = false;
    this.spinning = true;
    this.payment = false;
    // this.refCode = 'wb-' + this.waybill + '-' + Math.floor(Math.random() * 1000000000 + 1);
    if (x == 1) {
      this.paymentType = 'PayStack'
    }
    else if (x == 0) {
      this.paymentType = 'Flutterwave'
    }
    const payload = {
      waybill: this.waybill,
      Reference: this.refCode2,
      OnlinePaymentType: this.paymentType
    }
    this.apiCall.addwaybillpaymentlog(payload).subscribe(
      data => {
        if (data.Code == 200) {
         
          this.spinning = false;
          this.paystackAmount = data.Object.Amount;
          this.secondFlutterAmount=data.Object.Amount;
          if (x == 1) {
            this.payWithPaystack();
          }
          else if (x == 0) {
            this.payWithFlutterWave();
          }
        }
        else {
          this.spinning = false;
         
        }

      }
    )

  }
  //testPayStackKey = 'pk_test_7140f98d7c52c6e559d00182fce18ec6f98443ae';
  payWithPaystack() {
   // var payStackKey;
    this.payment=false;

    // if (this.countryId == '1') {
    //   payStackKey = this.testkey_live_Nigeria;
    // } else if (this.countryId == '76') {
    //   payStackKey = this.testkey_live_ghana;
    // }
    var handler = PaystackPop.setup({
      key: this.paymentKey.getPaystackKey(),
      email: this.email,
      ref: '' + this.refCode2,
      amount: this.paystackAmount * 100,
      currency: this.currencyCode,
      metadata: {
        custom_fields: [
          {
            display_name: 'Mobile Number',
            variable_name: 'mobile_number',
            value: this.phoneNumber,
          },
        ],
      },
      callback: function (response) {
      //  location.reload();
      },
      onClose: function () {
        alert('Payment Cancelled');
        location.reload();
      },
      onSuccess: function () {
        document.getElementById('validatepaymentpage').style.display = 'block';
      },
    });
    handler.openIframe();
  }
  flutterWave_test = 'FLWPUBK_TEST-0797c1579353fe90e2a60da4c7586fff-X';
  refCode2 = 'WB-' + Math.floor(Math.random() * 1000000000 + 1) + '-WEB';


  payWithFlutterWave = () => {
    this.payment = false;
    //console.log(this.refCode2);
    let paymentData: InlinePaymentOptions = {
      public_key: this.paymentKey.flutter1,
      tx_ref: this.refCode2,
      amount: this.secondFlutterAmount,
      currency: this.currencyCode,
      payment_options: 'card,ussd',
      redirect_url: '',
      // meta: this.meta,
      customer: {
        email: this.email,
        phone_number: this.phoneNumber,
        name: this.customerFullName,
      },
      customizations: {
        title: "GIG Logistics",
        description: "Shipment Payment",
        logo: "https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/GIGLogin.png",
      },
      callback: this.makePaymentCallback,
      onclose: this.closedPaymentModal,
      callbackContext: this
    }

    this.flutterWave.inlinePay(paymentData);

  }
  verifyReload: boolean = false;
  makePaymentCallback(response: PaymentSuccessResponse): void {
    this.verifyReload = true;

    console.log("Payment callback", response);
  }

  closedPaymentModal(): void {
    console.log('payment is closed');
    if (this.verifyReload) {

      document.getElementById('validatepaymentpage').style.display = 'block';
    }
    else {
      location.reload();
    }

  }


  closePopUp(){
    this.openPopUp = false;
  }
  ngOnInit(): void {
    this.getWaybillDetails(this.waybill);
    document.getElementById('validatepaymentpage').style.display = 'none';
  }

}
