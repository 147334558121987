<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="apiError">
  <div class="password-message">
    <div class="message">{{errorMessage}}</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div>

  <!-- <div class="password-message">
    <div class="message">{{errorMessage}}</div>
    <button (click)="closePopUp()">Ok</button>
  </div> -->

<div class="container-class tracking">
  <div class="container-content">
    <div class="page-text">
      <h2>Shipment Tracker</h2>
    </div>

    <div class="tracking-information">
      <div class="tracking-info-section display-card">
        <h4 class="tracking-intro-text">Please Enter Waybill Number</h4>
        <div class="tracking-input">
          <div class="input-container">
            <input
              type="text"
              [(ngModel)]="waybill"
              placeholder="Enter Waybill Number"
            />
          </div>
          <div class="button-container">
            <button class="track-button" (click)="trackShipment()">
              Track
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="display-track-header" *ngIf="tracking">
      Tracking Information
    </div>
    <div class="display-track-container" *ngIf="tracking">
     
      <div class="display-track-info">
      <div class="display-track-information">
        Waybill Number
        <div class="info">{{displayWaybill}}</div>
      </div>
      <div class="display-track-information departure-track">Departure Address <div class="info"> <span class="no-departure" *ngIf="!departureAddress">Departure address not specified</span>{{departureAddress}}</div></div>
      <div class="display-track-information">Destination Address <div class="info">{{destinationAddress}}</div></div>
      </div>
    </div>
    
    <div class="tracking-table display-tbl" *ngIf="tracking">
      <div class="tracking-table-container">
        <div class="tracking-table-content table-content">
          <div class="top-heading">Waybill Number {{displayWaybill}}</div>
          <div class="table-buttons">
            <span
              ><button
                class="table-button"
                 printSectionId="printSection"
            ngxPrint
            [useExistingCss]="true"
              >
                <i class="fas fa-print"></i>print
              </button></span
            >
            <span
              ><button class="table-button"  (click)="
                  exporter.exportTable('xlsx', {
                    fileName: 'Tracking',
                    sheet: 'sheet_name',
                    Props: { Author: 'GIGL' }
                  })
                ">
                <i class="far fa-file-excel"></i>excel
              </button></span
            >
            <!-- <span
              ><button class="table-button">
                <i class="far fa-file-pdf"></i>pdf
              </button></span
            > -->
          </div>
          <div class="table" id="printSection">
            <table
              mat-table
              [dataSource]="dataSource"
              matTableExporter
              #exporter="matTableExporter"
            >
              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <mat-header-cell *matHeaderCellDef>SN</mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>
              <!-- date column -->
              <ng-container matColumnDef="waybill">
                <mat-header-cell *matHeaderCellDef>WAYBILL</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Waybill">
                  {{ element.Waybill }}
                </mat-cell>
              </ng-container>

              <!-- service center Column -->
              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>DATE</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Date">
                  {{ element.DateTime | date }}
                </mat-cell>
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="time">
                <mat-header-cell *matHeaderCellDef>TIME</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Time">
                  {{ element.DateTime | date: "shortTime" }}
                </mat-cell>
              </ng-container>
              <!-- Amount column -->
              <ng-container matColumnDef="location">
                <mat-header-cell *matHeaderCellDef>LOCATION</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Location">
                  {{ element.Location }}
                </mat-cell>
              </ng-container>
              <!-- partner column -->
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>STATUS</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Status">
                  {{ element.ScanStatus.Incident }}
                </mat-cell>
              </ng-container>
              <!-- date column -->
            
              <!-- <ng-container matColumnDef="print">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  <span class="print-button"
                    ><i class="fas fa-print" (click)="getWaybill(i)"></i
                  ></span>
                </mat-cell>
              </ng-container> -->

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
