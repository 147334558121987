import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Location } from '@angular/common';
import { DataAccess } from '../helpers/dataAccess';
@Component({
  selector: 'app-merchant-account',
  templateUrl: './merchant-account.component.html',
  styleUrls: ['./merchant-account.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MerchantAccountComponent implements OnInit,OnDestroy {
  public dataAccess = new DataAccess();
  show: boolean = true;
  showConfirm: boolean = false;
  countryList: any;
  signUpForm: FormGroup;
  isPhone: boolean;
  isBuss: boolean;
  isSecure: boolean = false;
  spinning: boolean = false;
  isLoading:boolean = false;
  apiError:boolean = false;
  errorMessage:string;
  dropdownSettings:IDropdownSettings = {
    singleSelection: true,
    idField: 'name',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: false,
    itemsShowLimit: 1,
    enableCheckAll:false,
    closeDropDownOnSelection:true
  };
  visaCheck;
  visa;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loginservice: LoginService,
    private fb: FormBuilder,
    private location: Location
    ) { 
    this.visa = this.route.snapshot.queryParams;
    this.visaCheck = '' + this.route.snapshot.queryParams['visa'] + '';
    }

  ngOnInit() {
    this.dataAccess.setLocalStorageValue('Visa', this.visaCheck);
    console.log(this.visa);
    
    this.getCountry();
    this.initialiseForms();
    localStorage.setItem('hideside', 'true');
    document.querySelector('body').classList.add('login');
    this.onSwitch();
    
  }
  onSwitch() {
    //this.location.replaceState('/merchantSignUp');
    }
  initialiseForms() {

    this.signUpForm = this.fb.group({
      phoneNumber: ["", [Validators.required,Validators.minLength(11)]],
      companyName: ["", [Validators.required]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      isCheck: [false, [Validators.required]],
      countryCode: ["", [Validators.required]],
      phoneCode:["", [Validators.required]]
    });
    let stepData = JSON.parse(localStorage.getItem('m_d')) || 'noemail' ;
    if(stepData !== 'noemail'){
      this.patchValue(stepData)
    }

  }
  isNumber: boolean = false;
  isLower: Boolean = false;
  isUpper: boolean = false;
  isLength:boolean = false;

  patchValue(data){
    let phoneNumber = data['phoneNumber'].replace(data['phoneCode'],"");
    let countryCode =[];
    countryCode.push({"name":data['countryCode']})
    this.signUpForm.patchValue({
      phoneNumber:phoneNumber,
      companyName:data['companyName'],
      password:data['password'],
      countryCode:countryCode,
      phoneCode:data['phoneCode'],
      isCheck:data['isCheck']
    });
    this.isBuss = true;
    this.isPhone = true;
   this.getPassword();
   this.inputNumber("");
    
  }
  check() {

    this.signUpForm;
    console.log(this.signUpForm)
  }
  getPassword() {
    let password = this.signUpForm.value.password;

    if(password.length > 5) {
      this.isLength = true;
    }else {
      this.isLength = false;
    }
    if (password.match("[a-z]")) {
      this.isLower = true;
    } else {
      this.isLower = false;
    }
    if (password.match("[A-Z]")) {
      this.isUpper = true;
     // console.log('upper case match')
    }
    else {
      this.isUpper = false;
    }
    if (password.match("[0-9]")) {
      this.isNumber = true;
     // console.log('number match')
    }
    else {
      this.isNumber = false;
    }
    if (this.isNumber == true && this.isLower == true && this.isUpper == true && this.isLength == true) {
      this.isSecure = true;

    } else {
      this.isSecure = false;
    }
    
    console.log('cole',this.signUpForm.value)
    console.log('isSecreu',this.isSecure)
    console.log('isLength',this.isLength)
    console.log('isBuss',this.isBuss)
    console.log('isPhone',this.isPhone)

  }
  password() {
    this.show = !this.show;
  }
  Confirmpassword() {
    this.showConfirm = !this.showConfirm;
  }
  step() {
    let request = this.signUpForm.value;
    delete request['isCheck'];
    delete request['confirmPassword'];
    let first =request['phoneNumber'].charAt(0);

    if (first =='0') {
      request['phoneNumber'] = request['phoneNumber'].slice(1);
    }
    request['phoneNumber'] = request['phoneCode'] + request['phoneNumber'];
    request['countryCode'] = request['countryCode'][0]['name']
   // console.log(request, 'final Request')

    localStorage.setItem('m_d', JSON.stringify(request));

    this.router.navigate(['/mainpage/merchant-boarding']);
  }
  getCountry() {
    this.loginservice.getCountry().subscribe((res) => {
     // console.log(res)
      if (res['message'] == 'Success') {
        this.countryList = res.data.countryList;
      }
    });
  }
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  onItemSelectProduct(item: any) {
    //console.log(item,'cat selected');
   // this.signUpForm.controls['countryCode'].setValue(item.name)
    this.countryList.forEach(element => {
      if(element['name'] == item.name) {
        let code = "+" + element.phoneCode;
        localStorage.setItem('plan', JSON.stringify(element));
        this.signUpForm.controls['phoneCode'].setValue(code);
      }
      
    });

  }
  onItemDeSelect(item:any) {
    this.signUpForm.controls['countryCode'].setValue(null)
  }
  onSelectAllProduct(items: any) {

  }
  validateNumber() {
  //  this.isLoading = true;
    let request = {
      phoneNumber: this.signUpForm.value.phoneNumber
    }
   // this.spinning = true;
    this.loginservice.verifyMerchant(request).subscribe((res) => {
      this.isLoading = false;
      this.spinning = false;
      if (res['message'] == 'Success') {
        this.isPhone = true;
      } else {
        this.isPhone = false;
      }
    }, err => {
      this.isPhone = false;
      this.isLoading = false;
      this.errorMessage = err;
      if (err.error.data.message =='Sorry, we are unable process your request. Please try again or contact support for assistance.') {
        this.apiError = true;
        this.errorMessage = err.error.data.message;
      }
      if (err.error.data.message == 'Unable to process the request') {
        this.apiError = true;
        this.errorMessage = err.error.data.message;
      }
    });
    //console.log(request, 'final');
  }
  closePopUp() {
    this.apiError = false;
    this.errorMessage = null;
  }
  validateBusiness() {
   // this.isLoading = true;
    let request = {
      companyName: this.signUpForm.value.companyName
    }
    this.loginservice.verifyMerchant(request).subscribe((res) => {

      //console.log(res)
      this.isLoading = false;
      if (res['message'] == 'Success') {
        this.isBuss = true;
      } else {
        this.isBuss = false;
      }
    }, err => {
     
      this.isBuss = false;
      this.isLoading = false;
      if (err.error.data.message =='Sorry, we are unable process your request. Please try again or contact support for assistance.') {
        this.apiError = true;
        this.errorMessage = err.error.data.message;
      }
      if (err.error.data.message =='Unable to process the request') {
        this.apiError = true;
        this.errorMessage = err.error.data.message;
      }
      
    });
    console.log(request, 'final');
  }
  inputNumber(event){
    let mobile= this.signUpForm.value.phoneNumber;
    let first =mobile.charAt(0)
    if(first == "0") {
      this.signUpForm.controls['phoneNumber'].setValidators([ Validators.minLength(11),Validators.maxLength(11)]);
      this.signUpForm.controls['phoneNumber'].updateValueAndValidity();
    }
    else{
      this.signUpForm.controls['phoneNumber'].setValidators([ Validators.minLength(10),Validators.maxLength(10)]);
      this.signUpForm.controls['phoneNumber'].updateValueAndValidity();
    }
    
    } 
    ngOnDestroy() {
      document.querySelector('body').classList.remove('login');
  }

}
