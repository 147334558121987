import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-createshipment',
  templateUrl: './createshipment.component.html',
  styleUrls: ['./createshipment.component.scss']
})
export class CreateshipmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
