import { ApiCall } from "../services/api-call";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})

export class ProcessShipmentApi{
constructor(private apiCall:ApiCall,private httpClient:HttpClient) {}
baseUrl1=this.apiCall.API1;
baseUrl2=this.apiCall.API2;
baseHeader=this.apiCall.authHeader();

getProcessTerminalShipment(): Observable<any> {
    return this.httpClient.get(`${this.baseUrl1}/portal/outstandingpayments`,this.baseHeader)
}
 payForShipment(waybill: number): Observable<any>{
     return this.httpClient.get(`${this.baseUrl1}/portal/paymentforshipment/${waybill}`,this.baseHeader)
 }
  addwaybillpaymentlog(payload: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl1}/portal/addwaybillpaymentlog`, payload, this.baseHeader)
  }
 
}