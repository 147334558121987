<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="apiError">
  <div class="password-message">
    <div class="message">{{errorMessage}}</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div>
<div class="container-class">
  <div class="container-content">
    <div class="function-button">
      <div class="table-buttons">
        <span
          ><button
            class="table-button"
            printSectionId="print-section"
            ngxPrint
            [useExistingCss]="true"
          >
            <i class="fas fa-print"></i>print
          </button></span
        >
        <span
          ><button
            class="table-button"
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Invoice',
                sheet: 'sheet_name',
                Props: { Author: 'GIGL' }
              })
            "
          >
            <i class="far fa-file-excel"></i>excel
          </button></span
        >
        <!-- <span
          ><button class="table-button">
            <i class="far fa-file-pdf"></i>pdf
          </button></span
        > -->
      </div>
    </div>
    <div class="invoice-table display-tbl">
      <div class="invoice-table-container">
        <div class="invoice-table-content table-content">
          <div class="table-filter-container">
            <div class="table-filter">
              <i class="fas fa-search"></i>
              <input class="filter-line" matInput (keyup)="applyFilter($event)" placeholder="Search" />
            </div>
          </div>
          <div class="table">
            <table
              id="print-section"
              mat-table
              [dataSource]="dataSource"
              matTableExporter
              #exporter="matTableExporter"
              hiddenColumns="[7]"
            >
              <!-- Position Column -->
              <ng-container matColumnDef="position">
                <mat-header-cell *matHeaderCellDef>SN</mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>
              <!-- items column -->
              <ng-container matColumnDef="invoice">
                <mat-header-cell *matHeaderCellDef>INVOICE NO</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Invoice No">
                  {{ element.InvoiceNo }}
                </mat-cell>
              </ng-container>

              <!-- Departure Column -->
              <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef>AMOUNT</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Amount">
                  ₦ {{ element.Amount | number }}
                </mat-cell>
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef
                  >PAYMENT STATUS</mat-header-cell
                >
                <mat-cell *matCellDef="let element" data-label="Payment Status">
                 <span class="status-span" [ngClass]="element.PaymentStatus"> {{ element.PaymentStatus }} </span>
                </mat-cell>
              </ng-container>
              <!-- Amount column -->
              <ng-container matColumnDef="waybill">
                <mat-header-cell *matHeaderCellDef>WAYBILL</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Waybill">
                  {{ element.Waybill }}
                </mat-cell>
              </ng-container>
              <!-- partner column -->
              <ng-container matColumnDef="method">
                <mat-header-cell *matHeaderCellDef
                  >PAYMENT METHOD</mat-header-cell
                >
                <mat-cell *matCellDef="let element" data-label="Payment Method">
                  {{ element.PaymentMethod }}
                </mat-cell>
              </ng-container>
              <!-- date column -->
              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>DATE</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="date">
                  {{ element.DateCreated | date }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="option">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element; let invoiceId = index">
                  <!-- <i
                   
                    class="fas fa-search-plus" 
                  ></i> -->
                  <i class="fas fa-search" (click)="getWaybill(invoiceId)"></i>
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
