
<section class="main_section">
<app-spinner *ngIf="isLoading"></app-spinner>
<div class="overlay" *ngIf="apiError">
  <div class="errorMessage">
    <div class="ShowErrormessage">{{errorMessage}}
      <p> <button class="error_btn" (click)="closePopUp()">Ok</button></p>
    </div>
   
  </div>
</div>
  <div class="logo text-center mb-4">
    <a> <img class="mt-4" style="width: 77px;" src="../../assets/new-image/logo_white.png" alt=""></a>
  </div>
  <div class="innersection container">
    <div class="col-md-8 offset-md-2 al_bg_color ">
      <div class="page_heading p-2 text-center">
        <p class="m-0 mt-2 mb-4 p-3">CREATE YOUR ACCOUNT</p>
      </div>
      <div class="row m-0 p-0">
        <div class="col-md-12 col-lg-6  al_hide">
          <div class="account_text">
            <p>Hi,</p>
            <p class="m-0 mt-3">We are excited to have you join the thousands of social/e-commerce merchants who rely on GIG Logistics as a dependable partner to handle deliveries.</p>
            <p class="m-0 mt-3">Our mission daily is to simplify and help you solve one of the major challenges with digital commerce while you focus on other things that matter. We exist to enable ease of running your business by efficiently delivering your products to your customers when needed. </p>
            <p class="m-0 mt-3">Our support team is always a chat away to attend to your queries and enquiries. </p>
            <p class="m-0 mt-3">We remain totally committed to the success of your business. </p>
            <p class="m-0 mt-3">Best regards,</p>
            <div class="logo-container">
              <div class="logo-image-signature">
                <div class="signature">
                  <img src="https://res.cloudinary.com/gig-logistics/image/upload/v1611823427/Customer%20Portal/e_odiashe_1_yeyvjn.png" alt="">
                </div>
               
                <!-- <div class="logo-image">
                  <img src="https://res.cloudinary.com/gig-logistics/image/upload/v1611738527/Customer%20Portal/GIGLogin_u3bt1z.png" alt="" />
                </div> -->
              </div>
              <div class="officer-name">E. Odiase</div>
              <div class="officer-title">Chief Customer Experience Officer</div>
              <div class="normal-user-view" *ngIf="visaCheck != 'true'">
                <div class="image"><img src="https://res.cloudinary.com/gig-logistics/image/upload/v1611738582/Customer%20Portal/image_15_neka6x.png" alt=""></div>
                <div class="logo-line"></div>
                <div class="logo-icons">
                    <a href="https://facebook.com/giglogistics" target="_blank"> <img
                        src="https://res.cloudinary.com/gig-logistics/image/upload/v1611738510/Customer%20Portal/facebook_bn4jsv.png"
                        alt=""> </a>
                          <a href="https://instagram.com/giglogistics/" target="_blank"><img
                              src="https://res.cloudinary.com/gig-logistics/image/upload/v1611738573/Customer%20Portal/instagram_qgsehz.png"
                              alt=""></a>
                          <a href="https://twitter.com/giglogistics" target="_blank"><img
                              src="https://res.cloudinary.com/gig-logistics/image/upload/v1611738647/Customer%20Portal/Twitter_zlkotu.png"
                              alt=""></a>
                          <a href="https://linkedin.com/company/gig-logistics" target="_blank"><img
                              src="https://res.cloudinary.com/gig-logistics/image/upload/v1611738580/Customer%20Portal/linkedin_yulalt.png"
                              alt=""></a>
                </div>
              </div>
              <div class="visa-user-view" *ngIf="visaCheck == 'true'">
                <div class="visa-statement">
                  #WhereyouShop<span class="quote">Matters</span>
                </div>
                <div class="visa-logo-message">
                  <div class="message">Powered by</div>
                  <div class="logo-line">
                    <div class="image1"><img src="https://res.cloudinary.com/gig-logistics/image/upload/v1611830926/Customer%20Portal/image_5_oujayy.png" alt=""></div>
                    <div class="image2"><img src="https://res.cloudinary.com/gig-logistics/image/upload/v1611738653/Customer%20Portal/vbm_blugrad_1_fwlekz.png" alt=""></div>
                  </div>
                </div>
              </div>
             
              
             
            </div>
            <!-- <p class="m-0 mt-3">The Team at GIG Logistics</p> -->
          </div>
        </div>
        <div class="col-md-12 col-lg-6 mb-5">
          <form [formGroup]="signUpForm">
            <div class="form-group mb-4">
              <p class="al_input_title">Phone Number</p>
              <input class="al_input" (focusout)="validateNumber()" (input)="inputNumber($event)"  [ngClass]="{ invalid_: isPhone == false }" maxlength="11" minlength="10" formControlName="phoneNumber" (keypress)="onlyNumberKey($event)"  type="tel">
               <div *ngIf="signUpForm.controls['phoneNumber'].invalid && (signUpForm.controls['phoneNumber'].dirty || signUpForm.controls['phoneNumber'].touched)"
              class="errorinput text-left">
              <div class="errorShow" *ngIf="signUpForm.controls['phoneNumber'].errors.required">
                  *Phone Number is required.
              </div> 
              <div class="errorShow" *ngIf="signUpForm.controls.phoneNumber.errors.minlength || signUpForm.controls.phoneNumber.errors.maxlength">
                *Invalid phone number.
            </div>
               </div>
            </div>
            <div class="form-group mb-4">
              <p class="al_input_title">Business Name</p>
              <input class="al_input" (focusout)="validateBusiness()"  [ngClass]="{ invalid_: isBuss == false }"  formControlName="companyName"  type="text">
              <div *ngIf="signUpForm.controls['companyName'].invalid && (signUpForm.controls['companyName'].dirty || signUpForm.controls['companyName'].touched)"
              class="errorinput text-left">
              <div class="errorShow" *ngIf="signUpForm.controls['companyName'].errors.required">
                  *Business Name is required.
              </div>
               </div>
            </div>

            <div class="form-group mb-4 position-relative">
              <p class="al_input_title">Password</p>
              <input type="password" formControlName="password" (input)="getPassword()"  [type]="show ? 'text' : 'password'" class="al_input _noCase">
              <img *ngIf="show == false" (click)="password()" class="show_password" src="../../assets/new-image/visibility.png" alt="">
              <img *ngIf="show == true"  (click)="password()" class="show_password" src="../../assets/new-image/view.png" alt="">
              <div *ngIf="signUpForm.controls['password'].invalid && (signUpForm.controls['password'].dirty || signUpForm.controls['password'].touched)" class="errorinput text-left">
                <div class="errorShow" *ngIf="signUpForm.controls['password'].errors.required">
                  *Password is required.
                </div>
            </div>
            </div>
            <div class="passwordValid">
              <img *ngIf="isSecure == true" class="w-100 mt-2 mb-3" src="../../assets/new-image/pass_secure.svg" alt="">
              <ul class="ul_pass" *ngIf="isSecure == false">
                <li class="_password_text" [ngClass]="{ valid_match: isUpper == true }">
                 <span class="ml-2">Include upper letter</span>
                </li>
                <li class="_password_text" [ngClass]="{ valid_match: isLower == true }" >
                 <span class="ml-2">Include lowercase letter</span>
                </li>
                <li class="_password_text" [ngClass]="{ valid_match: isNumber == true }">
                   <span class="ml-2">Include number</span>
                </li>
                <li class="_password_text" [ngClass]="{ valid_match: isLength == true }">
                  <span class="ml-2">Password length should be minimum  6 character </span>
               </li>
              </ul>
            </div> 
            <div class="form-group mb-4">
              <p class="al_input_title">Country</p>
              <ng-multiselect-dropdown
                  [placeholder]="'Select Country'"
                  [data]="countryList"
                  [settings]="dropdownSettings"
                  formControlName="countryCode"
                  (onSelect)="onItemSelectProduct($event)"
                  (onSelectAll)="onSelectAllProduct($event)"
                  (onDeSelect)="onItemDeSelect($event)"
                >
                </ng-multiselect-dropdown>
              <div *ngIf="signUpForm.controls['countryCode'].invalid && (signUpForm.controls['countryCode'].dirty || signUpForm.controls['countryCode'].touched)"
              class="errorinput text-left">
              <div class="errorShow" *ngIf="signUpForm.controls['countryCode'].errors.required">
                  *Country is required.
              </div>
          </div>
            </div>
            <button class="al_btn  mt-4 mb-4" [disabled]="!signUpForm.valid || signUpForm.value.isCheck === false || isSecure == false || isBuss !== true || isPhone !== true || isLength !== true" (click)="step()">CREATE MY ACCOUNT</button>

            <div class="custom-control custom-checkbox">
              <input type="checkbox" (change)="check()" formControlName="isCheck" class="custom-control-input mr-2" id="customCheck1">
              <label class="custom-control-label " for="customCheck1"> </label>
              <span class="_text" style="color: black;">I acknowledge that I have read and do hereby accept the terms and conditions in the GIG Logistics <a href="https://giglogistics.com/terms" target="_blank" class="_nav_link"> Terms of use</a> and <a href="https://giglogistics.com/terms" target="_blank" class="_nav_link" > Merchant Agreement.</a></span>
            </div>
          </form>
        </div>
      </div>
    </div>
    
  </div>
  <div class="signIn text-center">
    <a> Already have an account? <span class="login_text" style="cursor: pointer;"  routerLink="/login">LOGIN</span>  </a>
  </div>
</section>

<div  class="modal" id="myModal"  #content>
  <div class="modal-dialog">
    <div class="modal-content">
    
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">Modal Heading</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      
      <!-- Modal body -->
      <div class="modal-body">
        Modal body..
      </div>
      
    </div>
  </div>
</div>