export class Header {
  tab = [
    'Dashboard',
    'Transaction History',
    'Wallet',
    'Payment Log',
    'Invoice',
    'Tracking',
    'Quick Quotes',
    'Cod',
    'Oversea Shipping',
    'Profile',
    'Change Password',
    'Process Terminal Shipment',
    'Subscription'
  ];
}
