import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { MerchantService } from '../services/merchant.service';
import { ApiCall } from "../services/api-call";
import { Router } from '@angular/router';
import { DataAccess } from '../helpers/dataAccess';

@Component({
  selector: 'app-ecommerce-registration',
  templateUrl: './ecommerce-registration.component.html',
  styleUrls: ['./ecommerce-registration.component.scss']
})
export class EcommerceRegistrationComponent implements OnInit {
  serviceCenterList: any;

  constructor(private fb: FormBuilder, private merchantApi: MerchantService, private apiCall: ApiCall, private router: Router) { }
  addressOption;
  apiError:boolean=false;
  businessList;
  businessName;
  countryId = JSON.parse(localStorage.getItem('countryId'));
  public dataAccess = new DataAccess();
  errorMessage;
  firstName = JSON.parse(localStorage.getItem('firstName'));
  lastName = JSON.parse(localStorage.getItem('lastName'));
  homeAddress;
  productCategory;
  registerEcommerceForm: FormGroup;
  selected: string[];
  selectedProducts: string[];
  serviceCenterId;
  spinning:boolean=false;
  successfullyUpgraded:boolean=false;
  successMessage;
  userAddress;
  



  redirectToSubscription(){
    this.router.navigate(['/mainpage/subscription']);
  }

  registerUser(){
    this.spinning=true;
    if(this.addressOption==null){
      this.spinning=false;
      return
    }
    if (this.addressOption =='HOMEDELIVERY'){
      this.userAddress=this.homeAddress;
      this.serviceCenterId=0;
    }
    else if (this.addressOption == 'SERVICECENTER'){
      this.userAddress=''
      // console.log(this.addressOption);
      
    }
    // console.log('works');
    // console.log(this.serviceCenterId);
    
    
    
    // console.log(this.homeAddress);
    

    // const payload={
    //   Industry: this.selected,
    //   Name: this.businessName,
    //   ProductType:this.selectedProducts,
    //   ReturnAddress:this.userAddress,
    //   ReturnOption: this.addressOption,
    //   ReturnServiceCentre: this.serviceCenterId,
    //   FirstName: this.firstName,
    //   LastName: this.lastName,
    // }
    let payload2={
      Name: this.businessName,
      Industry: this.selected,
      ProductType: this.selectedProducts,
      ReturnAddress: this.userAddress,
      ReturnOption: this.addressOption,
      ReturnServiceCentre: this.serviceCenterId,
      FirstName: this.firstName,
      LastName: this.lastName,
    }
    console.log(payload2);
    
this.apiCall.getUpgradeToEcommerce(payload2).subscribe(
  res=>{
    if(res.Code==200){
      this.spinning = false;
      this.dataAccess.setLocalStorageValue('rank', res.Object.Rank);
      this.dataAccess.setLocalStorageValue(
        'CustomerType',
        res.Object.CompanyType
      );
      this.successMessage = 'Successfully Upgraded.';
      this.successfullyUpgraded=true;      
    }
    else {
      this.apiError = true;
      this.spinning = false;
      this.errorMessage = res.ShortDescription;
    }
    //console.log(res);
  }
)

  }

  productList(){
    let payload={
        businessList: this.selected 
    }
    this.merchantApi.getProduct(payload).subscribe(
      res=>{
        if (res.statusCode == 200) {
          this.productCategory = res.data.productList;
        }
       
      },
      error=>{
        this.spinning=false;
        this.errorMessage = error.statusText
        this.apiError=true;

      }
    )
  }

  ngOnInit(): void {
    this.spinning = true;
    this.merchantApi.getBusiness().subscribe(
      res=>{
        if (res.statusCode==200){
          this.spinning = false;
          this.businessList = res.data.businessList
        }
      
      },
      error=>{
        this.errorMessage = error.statusText;
        this.spinning=false;
        this.apiError=true;
      }

    )
    this.apiCall.getServiceCenterByCountry(this.countryId).subscribe(
      res=>{
        if(res.Code==200){
          this.spinning=false;
          console.log(res);
          this.serviceCenterList = res.Object;
        }
        else {
          this.apiError = true;
          this.spinning = false;
          this.errorMessage = res.ShortDescription;
        }
       
      }
    )
    this.registerEcommerceForm = this.fb.group({
      business_category: ['', [Validators.required]],
      product_category: ['', [Validators.required]],
      business_name: ['', [Validators.required]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      service_center: [''],
      address: [''],
      home_address: [''],
      
    });

  }

}
