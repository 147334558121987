<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="container-class">
  <div class="profile-page-header">Profile Page</div>
  <div class="profile-page">
    <div class="profile-picture"><i class="fa fa-user-circle"></i></div>
    <div class="profile-name">Hello, {{ this.profileObj.CustomerName }}</div>
    <div class="profile-information">
      <div class="testing-container">
        <div class="section code-container">
          <span class="header-text">User Code</span>
          <div class="profile-code">{{ this.profileObj.CustomerCode }}</div>
        </div>
        <div class="section address-container">
          <span class="header-text">Email</span>
          <div class="profile-email">{{ this.profileObj.Email }}</div>
        </div>

        <div class="section number-container">
          <span class="header-text">Phone Number</span>
          <div class="profile-number">{{ this.profileObj.PhoneNumber }}</div>
        </div>
        <div class="section address-container">
          <span class="header-text">Address</span>
          <div class="profile-address">{{ this.profileObj.Address }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
