import { ApiCall } from "../services/api-call";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TransactionHistory } from '../models/transactionhistory';

@Injectable({
  providedIn: 'root',
})

export class TransactionHistoryApi{
 constructor(private apiCall:ApiCall,private httpClient:HttpClient) {}
   
baseUrl1=this.apiCall.API1;
baseUrl2=this.apiCall.API2;
baseHeader=this.apiCall.authHeader();

getTransactionHistory(transaction: TransactionHistory):Observable<any>{
return this.httpClient.post(`${this.baseUrl1}/portal/getwallettransactionandpreshipmenthistory`,transaction,this.baseHeader)
}
}