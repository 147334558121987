import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CodComponent } from './cod/cod.component';
import { CodremittancesComponent } from './codremittances/codremittances.component';
import { CreateshipmentComponent } from './createshipment/createshipment.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { NavSideBarComponent } from './nav-side-bar/nav-side-bar.component';
import { PaymentLogComponent } from './payment-log/payment-log.component';
import { PickUpReequestComponent } from './pick-up-reequest/pick-up-reequest.component';
import { QuickQuoteComponent } from './quick-quote/quick-quote.component';
import { SlaComponent } from './sla/sla.component';
import {PaymentPageComponent} from './payment-page/payment-page.component'
import { TrackingComponent } from './tracking/tracking.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { WalletComponent } from './wallet/wallet.component';
import { LoginComponent } from './login/login.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { ProfileComponent } from './profile/profile.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';
import { PickUpRequestDetailComponent } from './pick-up-request-detail/pick-up-request-detail.component';
import { SignupComponent } from './signup/signup.component';
import { AuthGuard } from './services/auth.guard';
import {PublictrackComponent} from './publictrack/publictrack.component';
import { ShipmentTransactionComponent } from './shipment-transaction/shipment-transaction.component';
import { ProcessTerminalShipmentComponent } from './process-terminal-shipment/process-terminal-shipment.component';
import { TrackByWaybillComponent } from './track-by-waybill/track-by-waybill.component';
import { from } from 'rxjs';
import { MerchantAccountComponent } from './merchant-account/merchant-account.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TransactionHistoryDetailsComponent } from './transaction-history-details/transaction-history-details.component';
import { EcommerceRegistrationComponent } from './ecommerce-registration/ecommerce-registration.component';

const routes: Routes = [
  {
    path: 'mainpage',
    component: NavSideBarComponent,
    canActivate: [AuthGuard],
    children: [
      { path: 'cashondelivery', component: CodComponent },
      { path: 'codreceipthistory', component: CodremittancesComponent },
      { path: 'createshipment', component: CreateshipmentComponent },
      { path: 'invoice', component: InvoiceComponent },
      { path: 'paymentlog', component: PaymentLogComponent },
      { path: 'pickuprequest', component: PickUpReequestComponent },
      { path: 'pickuprequestdetail', component: PickUpRequestDetailComponent },
      { path: 'quickquote', component: QuickQuoteComponent },
      { path: 'paymentpage/:waybill',component:PaymentPageComponent},
      { path: 'sla', component: SlaComponent },
      { path: 'shipmenttracking', component: TrackingComponent },
      { path: 'shipmenttracking/:waybill', component: TrackByWaybillComponent },
      { path: 'customertransactions', component: TransactionHistoryComponent },
      { path: 'walletsummary', component: WalletComponent },
      { path: 'changePassword', component: PasswordChangeComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'printInvoice', component: ViewInvoiceComponent },
      {path:'registerAsEcommerce', component: EcommerceRegistrationComponent},
      { path: 'shipmenttransaction', component: ShipmentTransactionComponent },
      {path:'processterminalshipment',component:ProcessTerminalShipmentComponent},
      { path: 'transactionhistorydetail/:waybill', 
      component: TransactionHistoryDetailsComponent},
      { path: '', component: DashboardComponent },
      {
        path: "merchant-boarding",
        loadChildren: () =>
          import("../app/merchant-boarding/merchant-boarding.module").then(
            (m) => m.MerchantBoardingModule
          )
      },
      {path:'subscription',component:SubscriptionComponent},
    ],
  },
   {path:'public/track/:waybill',component:PublictrackComponent},
  { path: 'login', component: LoginComponent },
  { path: 'merchantSignUp', component: MerchantAccountComponent },
  { path: 'merchantSignUp?visa=true', component: MerchantAccountComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },

  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
