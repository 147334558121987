import { ApiCall } from "../services/api-call";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TransactionHistory } from '../models/transactionhistory';
@Injectable({
  providedIn: 'root',
})
export class DashboardApi{

constructor(private apiCall:ApiCall,private httpClient:HttpClient) {}
   
baseUrl1=this.apiCall.API1;
baseUrl2=this.apiCall.API2;
baseHeader=this.apiCall.authHeader();

getCod():Observable<any>{
   return this.httpClient.get(`${this.baseUrl1}/portal/cod`,this.baseHeader)
}
getTransactionHistory(transaction: TransactionHistory):Observable<any>{
return this.httpClient.post(`${this.baseUrl1}/portal/getwallettransactionandpreshipmenthistory`,transaction,this.baseHeader)
}
addwalletpaymentlog(payload: any):Observable<any>{
    return this.httpClient.post(`${this.baseUrl1}/portal/addwalletpaymentlog`,payload,this.baseHeader)
}
getWallet():Observable<any>{
    return this.httpClient.get(`${this.baseUrl1}/portal/wallet`,this.baseHeader)
}
getDashboard():Observable<any>{
    return this.httpClient.get(`${this.baseUrl1}/portal/dashboard`,this.baseHeader)
}
}