import { Component, OnInit } from '@angular/core';
import { ForgotPassword } from '../models/forgotPassword';
import { ApiCall } from '../services/api-call';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  password = new ForgotPassword();
  success: boolean = false;
  apiError: boolean = false;
  successMessage;
  errorMessage;
  spinning: boolean = false;
  constructor(
    private apiCall: ApiCall,
    private router: Router,
    private fb: FormBuilder
  ) {}
  closePopUp() {
    location.reload();
    this.success=false;
    
    
  }
  SubmitPassword(e) {
    this.spinning = true;
    this.apiCall.getForgotPassword(this.password).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.success = true;
      } else {
        this.success = false;
        this.spinning = false;
        this.errorMessage = data.ShortDescription;
      }
    });
  }
  returnToLogin() {
    this.router.navigate(['login']);
    // location.reload();
  }
  resetPasswordForm = this.fb.group({
    email: ['', [Validators.required]],
  });

  ngOnInit(): void {}
}
