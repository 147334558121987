import { ApiCall } from "../services/api-call";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ViewInvoiceApi{
constructor(private apiCall:ApiCall,private httpClient:HttpClient) {}
   
baseUrl1=this.apiCall.API1;
baseUrl2=this.apiCall.API2;
baseHeader=this.apiCall.authHeader();

 getByWaybill(waybill: number): Observable<any> {
     return this.httpClient.get(`${this.baseUrl1}/portal/bywaybill/${waybill}`,this.baseHeader)
 }
}