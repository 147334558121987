<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="apiError">
  <div class="password-message">
    <div class="message">{{errorMessage}}</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div>
<div class="overlay" *ngIf="walletError">
  <div class="password-message">
    <div class="message">Enter a valid amount</div>
    <button (click)="walletError=false">Ok</button>
  </div>
</div>


<div class="container-class dashboard">
  <div class="container-content dashboard-content">
    <!-- <button class="fund-wallet-button" (click)="fundWallet()">
      <i class="fas fa-wallet"></i>Fund Wallet
    </button> -->
    <!-- <div class="page-text">
      <h2>Dashboard</h2>
    </div> -->

    <div class="wallet-terms-message">
      Funds credited to your wallet are non refundable and cannot be withdrawn. They shall be applied solely towards
        your transactions within GIGL Ecosystem.
    </div>
    <div class="dashboard-cards">
      <div class="dashboard-cards-item display-card shipment-card">
        <span class="border-line1"></span>
        <div class="display-card-text flex-direction-change">
          <p>Shipment Ordered</p>
          <h2>{{ shipmentOrdered }}</h2>
        </div>
        <div class="flex-container flex-container-1">
          <div class="display-icon-container shipment-card-container">
            <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/truck-icon.png" alt="" />
          </div>
        </div>
      </div>

      <div
        class="dashboard-cards-item display-card wallet-card wallet-card-container"
      >
        <span class="border-line2"></span>
        <div class="display-card-text flex-direction-change">
          <p>wallet Balance</p>
          <h2>{{ currencySymbol }}{{ walletBalance | number }}</h2>
        </div>
        <div class="flex-container">
          <div class="display-icon-container wallet-icon-container">
            <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/wallet-icon.png" alt="" />
          </div>
        </div>
      </div>

      <div class="dashboard-cards-item default-width">
        <div class="fund-section">
          <div class="card-section-header">
            <h4>Fund Wallet</h4>
          </div>
          <div class="card-section-form">
                 <div class="general-contain">
                        <div class="input-box">
                          <input type="number" (keypress)="keyPress($event)" (keyup)="amountChange($event)" [(ngModel)]="amount"
                            placeholder="Enter Amount" pattern="[0-9]" />
                        </div>

                        <div class="btn-box">
                          <button (click)="activatePaymentOptions()" class="some-fund">
                            Pay
                          </button>
                        </div>
            </div>     
          </div>
           
        </div>
      </div>
    </div>
    <div class="function-button">
      <div class="table-buttons">
        <span
          ><button
            class="table-button"
            printSectionId="printSection"
            ngxPrint
            [useExistingCss]="true"
          >
            <i class="fas fa-print"></i>Print
          </button></span
        >
        <span
          ><button
            class="table-button"
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Top 5',
                sheet: 'sheet_name',
                Props: { Author: 'GIGL' }
              })
            "
          >
            <i class="far fa-file-excel"></i>Excel
          </button></span
        >
        <!-- <span
          ><button class="table-button">
            <i class="far fa-file-pdf"></i>PDF
          </button></span
        > -->
      </div>
    </div>
    <div class="dashboard-table display-tbl">
      <div class="dashboard-table-container">
        <div class="dashboard-table-content table-content">
          <div class="table-filter-container">
    <div class="table-filter">
      <i class="fas fa-search"></i>
      <input class="filter-line" matInput (keyup)="applyFilter($event)" placeholder="Search" />
    </div>
          </div>
      
          <div class="table" id="printSection">
            <table
              mat-table
              matTableExporter
              #exporter="matTableExporter"
              hiddenColumns="[8]"
              [dataSource]="dataSource"
            >
              <!-- Position Column -->
              <ng-container matColumnDef="sn">
                <mat-header-cell *matHeaderCellDef>SN</mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                  {{ i + 1 }}
                </mat-cell>
              </ng-container>
              <!-- waybill column -->
              <ng-container matColumnDef="waybill">
                <mat-header-cell *matHeaderCellDef
                  >Waybill Number</mat-header-cell
                >
                <mat-cell *matCellDef="let element" data-label="Waybill">
                  {{ element.Waybill }}
                </mat-cell>
              </ng-container>

              <!-- Departure Column -->
              <ng-container matColumnDef="departure">
                <mat-header-cell *matHeaderCellDef>Departure</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Departure">
                  {{ element.SenderAddress }}
                </mat-cell>
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="destination">
                <mat-header-cell *matHeaderCellDef>Destination</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Destination">
                  {{ element.ReceiverAddress }}
                </mat-cell>
              </ng-container>
              <!-- Amount column -->
              <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Amount">
                  {{ currencySymbol }} {{ element.GrandTotal | number }}
                </mat-cell>
              </ng-container>
              <!-- partner column -->
              <!-- <ng-container matColumnDef="pickup">
                <mat-header-cell *matHeaderCellDef
                  >Pickup Options</mat-header-cell
                >
                <mat-cell *matCellDef="let element" data-label="Pickup Options">
                  {{ element.PickupOptions }}
                </mat-cell>
              </ng-container> -->
              <!-- date column -->
              <ng-container matColumnDef="receiver">
                <mat-header-cell *matHeaderCellDef>Receiver</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Receiver">
                  {{ element.ReceiverName }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Description">
                  {{ element.PreShipmentItems[0].Description }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Date">
                  {{ element.DateCreated | date }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="options">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index" class="track"
                  ><span class="print-button"
                    ><i class="fas fa-print" (click)="getWaybill(i)"></i
                  ></span>

                  <a [routerLink] = "['/mainpage/shipmenttracking',  element.Waybill]" class="track-button">
                    <i
                      class="fas fa-map-marker-alt"
                      
                    ></i>
                    <span class="track-text" 
                      >Track</span>
                  </a>
                </mat-cell>
              </ng-container>
              <div>
                 
              </div>
           

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
        
        
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="overlay" *ngIf='payment'>
  <div class="validation-message">
    <div class="close-payment-popup"><i class="far fa-times-circle" (click)="closePaymentOptions()"></i></div>
    <div class="message-text"> <div class="payment-pop-up-message">Pay With</div></div>
    <div class="image-container">
<div class="paystack-image">
  <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/paystack (1).png"
    alt="" (click)="paymentLog(1)">
</div>
<div class="flutterwave-image">
  <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/flutterwave (1).png"
    alt="" (click)="paymentLog(0)">
</div>
    </div>
  </div>
</div>
<div class="overlays" id="validatepayment" >
  <div class="password-message">
    <div class="message">Payment Successful</div>
    <button (click)='verifyPayment()'>Ok</button>
  </div>
</div>
