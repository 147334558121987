import {
  HttpClient,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthHelpers } from '../helpers/auth';
@Injectable({
  providedIn: 'root',
})
export class UploadService {
  public authHelpers = new AuthHelpers();
  token = this.authHelpers.getAccessToken('accessToken');
  SERVER_URL: string =
    'https://giglgodev.herokuapp.com/api/s3upload/image-upload';
  constructor(private httpClient: HttpClient) {}
  public upload(formData) {
    return this.httpClient
      .post<any>(this.SERVER_URL, formData, {
        headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.token),
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return event.body;
            default:
              return `Unhandled event: ${event.type}`;
          }
        })
      );
  }
}
