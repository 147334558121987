

import { Injectable } from '@angular/core';
import { AuthHelpers } from '../helpers/auth';
import { Observable, from, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MerchantService {
  public authHelpers = new AuthHelpers();

  public API2 = 'https://nodeapi.gigl-go.com/apimerchant'; // dev zimble
  public API1 ='https://giglmerchant.herokuapp.com/apimerchant';
  token = this.authHelpers.getAccessToken('accessToken');

  alertChannel: Subject<any> = new Subject();
  alertSubscription$ = this.alertChannel.asObservable();

  constructor(private httpClient: HttpClient) { }

  authHeader() {
    this.token = this.authHelpers.getAccessToken('accessToken');
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', `Bearer ${this.token}`);
    return { headers };
  }

  createOTP(body: any): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/otp/create`, body
    );
  }
  verifyOTP(body: any): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/otp/verify`, body
    );
  }
  verifyMerchant(body: any): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/merchant/verify`, body
    );
  }

  verifyBVN(bnv:any): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/merchant/verifybvn/`+bnv,
    );
  }
  
  verifyPayment(redId: any): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/merchant/payment/success/`+redId,
    );
  }

  PostMerchant(body: any): Observable<any> {
    let isfrom= "";
    if(body['rank'] == 'class')  {
      isfrom = 'from=web'
    }
    return this.httpClient.post(
      `${this.API1}/merchant?`+isfrom, body
    );
  }
  upgradeMerchant(body: any): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/merchant/upgrade?from=web`, body
    );
  }
  editCard(body: any): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/merchant/editcard`, body
    );
  }
  getimageUpload(image): Observable<any> {
    const formData = new FormData();
    formData.append("image", image, 'image.jpg');
    return this.httpClient.post(
      `${this.API1}/s3upload/image-upload`, formData
    );
  }

  getWallet(): Observable<any> {
    return this.httpClient.get(`${this.API2}/portal/wallet`, this.authHeader());
  }
  getCountry(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/country`,
    );
  }
  getSubAmount(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/merchant/planDetails`,
    );
  }
  getSubscriptionDetail(query:any): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/merchant/subscription?`+query,
    );
  }
  cancelledSubscription(body:any): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/merchant/cancel/subscription`, body
    );
  }

  renewSubscription(body:any):Observable<any>{
    return this.httpClient.post(
      `${this.API1}/merchant/subscription/reinstate`
    ,body)
  }

  getStateByCountry(countryName: any): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/country/state/` + countryName,
    );

  }
  getBusiness(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/general/business`,
    );
  }
  getProduct(body: any): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/general/product`,
      body
    );
  }
  getIdentity(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/general/identityType`
    );
  }
  streamMessage(data: any) {
    let obj = { data: data };
    this.alertChannel.next(obj);
  }
 getSubscription(): Observable<any> {
    return this.alertSubscription$;
  }
  
// getMerchantBusiness():Observable<any>{
//   return this.httpClient.get(`${this.API1}/general/business `)
// }


}
