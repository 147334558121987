import { Component, OnInit } from '@angular/core';
import { ApiCall } from '../services/api-call';
import { ProcessShipmentApi } from './process-terminal-shipment-api';
import { AuthHelpers } from '../helpers/auth';
import { DataAccess } from '../helpers/dataAccess';
import { PaymentService } from '../services/payment.service';
import { Flutterwave, InlinePaymentOptions, PaymentSuccessResponse, AsyncPaymentOptions } from "flutterwave-angular-v3";
// import { FlutterwaveCheckout } from 'flutterwave-angular-v3/src/app/modules/models';
declare var PaystackPop: any;

@Component({
  selector: 'app-process-terminal-shipment',
  templateUrl: './process-terminal-shipment.component.html',
  styleUrls: ['./process-terminal-shipment.component.scss'],
})

export class ProcessTerminalShipmentComponent implements OnInit {
  apiError: boolean = false;
  public authHelpers = new AuthHelpers();
  payment: boolean;
  paymentType: any;
  constructor(private apiCall: ApiCall, private processTerminalShipmentApi: ProcessShipmentApi, private flutterWave: Flutterwave, private paymentKey: PaymentService) {}
  countryId: string = localStorage.getItem('countryId');
  currencyCode = localStorage.getItem('currencyCode');
  
  email = this.authHelpers.getAccessToken('email');
  dataAccess = new DataAccess();
  waybill;
  noTerminalShipment:boolean=false;
  openPopup:boolean=false;
  processTerminalShipmentData;
  spinning: boolean = false;
  message;
  paymentIndex;
  phoneNumber = this.authHelpers.getAccessToken('phoneNumber');
  customerFullName = this.dataAccess.getLocalStorageValue('CustomerFullName');
  paystackAmount;
  secondFlutterAmount;
  submit: boolean = false;
  testkey_live_Nigeria = 'pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b';
  testkey_live_ghana = 'pk_live_385c2ba036608c0b5fc902b32fd4b4fb07fcc01d';
  refCode;
  payStackFlutterNum;
  
  reload() {
    this.submit = false;
    location.reload();
  }
closePopUp(){
this.noTerminalShipment=false;
}
closePaymentPopup=()=>{
  this.openPopup=false;
}
  openPaymentPopup(i){
    this.openPopup=true;
    this.paymentIndex=i;
  }
  activatePaymentOptions() {
  this.openPopup=false;
    this.payment = true;

  }
  closePaymentOptions = () => {
    this.payment = false;
  }
  processPayment() {
    this.spinning = true;
    this.openPopup=false;
    this.waybill = this.processTerminalShipmentData[this.paymentIndex].Waybill;
    this.processTerminalShipmentApi.payForShipment(this.waybill).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.submit = true;
        this.message = data.ShortDescription;
      } else {
        this.spinning = false;
        this.submit = true;
        this.message = data.ShortDescription;
      }
    });
  }
  verifyPayment() {
    document.getElementById('validatepaymentprocess').style.display = 'none';
    this.spinning = true;
    console.log(this.refCode2);
    
    this.apiCall.getVerifyPayment(this.refCode2).subscribe(
      data => {
        if (data.Code == 200) {
          this.spinning = false;
          //console.log(data);
          
          location.reload();
        }
        else {
          this.spinning = false;
          this.submit = true;
          this.message = data.ShortDescription;
        }

      }
    )
  }
  payWithCard(x){
    this.openPopup = false;
    this.payment=false;
    this.spinning=true;
    //this.refCode = 'wb-' + this.processTerminalShipmentData[this.paymentIndex].Waybill + '-' + Math.floor(Math.random() * 1000000000 + 1);
    if(x==1){
      this.paymentType='PayStack'
    }
    else if(x==0){
      this.paymentType='Flutterwave'
    }
    const payload={
      waybill: this.processTerminalShipmentData[this.paymentIndex].Waybill,
      Reference: this.refCode2,
      OnlinePaymentType:this.paymentType
    }
    this.processTerminalShipmentApi.addwaybillpaymentlog(payload).subscribe(
      data=>{
        if (data.Code==200){
          console.log(data);
          this.spinning=false;
          this.paystackAmount = data.Object.Amount;
          this.secondFlutterAmount=data.Object.Amount;
if(x==1){
  this.payWithPaystack();
}
else if(x==0){
  this.payWithFlutterWave();
}
         
        }
        else{
          this.spinning = false;
          this.submit = true;
          this.message = data.ShortDescription;
        }
        
      }
    )
  }
  //testPayStackKey = 'pk_test_7140f98d7c52c6e559d00182fce18ec6f98443ae';
  payWithPaystack() {
    // var payStackKey;

    // if (this.countryId == '1') {
    //   payStackKey = this.testkey_live_Nigeria;
    // } else if (this.countryId == '76') {
    //   payStackKey = this.testkey_live_ghana;
    // }
    var handler = PaystackPop.setup({
      key: this.paymentKey.getPaystackKey(),
      email: this.email,
      ref: '' + this.refCode2,
      amount: this.paystackAmount * 100,
      currency: this.currencyCode,
      metadata: {
        custom_fields: [
          {
            display_name: 'Mobile Number',
            variable_name: 'mobile_number',
            value: this.phoneNumber,
          },
        ],
      },
      callback: function (response) {
        // location.reload();
      },
      onClose: function () {
        alert('Payment Cancelled');
        location.reload();
      },
      onSuccess: function () {
        document.getElementById('validatepaymentprocess').style.display = 'block';
      },
    });
    handler.openIframe();
  }
  //flutterWave_test = 'FLWPUBK_TEST-0797c1579353fe90e2a60da4c7586fff-X';
  refCode2 = 'WB-' + Math.floor(Math.random() * 1000000000 + 1) + '-WEB';


  payWithFlutterWave = () => {
    this.payment = false;
    //console.log(this.refCode2);
    let paymentData: InlinePaymentOptions = {
      public_key: this.paymentKey.flutter1,
      tx_ref: this.refCode2,
      amount: this.secondFlutterAmount,
      currency: this.currencyCode,
      payment_options: 'card,ussd',
      redirect_url: '',
      // meta: this.meta,
      customer: {
        email: this.email,
        phone_number: this.phoneNumber,
        name: this.customerFullName,
      },
      customizations: {
        title: "GIG Logistics",
        description: "Shipment Payment",
        logo: "https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/GIGLogin.png",
      },
      callback: this.makePaymentCallback,
      onclose: this.closedPaymentModal,
      callbackContext: this
    }

    this.flutterWave.inlinePay(paymentData);

  }
  verifyReload: boolean = false;
  makePaymentCallback(response: PaymentSuccessResponse): void {
    this.verifyReload = true;
   
    console.log("Payment callback", response);
  }

  closedPaymentModal(): void {
    console.log('payment is closed');
    if (this.verifyReload) {
   
      document.getElementById('validatepaymentprocess').style.display = 'block';
    }
    else {
      location.reload();
    }

  }
 
 

  ngOnInit(): void {
    this.spinning = true;
    console.log("first one",this.currencyCode);
    
    // console.log('second', this.dataAccess.getLocalStorageValue('currencyCode'));
    
    
    document.getElementById('validatepaymentprocess').style.display = 'none';
    this.processTerminalShipmentApi.getProcessTerminalShipment().subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.processTerminalShipmentData = data.Object;
        
        
        if(data.Object==0){
          this.noTerminalShipment=true;
        }
      }
      else{
          this.submit = true;
        this.message = data.ShortDescription;
      }
    });
  }
}



 