import { Component, OnInit } from '@angular/core';
import { TransactionHistoryApi} from './transaction-history-details-api'
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-transaction-history-details',
  templateUrl: './transaction-history-details.component.html',
  styleUrls: ['./transaction-history-details.component.scss']
})

export class TransactionHistoryDetailsComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  waybillNumber;
  spinning: boolean = true;
  departure;
  destination;
  delivery;
  pickUp;
  total;
  grandTotal;
  value;
  vat;
  discount;
  invoiceDiscount;
  offInvoice;
  shipmentPackagePrice;
  date;
  customerName;
  customerEmail;
  customerNumber;
  customerAddress;
  customerState;
  currencySymbol;
  receiverName;
  receiverEmail;
  receiverNumber;
  receiverAddress;
  receiverState;
  shipmentStatus;
  pickUpRequestdetailData;
  cashOnDelivery;
  pickupOptions;
  qrCode;
  receiverCode;
  showAmount = false;
  // responsible for table column
  displayedColumns: string[] = ['position', 'description', 'nature', 'weight'];
  constructor(private apiCall: TransactionHistoryApi, private router: Router, private route: ActivatedRoute) { 
    this.route.params.subscribe(
      params=>this.waybillNumber=params.waybill
    )
  }

  ngOnInit(): void {
    this.apiCall.getTransactionHistoryDetails(this.waybillNumber).subscribe(
   (data)=>{
        if (data.Code == 200) {
          
          console.log(data);
          
          this.spinning = false;
          this.pickUpRequestdetailData = data.Object;
          this.customerName = this.pickUpRequestdetailData.SenderName;
          this.customerNumber = this.pickUpRequestdetailData.SenderPhoneNumber;
          this.customerAddress = this.pickUpRequestdetailData.SenderAddress;
          this.customerState = this.pickUpRequestdetailData.SenderLocality
          this.currencySymbol = this.pickUpRequestdetailData.CurrencySymbol
          this.receiverName = this.pickUpRequestdetailData.ReceiverName;
          this.receiverEmail = this.pickUpRequestdetailData.ReceiverEmail;
          this.receiverNumber = this.pickUpRequestdetailData.ReceiverPhoneNumber;
          this.receiverState = this.pickUpRequestdetailData.ReceiverState;
          this.receiverAddress = this.pickUpRequestdetailData.ReceiverAddress;
          this.grandTotal = this.pickUpRequestdetailData.GrandTotal;
          this.pickupOptions = this.pickUpRequestdetailData.PickupOptions
          this.total = this.pickUpRequestdetailData.Total;
          this.vat = this.pickUpRequestdetailData.Vat;
          this.cashOnDelivery = this.pickUpRequestdetailData.CashOnDeliveryAmount;
          this.discount = this.pickUpRequestdetailData.DiscountValue;
          this.invoiceDiscount = this.pickUpRequestdetailData.InvoiceDiscountValue_display;
          this.offInvoice = this.pickUpRequestdetailData.offInvoiceDiscountvalue_display;
          this.shipmentPackagePrice = this.pickUpRequestdetailData.ShipmentPackagePrice;
          this.date = this.pickUpRequestdetailData.DateCreated;
          this.value = this.pickUpRequestdetailData.Value;
          this.qrCode = this.pickUpRequestdetailData.QRCode;
          this.receiverCode = this.pickUpRequestdetailData.SenderCode;
          this.shipmentStatus = this.pickUpRequestdetailData.shipmentstatus
          this.dataSource = new MatTableDataSource(
            this.pickUpRequestdetailData.PreShipmentItems
          );
          if (this.pickUpRequestdetailData.CashOnDeliveryAmount) {
            this.showAmount = true;
          } else {
            this.showAmount = false;
          }
        }
   }
    )

  }

}
