<div class="overlays" *ngIf="spinning">
    <div class="centers">
        <div class="spinner-grow spin" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="overlay" *ngIf="apiError">
    <div class="password-message">
        <div class="message">{{errorMessage}}</div>
        <button (click)="apiError=false">Ok</button>
    </div>
</div>
<div class="overlay" *ngIf="successfullyUpgraded">
    <div class="password-message">
        <div class="message">{{successMessage}}</div>
        <button (click)="redirectToSubscription()">Ok</button>
    </div>
</div>

<div class="container-class">
    <div class="container-content">
        <div class="register-message">
            Register as an ecommerce merchant
        </div>
        <div class="form-container">
           <form action=""  [formGroup]="registerEcommerceForm" (submit)="registerUser()">
            <div class="form-content">
                <div class="form-field">
                    <label for="">First name</label>
                    <div class="input-field">
                        <input type="text"  formControlName="first_name" [(ngModel)]="firstName">
                    </div>
                </div>
                <div class="form-field">
                    <label for="">Last name</label>
                    <div class="input-field">
                        <input type="text"  formControlName="last_name" [(ngModel)]="lastName">
                    </div>
                </div>
                <div class="form-field">
                    <label for="">Business name</label>
                    <div class="input-field">
                        <input type="text"  formControlName="business_name" [(ngModel)]="businessName">
                    </div>
                </div>
                <div class="form-field">
                    <label for="">Business Category</label>
                    <div class="input-field">
                    <ng-select [items]="businessList" bindLabel="name" bindValue="name" placeholder="Select an option" appendTo="body" multiple="true"
                        [(ngModel)]="selected"
                        formControlName="business_category"
                        (change)="productList()"
                        >
                    </ng-select>
                    <!-- {{selected}} -->
                    </div>
                </div>
                <div class="form-field">
                    <label for="">Product Category</label>
                    <div class="input-field">
                    <ng-select [items]="productCategory" bindLabel="productList" bindValue="productList" placeholder="Select an option" appendTo="body"
                        multiple="true" [(ngModel)]="selectedProducts" formControlName="product_category">
                    </ng-select>
                    </div>
                </div>
                <div class="form-field">
                    <label for="">Return Address</label>
                    <div class="input-field checkbox-input">
                    <span> <label for="">Terminal Address</label>
                        <input type="radio" id="terminalAddress" name="address" [(ngModel)]="addressOption" value="SERVICECENTER" formControlName="address">
                    </span>
                    <span>
                        <input type="radio" id="homeAddress" name="address" [(ngModel)]="addressOption" value="HOMEDELIVERY" formControlName="address"> <label for="">Home Address</label>
                    </span>
                    </div>
                    <div class="address-input" *ngIf="addressOption=='HOMEDELIVERY'">
                        <input type="text" placeholder="Enter Home address" [(ngModel)]="homeAddress" name='home_address' [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="address-input" *ngIf="addressOption=='SERVICECENTER'">
                        <ng-select [items]="serviceCenterList" bindLabel="Name" bindValue="ServiceCentreId" placeholder="Select service Center"
                            appendTo="body" [(ngModel)]="serviceCenterId" formControlName="service_center">
                        </ng-select>
                    </div>
                </div>
                <div class="form-field">
                    <button  [disabled]="!registerEcommerceForm.valid">Submit</button>
                </div>
            </div>




           </form>
        </div>
    </div>
</div>