import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  flutter2 = 'FLWPUBK_TEST-0797c1579353fe90e2a60da4c7586fff-X';
  flutter1 = 'FLWPUBK-cddb1a65501aecf88e7c1dbd00e5cdcc-X';
  countryId = JSON.parse(localStorage.getItem('countryId'));
  paystack2 = 'pk_test_7140f98d7c52c6e559d00182fce18ec6f98443ae';
  testkey_live_Nigeria = 'pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b';
  testkey_live_ghana = 'pk_live_385c2ba036608c0b5fc902b32fd4b4fb07fcc01d';
  paystack1 = '';
  

 getPaystackKey(){
   if (this.countryId === 1) {
     this.paystack1 = this.testkey_live_Nigeria;

     
   } else if (this.countryId === 76) {
     this.paystack1 = this.testkey_live_ghana;
   
     
   }
   return this.paystack1;
 }

  constructor() { }
}
