<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<div class="overlay" *ngIf="succesfulUpload">
  <div class="password-message">
    <div class="message">Succesful Upload</div>
    <button (click)="closePopUpInternational()">Ok</button>
  </div>
</div>
<div class="overlay" *ngIf="deleteItem">
  <div class="password-message delete-international">
    <div class="message">Are you sure you want to delete this item</div>
    <button (click)="yesDeleteItem()"><i class="far fa-check-circle"></i></button> <button class="delete-item" (click)="noDeleteItem()"><i class="far fa-times-circle"></i></button>
  </div>
</div>
<div class="overlay" *ngIf="viewStores">
  <div class="password-message-stores">
    <div class="intro-message">Select a Store from the list below</div>
    <div class="message" *ngFor="let store of linkArray">
      <div class="international-stores-link"><a target="_blank" href={{store.links}}>{{store.stores}}</a></div>
      
    </div>
    <button (click)="closePopUp()">Close</button>
  </div>
</div>
<div class="overlay" *ngIf="internationalShipmentTermsandConditions">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="password-message-terms" [ngStyle]="{'visibility': displayTermsMessage ? 'visible' : 'hidden' }">
    <div class="password-message-terms-header">
      <div class="header-message"><i class="fas fa-search"></i> <span>Overseas shipping detected</span></div> <div class="close-header" (click)="closePopUp()">X</div>
    
    </div>
    <div class="message termsMessage">    
    </div>
    <!-- <button (click)="closePopUp()">Proceed</button> -->
  </div>
</div>

<div class="overlay" *ngIf="submitShipment">
  <div class="password-message">
    <div class="message">{{ submitShipment.ShortDescription }}</div>
    <!-- <button *ngIf="submitShipment.Code == 200" (click)="reload()">Ok</button> -->
    <button *ngIf="submitShipment.Code != 200" (click)="closePopUp()">
      Ok
    </button>
  </div>
</div>
<div class="overlay" *ngIf="validation">
  <div class="password-message">
    <div class="message">{{ validationMessage }}</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div>
<div class="overlay" *ngIf="successfulInternationalShipment">
  <div class="password-message-international">
    <div class="message">
      <div class="message-logo">
        <i class="far fa-check-circle"></i>
      </div>
      <div class="message-info">
        Your shipment registration with transaction ID {{requestNumber}} was successful.
      </div>
      <div class="message-info-two">
        You should receive an email from us shortly containing a summary of your overseas shipping request.
        <br>
        <br>
        We would also send a follow up email to you as soon as we receive your package at our delivery hub.
      </div>
      <div class="message-info-three">
        <div class="header-info">
        <span class="header-info-message">Do not keep this information to yourself</span>
        </div>
        <div class="body-info">
          Tell your friends and family about this super cool site that makes overseas shipping so seamless.
        </div>
      </div>
    </div>
    <div class="share-button-container">
      <div class="share-button" (click)="reload()">Okay</div>
     
    </div>
    <!-- <button (click)="closePopUp()">Ok</button> -->
  </div>
</div>

<div class="container-class">
  <div class="not-verified-container" *ngIf="Registration">
    <div class="heading1">
      <span class="heading1-asterik"
        ><i class="fas fa-exclamation-circle"></i
      ></span>
      The security of your item(s) and fraud prevention is important to GIG
      Logistics.

      <br />
      <br />

      Please click on the drop down below to select and upload a form of
      identification to further guarantee the security of your item.
    </div>
    <div class="not-verified-form">
      <div class="first-field-header">
        <div>Select Identification type:</div>
      </div>
      <form [formGroup]="shipmentTransactionForm" (submit)="submit($event)">
        <div class="identification-type">
          <div class="dropdown">
            <select
              class="select-identification"
              (change)="radioButton()"
              [(ngModel)]="identificationType"
              formControlName="identification"
            >
              <option value="">Select Identification type</option>
              <option *ngFor="let identification of identificationTypeData">
                {{ identification.Name }}
              </option>
            </select>
          </div>
        </div>
        <div class="identification-details" *ngIf="radioButtonSelected">
          <div class="identification-number">
            <label for="">Identification Number:</label>
            <input
              type="text"
              name="number"
              id=""
              [(ngModel)]="identificationNumber"
              formControlName="identification_number"
            />
          </div>
          <div class="identification-image">
            <label for="">Upload a scan of your international passport</label>
            <div class="uploaded-image">
              <input
                class="inputfile inputfile-4"
                type="file"
                id="file"
                name="image"
                formControlName="image"
                accept="image/png, image/jpeg"
                placeholder="upload image"
                #file
                (change)="loadFile($event)"
              />
              <!--(change)="loadFile($event)"-->
              <div class="user-image">
                <img id="output" width="200" [src]="imageUrl" />
              </div>

              <label for="file">
                <figure><i class="fas fa-cloud-upload-alt"></i></figure>
                <span>Upload the image of your international passport</span>
                <div class="upload-button-container">
                  <div class="image-button">Choose a file</div>
                </div>
              </label>
            </div>
          </div>
          <button
            [ngStyle]="{ 'background-color': imageUpload ? 'black' : 'grey' }"
            class="form-btn"
            type="submit"
            [disabled]="!shipmentTransactionForm.valid"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="oversea-shipping" *ngIf="overseaShipping">
    <div class="oversea-shipping-address-information">
      <div class="oversea-shipping-information">
        <div class="information-details">
          <div class="details">
            <div class="details-heading">
              Shipping your items from the USA to Nigeria is seamless with GIG
              Logistics.
            </div>
            <p> 
              Simply use details in the format below as your shipping address when shopping from any store ( physical or online). This
              will enable GIG Logistics receive shipments on your behalf for onward delivery to recipient.
            </p>
          </div>
          <div class="logo">
            <i class="fas marker fa-map-marker-alt"></i
            ><i class="fas fa-box-open"></i>
          </div>
        </div>
      </div>
      <div class="oversea-shipping-address-information">
        <span class="country-logo">
          <i class="fas marker fa-map-marker-alt"></i>
        </span>
        <span class="country-info">Shipping Address Format</span>
      </div>
      <div class="oversea-shipping-address">
       
      <div class="shipping-address-info">
        <label for="">Address</label>
        <div class="shipping-address-box">
            <div class="shipping-address-box-address">
              C/O GIG Logistics, 6872 Hwy 6 S STE B
            </div>
            <div class="shipping-address-box-copy address-copy" (click)="successfulCopy()" [cdkCopyToClipboard]="address">Copy</div>
        </div>
      </div>
      <div class="shipping-address-info">
        <label for="">City</label>
        <div class="shipping-address-box">
          <div class="shipping-address-box-address">
          Houston
          </div>
          <div class="shipping-address-box-copy" (click)="successfulCopy()" [cdkCopyToClipboard]="city">Copy</div>
        </div>
      </div>
      <div class="state-zipcode">
  <div class="shipping-address-info state-and-zip">
    <label for="">State</label>
    <div class="shipping-address-box">
        <div class="shipping-address-box-address">
          TX
        </div>
        <div class="shipping-address-box-copy copy-state-zip" (click)="successfulCopy()" [cdkCopyToClipboard]="state">Copy</div>
    </div>
  </div>
  <div class="shipping-address-info state-and-zip">
    <label for="">Zipcode</label>
    <div class="shipping-address-box">
        <div class="shipping-address-box-address">
          77083
        </div>
        <div class="shipping-address-box-copy copy-state-zip" (click)="successfulCopy()" [cdkCopyToClipboard]="zip">Copy</div>
    </div>
  </div>
      </div>
   
       
      
      </div>
      <div class="international-store-container">
        <div class="international-store-header">
          Buy and ship through GIGL from any of the stores below and <span class="store-list-link" (click)="viewMoreStores()">more</span> 
        </div>
        <div class="international-stores">
          <div class="store">
            <a href="https://www.bestbuy.com/" target="_blank">
              <div class="image">
                <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/image 3.png" alt="" />
              </div>
              <div class="text">Best Buy</div>
            </a>
          </div>
          <div class="store">
            <a href="https://www.amazon.com/" target="_blank">
              <div class="image">
                <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/image 4.png" alt="" />
              </div>
              <div class="text">Amazon</div>
            </a>
          </div>
          <div class="store">
            <a href="https://www.ebay.com/" target="_blank">
              <div class="image">
                <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/image 5.png" alt="" />
              </div>
              <div class="text">Ebay</div>
            </a>
          </div>
          <div class="store">
            <a href="https://www.asos.com/" target="_blank">
              <div class="image">
                <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/image 7.png" alt="" />
              </div>
              <div class="text">Asos</div>
            </a>
          </div>
          <div class="store">
            <a href="https://oldnavy.gap.com/" target="_blank">
              <div class="image new-image">
                <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/oldNavy.png" alt="" />
              </div>
              <div class="text">Old Navy</div>
            </a>
          </div>
          <div class="store">
            <a href="https://www.zara.com/us/" target="_blank">
              <div class="image new-image">
                <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/zara.png" alt="" />
              </div>
              <div class="text">Zara</div>
            </a>
          </div>
          <div class="store">
            <a href="https://www2.hm.com/en_us/index.html" target="_blank">
              <div class="image new-image">
                <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/images.png" alt="" />
              </div>
              <div class="text">H&M</div>
            </a>
          </div>
          <div class="store">
            <a href="https://www.walmart.com/" target="_blank">
              <div class="image new-image" >
                <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/wallmart.png" alt="" />
              </div>
              <div class="text">Walmart</div>
            </a>
          </div>
        </div>
      </div>
      <div class="proceed">
        <button (click)="overseasShipping()">Create Shipment</button>
      </div>
    </div>
  </div>
  <div class="return-button-container" *ngIf="internationalShipment">
    <i class="fas fa-arrow-left" (click)="returnBack()"></i>
    <span (click)="returnBack()">back</span>
  </div>
  <form
    action=""
    [formGroup]="createShipmentForm"
    (submit)="createShipment()"
    *ngIf="internationalShipment"
  >
    <div class="international-shipment-container">
      <div class="internation-shipment-header">Oversea Shipping</div>

      <!-- <div class="select-country-container">
          <label>Select Country</label>
          <select
            [(ngModel)]="selectedCountry"
            (change)="onchangeCountry($event)"
            class="select-country"
            formControlName="select_country"
            required
            [class.inValid]="
              createShipmentForm.get('select_country').invalid &&
              createShipmentForm.get('select_country').touched
            "
          >
            <option value="">Select a Country</option>
            <option
              *ngFor="let country of countryList"
              [ngValue]="country.CountryId"
            >
              {{ country.CountryName }}
            </option>
          </select>
        </div>
        <div class="select-country-container" *ngIf="selectedCountry != null">
          <label>Select Service Center</label>
          <select
            [(ngModel)]="selectedCenter"
            (change)="onchangeCenter($event)"
            class="select-country"
            formControlName="select_station"
            required
            [class.inValid]="
              createShipmentForm.get('select_station').invalid &&
              createShipmentForm.get('select_station').touched
            "
          >
            <option value="">Select a Station</option>
            <option
              *ngFor="let center of serviceCenterList"
              [ngValue]="center.ServiceCentreId"
            >
              {{ center.Address }}
            </option>
          </select>
        </div> -->

      <div class="shipment-list">
        <div class="header">Shipment Details</div>
        <div
          class="regular-shipment"
          *ngFor="let value of shipment; let i = index"
        >
          <div class="list-item">
            <input
              type="text"
              [(ngModel)]="value.TrackingId"
              placeholder="Enter Track Id"
              required
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="list-item">
            <input
              type="text"
              placeholder="Enter Item Name"
              [(ngModel)]="value.ItemName"
              required
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="list-item">
            <input
              class="quantity"
              type="number"
              [(ngModel)]="value.Quantity"
              placeholder="Quantity"
              [ngModelOptions]="{ standalone: true }"
              required
            />
          </div>
          <div class="list-item">
            <input
              type="text"
              [(ngModel)]="value.storeName"
              placeholder="Enter Store Name"
              [ngModelOptions]="{ standalone: true }"
              required
            />
          </div>
          <div class="list-item">
            <span class="close" (click)="removeItem(i)"
              ><i class="fas fa-trash"></i
            ></span>
          </div>
        </div>

        <div class="add-item-container">
          <span (click)="addShipment()">Add Item</span>
          <i class="fas fa-plus" (click)="addShipment()"></i>
        </div>
      </div>
      <div class="receiver-details" *ngIf="shipment.length > 0">
        <div class="header">Receiver's Details</div>
        <div class="receiver-details-container">
          <div class="receiver-info">
            <label for="">Receiver Name</label>
            <input
              type="text"
              name="receiver-name"
              id="receiver-name"
              placeholder="Receiver's Name"
              [(ngModel)]="receiverName"
              formControlName="receiver_name"
              required
              [class.inValid]="
                createShipmentForm.get('receiver_name').invalid &&
                createShipmentForm.get('receiver_name').touched
              "
            />
          </div>

          <div class="receiver-info">
            <label for="">Receiver Phone Number</label>
            <input
          
              type="number"
              name="phone-number"
              id="phone-number"
              placeholder="Receiver's Phone number"
              required
              [(ngModel)]="receiverNumber"
              formControlName="receiver_number"
              [class.inValid]="
                createShipmentForm.get('receiver_number').invalid &&
                createShipmentForm.get('receiver_number').touched
              "
            />
      
           
          </div>
          <div class="receiver-info">
            <label for="">Receiver Email</label>
            <input
              type="email"
              name="receiver-email"
              id="phone-number"
              placeholder="Receiver's email address"
              required
              [(ngModel)]="receiverEmail"
              formControlName="receiver_email"
              [class.inValid]="
                createShipmentForm.get('receiver_email').invalid &&
                createShipmentForm.get('receiver_email').touched
              "
            />
            <div class="invalid-email" *ngIf="primaryEmail.errors?.email">
              invalid Email.
            </div>
          </div>
        </div>
      </div>
      <div class="delivery-options" *ngIf="shipment.length > 0">
        <div class="location-header">Delivery Options</div>
        <div class="location">
          <!-- <div class="select-country-container">
            <label>Select Destination Country</label>
            <select
              [(ngModel)]="selectedCountry"
              (change)="onchangeCountry($event)"
              class="select-country"
              required
              [class.inValid]="
                createShipmentForm.get('select_destination_country').invalid &&
                createShipmentForm.get('select_destination_country').touched
              "
              formControlName="select_destination_country"
            >
              <option value="">Select Destination Country</option>
              <option>NIGERIA</option>
            </select>
          </div> -->

          <div class="select-country-container">
            <label>Delivery Option</label>
            <select
              [(ngModel)]="pickUpTypeOption"
              formControlName="delivery_option"
              (change)="pickup($event)"
              class="select-country"
              required
              [class.inValid]="
                createShipmentForm.get('delivery_option').invalid &&
                createShipmentForm.get('delivery_option').touched
              "
            >
              <option value="">Delivery Option</option>
              <option>Home Delivery</option>
              <option>GIGL pickup center</option>
            </select>
           
          
          
          </div>
          <div class="auto-select select-country-container" *ngIf="homeDelivery">
            <ng-select (change)="onchangeDestinationHub($event)" [(ngModel)]="destinationCenter" placeholder="Select a Terminal"
              formControlName="select_destination_center">
              <ng-option *ngFor="let serviceCenter of serviceCenterList" [value]="serviceCenter.ServiceCentreId">
                {{ serviceCenter.FormattedServiceCentreName }}</ng-option>
            </ng-select>
          </div>
            <div class="select-country-container receiver-address-container">
              <div class="receiver-statte-info" *ngIf="terminalOption">
                <label> Select State</label>
                <select formControlName="delivery_option_home" [(ngModel)]="serviceCenterStationName"
                  (change)="onChangeServiceCenterName($event)">
                  <option value="">Select a State</option>
                  <option [value]="station.StationId" *ngFor="let station of stationList">
                    {{ station.StationName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="select-country-container receiver-address-container">
              <div class="receiver-address-info" *ngIf="terminalOption">
                <label> Receiver Address</label>
                <input ngx-google-places-autocomplete (onAddressChange)="AddressChange($event)" type="text" name="receiver-address"
                  id="receiver-address" placeholder="Receiver's Address" required [(ngModel)]="receiverAddress"
                  [ngModelOptions]="{ standalone: true }" />
              </div>
            </div>
        </div>
          <div class="push-left">
            <span *ngIf="pickUpTypeOption == 'Home Delivery'">Please note that home delivery request attracts additional charges</span>
          </div>
      </div>

      <div class="register-shipment-button">
        <button
          class="add-btn button-submit register-shipment"
          *ngIf="shipment.length > 0"
          [disabled]="!createShipmentForm.valid"
        >
          Proceed
        </button>
      </div>
    </div>
  </form>
</div>
