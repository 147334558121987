<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="container-class codremmitance">
  <div class="container-content coderemmitance-content">
    <div class="page-text">
      <h2>COD Remittance History</h2>
    </div>
    <div class="coderemmitance-table display-tbl table-content">
      <div class="table-filter">
        <mat-form-field>
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" />
        </mat-form-field>
      </div>
      <div class="table-buttons">
        <span
          ><button class="table-button">
            <i class="far fa-copy"></i>copy
          </button></span
        >
        <span
          ><button class="table-button">
            <i class="fas fa-print"></i>print
          </button></span
        >
        <span
          ><button class="table-button">
            <i class="far fa-file-excel"></i>excel
          </button></span
        >
        <span
          ><button class="table-button">
            <i class="far fa-file-pdf"></i>pdf
          </button></span
        >
      </div>
      <div class="table">
        <table mat-table [dataSource]="dataSource">
          <!-- Position Column -->
          <ng-container matColumnDef="position">
            <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </mat-cell>
          </ng-container>
          <!-- items column -->
          <ng-container matColumnDef="create">
            <mat-header-cell *matHeaderCellDef>CREATE DATE</mat-header-cell>
            <mat-cell *matCellDef="let element"> </mat-cell>
          </ng-container>

          <!-- Departure Column -->
          <ng-container matColumnDef="center">
            <mat-header-cell *matHeaderCellDef>SERVICE CENTER</mat-header-cell>
            <mat-cell *matCellDef="let element"> </mat-cell>
          </ng-container>

          <!-- Destination Column -->
          <ng-container matColumnDef="waybill">
            <mat-header-cell *matHeaderCellDef>WAYBILL</mat-header-cell>
            <mat-cell *matCellDef="let element"> </mat-cell>
          </ng-container>
          <!-- Amount column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>CUSTOMER'S NAME</mat-header-cell>
            <mat-cell *matCellDef="let element"> </mat-cell>
          </ng-container>
          <!-- partner column -->
          <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef>AMOUNT</mat-header-cell>
            <mat-cell *matCellDef="let element"> </mat-cell>
          </ng-container>
          <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef>USER</mat-header-cell>
            <mat-cell *matCellDef="let element"> </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>
      </div>
    </div>
  </div>
</div>
