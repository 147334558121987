import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MerchantService } from '../services/merchant.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {DataAccess} from '../helpers/dataAccess';
import { LocationStrategy } from '@angular/common';
import { AuthHelpers } from '../helpers/auth';
import { PaymentService } from '../services/payment.service';
import { Flutterwave, InlinePaymentOptions, PaymentSuccessResponse } from "flutterwave-angular-v3";
declare var PaystackPop: any;
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  public dataAccess = new DataAccess();
  isLoading: boolean = false;
  ref1:any;
  plan:any;
  isSubscription: string = '' ;
  countryList: Array<object> = [];
  public authHelpers = new AuthHelpers();
  subDetail:any;
  userEmail:string;
  showInsufficentError:boolean = false;
  apiError:boolean = false;
  errorMessage:string = '';
  isBVN: boolean = false;
  planData:any;
  showError:boolean = false;
  NewUser:boolean = false;
  showBVN:boolean = false;
  cardDetail:any;
  isCancelledUser:boolean;
  payment: boolean = false;
  customerFullName = this.dataAccess.getLocalStorageValue('CustomerFullName');
  phoneNumber = this.authHelpers.getAccessToken('phoneNumber');

  dropdownSettings:IDropdownSettings = {
    singleSelection: true,
    idField: 'name',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: false,
    itemsShowLimit: 1,
    enableCheckAll:false,
    closeDropDownOnSelection:true
  };
  isVerified:boolean ;
  CurrencyCode = localStorage.getItem('currencyCode');
  flutterWaveAmount: any;
  constructor( private router: Router,
    private MerchantService:MerchantService,
    private location: LocationStrategy,
    private flutterWave: Flutterwave,
     private paymentKey: PaymentService
    ) { }

  ngOnInit(): void {

    this.userEmail  = this.dataAccess.getLocalStorageValue('email');
    // this.getSubscription(this.userEmail);
    let request =  JSON.parse(localStorage.getItem('m_d5')) ;
    console.log('request===>',request);
    this.preventBackButton();
    if(request == false) {
      this.closePayment() ;
      return
    } else if (request == true) {
      this.successPayment();
      return
    } else {
      this.getSubscription(this.userEmail);
    }
   
    console.log('subscription==>',this.isSubscription);

  }
  preventBackButton() {
    history.pushState(null, null, location.href);
    this.location.onPopState(() => {

      history.pushState(null, null, location.href);
    })
  }
  closePaymentOptions = () => {
    this.payment = false;
  }
  activatePaymentOptions() {
  
    this.payment = true;

  }
  walletBalance:any;
  loadWallet() {
    this.MerchantService.getWallet().subscribe((data) => {
      if (data.Code == 200) {
        this.walletBalance = data.Object.WalletBalance;
        this.planDetail();
      } else {
        
      }
    });
  }
  planDetail() {
    this.MerchantService.getSubAmount().subscribe((res) => {

      if (res['message'] == 'Success') {
       if (this.walletBalance == 0) {
         this.showInsufficentError = true;
       }
       else {
         if(this.walletBalance < res.data.paystackData.planAmount) {
           this.showInsufficentError = true;
         }
         else {
          this.showInsufficentError = false;
         }
         console.log('showInufuncc',this.showInsufficentError);
       }
      }
    }, err => {
      this.isLoading= false;
    });
  }
  closePayment() {
    localStorage.removeItem('m_d5');
    alert("Payment cancelled");
    this.getSubscription(this.userEmail)
  }
  successPayment() {
    let  ref= localStorage.getItem("ref");
    this.verifyPay(ref);
  }
  verifyPay(req) {
    this.isLoading = true;
    this.MerchantService.verifyPayment(req).subscribe((res) => {
      this.isLoading = false;
      console.log(res);
      
      if (res['message'] == 'Success') {
        localStorage.removeItem('m_d5');
        localStorage.removeItem('ref');
        
        this.dataAccess.setLocalStorageValue('rank', 'Class');
        this.dataAccess.setLocalStorageValue('Selection',0);
        this.router.navigate(['/mainpage/']);

      }
    }, err => {
      this.isLoading = false;
      localStorage.removeItem('m_d5');
        localStorage.removeItem('ref');
        this.dataAccess.setLocalStorageValue('rank', 'Basic');
        this.dataAccess.setLocalStorageValue('Selection',0);
        this.router.navigate(['/mainpage/']);
    });

  }
  getSubscription(email:any) {
    this.isLoading = true;
    let query="email="+email+"&countryCode="+ localStorage.getItem("Country");
    this.MerchantService.getSubscriptionDetail(query).subscribe((res) => {
     
      if (res['message'] == 'Success') {
       // this.loadWallet();
       
        this.isLoading = false
        this.isSubscription = res.data.status;
        this.isBVN = res.data.isBvnAdded;
        this.subDetail = res.data;
        this.countryList =res.data.countryList;
        this.cardDetail =res.data.cardDetails;
        this.isCancelledUser = res.data.isCanceledByUser;
        console.log(res.data);
        console.log('cancelled user',this.isCancelledUser);
        
        
        // console.log(res.data);
        // console.log(res);
        console.log('card Detail',this.cardDetail);
    
        if(this.isBVN == true){ 
          this.isVerified = true;
        }
        this.planData =res.data.country;

        console.log('isVerfied',this.isVerified)
      }
    }, err => {
     // this.loadWallet();
      this.isSubscription = 'No';
      console.log('isSubscription',this.isSubscription);
        console.log(err);
      this.isBVN = err.error.data.isBvnAdded;
      if(this.isBVN == true){ 
        this.isVerified = true;
      }
      this.isLoading= false;
     ;
      this.planData =err.error.data.country;
      if(err.error.data.errorCode == 'MERCHANT_NOT_FOUND') {
        this.NewUser = true;
        this.isBVN = false;
        this.planData =err.error.data.country;
        this.isSubscription = "No";

      }
     // this.validateCountry();
      console.log('isBVN',this.isBVN);
    console.log('planData',this.planData);
    console.log('this.is')
      
    });
    
  }
  BVN:any;
  validateBVN() {
    
    if(this.BVN.length <= 10) {
      this.isVerified = false;
      this.showError = true;
      return
    }
    this.showError = false;
    this.MerchantService.verifyBVN(this.BVN).subscribe((res) => {
       if(res['message'] =='Success') {
       this.isVerified = true;
       } 
     }, err => {
       this.isVerified = false;
     });
   
  
  
  }
 
  onlyNumberKey(event) {
    return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57;
  }
  CancelSub() {
    this.isLoading = true;
    let request  =  {
      email: this.userEmail,
      subscriptionId:this.subDetail['subscriptionId']
    }
    this.MerchantService.cancelledSubscription(request).subscribe((res) => {
      if (res['message'] == 'Success') {
        this.isLoading = false;
        this.subDetail = res.data;
        this.dataAccess.setLocalStorageValue('rank', 'Basic');
        this.getSubscription(this.userEmail);
      }
    }, err => {
      this.isLoading= false;
      this.apiError = true;
      this.errorMessage = err.error.data.message;
    });
  }
  RenewSub(){
     this.isLoading=true;
   let payload={
     subscriptionId: this.subDetail['subscriptionId']
   }
   this.MerchantService.renewSubscription(payload).subscribe(res=>{
     console.log(res);
         if(res.statusCode==200){
        this.isLoading=false;
       this.dataAccess.setLocalStorageValue('rank', 'Class')
        this.getSubscription(this.userEmail);
        location.reload();
      

       }
     
   },err=>{
       this.isLoading = false;
       this.apiError = true;
       this.errorMessage = err.error.data.message;
   })
  
    
  }
  amount:any;
  upgradePlan() {
    let request = { 
      email:this.userEmail,
      userId:this.dataAccess.getLocalStorageValue('userId'),
      countryCode:localStorage.getItem("Country"),
    }
    if (this.isBVN == false) {
      request['BVN'] = this.BVN;
    }
    if (this.NewUser == true) {
      request['isNew'] = true;
      request["firstName"]=this.dataAccess.getLocalStorageValue('firstName') 
      request["lastName"]=this.dataAccess.getLocalStorageValue('lastName') ;
      request["phoneNumber"]=this.dataAccess.getLocalStorageValue('phoneNumber') ;
      request["userName"]=this.dataAccess.getLocalStorageValue('userCode') ;
      request["userChannelCode"]=this.dataAccess.getLocalStorageValue('userChannelCode') ;
    }
    this.isLoading = true;
    this.MerchantService.upgradeMerchant(request).subscribe((res) => {
      if (res['message'] == 'Success') {
        console.log(res.data);
        
        this.ref1 = res.data.paystackData.reference;
        this.amount = res.data.paystackData.planAmount * 100;
        this.flutterWaveAmount = res.data.paystackData.planAmount;
        this.BVN = "";
        localStorage.setItem("ref",this.ref1);
        //this.payment=true;
       this.payWithPaystack();
      }
    }, err => {
      this.isLoading= false;
      this.apiError = true;
      this.errorMessage = err.error.data.message;
    });
  }
  closePopUp() {
    this.apiError = false;
    this.errorMessage = null;
  }
  EditCardDetail(value) {
    console.log('final',value)
    this.isLoading = true;
    let request = { 
      email:this.userEmail 
    }
    this.MerchantService.editCard(request).subscribe((res) => {
      if (res['message'] == 'Success') {
        this.ref1 = res.data.paystackData.reference;
        this.amount = res.data.paystackData.planAmount * 100;
        this.flutterWaveAmount = res.data.paystackData.planAmount;
        localStorage.setItem("ref",this.ref1);
        //this.payment=true;
        this.payWithPaystack()
      }
    }, err => {
      this.isLoading= false;
      this.apiError = true;
      this.errorMessage = err.error.data.message;
    });
  }
  
  onItemSelectProduct(item: any) {
  }
  onSelectAllProduct(items: any) {
  }
  // testPayStackKey ='pk_test_7140f98d7c52c6e559d00182fce18ec6f98443ae'
  // livePayStack ='pk_live_5f2f6f3b116c4f0a1752ee44176c8b8bdc3c085b'
  public payWithPaystack() {
    //console.log(this.email,this.phoneNumber);
    this.payment=false;
    var payStackKey = this.paymentKey.getPaystackKey()
    var handler = PaystackPop.setup({
      key: payStackKey,
      email: this.userEmail,
      ref: this.ref1,
      amount: this.amount,
      currency: this.CurrencyCode,
     callback: function (response) {
      location.reload()
      },
      onClose: function (e) {
        localStorage.setItem('m_d5','false');
        location.reload();
      },
       onSuccess: function (response) {
        localStorage.setItem('m_d5','true');
        location.reload();
      },
    });
    handler.openIframe();
  }
  flutterWave_test = 'FLWPUBK_TEST-0797c1579353fe90e2a60da4c7586fff-X';

  payWithFlutterWave = () => {
    this.payment = false;
    // console.log(this.refCode);
    // this.secondFlutterAmount = Math.round(this.amount);
    var paymentData: InlinePaymentOptions = {
      public_key: this.flutterWave_test,
      tx_ref: this.ref1,
      amount: this.flutterWaveAmount,
      currency: this.CurrencyCode,
      payment_options: 'card,ussd',
      redirect_url: '',
      // meta: this.meta,
      customer: {
        email: this.userEmail,
        phone_number: this.phoneNumber,
        name: this.customerFullName,
      },
      customizations: {
        title: "GIG Logistics",
        description: "Wallet Funding",
        logo: "https://res.cloudinary.com/gig-logistics/image/upload/v1611738527/Customer%20Portal/GIGLogin_u3bt1z.png",
      },
      callback: this.makePaymentCallback,
      onclose: this.closedPaymentModal,
      callbackContext: this
    }
    this.flutterWave.inlinePay(paymentData)

  }
  // verifyReload: boolean = false;
  makePaymentCallback(response: PaymentSuccessResponse): void {
    localStorage.setItem('m_d5', 'true');
    location.reload();
   
    // console.log("Payment callback", response);
  }

  closedPaymentModal(): void {
    localStorage.setItem('m_d5', 'false');
    location.reload();
    // if (this.verifyReload) {
     
    // }
    // else {
    //   location.reload();
    // }

  }
  

}
