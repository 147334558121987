import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ChangePassword } from '../models/changePassword';
import { ApiCall } from '../services/api-call';
import { UploadService } from '../services/uploadimage';
import { DataAccess } from '../helpers/dataAccess';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ShipmentTransactionApi } from './shipment-transaction-api';


@Component({
  selector: 'app-shipment-transaction',
  templateUrl: './shipment-transaction.component.html',
  styleUrls: ['./shipment-transaction.component.scss'],
})
export class ShipmentTransactionComponent implements OnInit {
  Name: any;

  overseaShipping: boolean = true;
  destinationServiceCenterList: any;
  pickUpTypeOption: string;
  Registration = false;
  stationId: any;
  // public exampleData: Array<Select2OptionData>;
  // public options;

  constructor(
    private upload: UploadService,
    private fb: FormBuilder,
    private apiCall: ApiCall,
    private httpClient: HttpClient,
    private router: Router,
    private shipmentTransactionApi:ShipmentTransactionApi
  ) {}
  dataAccess = new DataAccess();
  address = 'C/O GIG Logistics, 6872 Hwy 6 S STE B';
  city=' Houston';
  state='TX';
  zip='77083';
  userId = this.dataAccess.getLocalStorageValue('userId');
  access = this.dataAccess.getLocalStorageValue('isInternational');
  customerType = this.dataAccess.getLocalStorageValue('CustomerType');
  shipmentTransactionForm: FormGroup;
  serviceCenterForm: FormGroup;
  createShipmentForm: FormGroup;
  shipmentSubmit: boolean = false;
  //SERVER_URL = 'https://file.io/';
  SERVER_URL = 'https://giglgodev.herokuapp.com/api/s3upload/image-upload';
  radioButtonSelected: boolean = false;
  selectedCountry: boolean;
  selectedCountryName: string;
  succesfulUpload;
  countryList: any;
  stationList: any;
  countryName: any;
  submitShipment: any;
  serviceCenterList: any;
  spinning: boolean = false;
  selectedStation: any;
  selectedCenter: any;
  selectedCenterName: any;
  notVerified: boolean = true;
  nameAddressDisplay: boolean = false;
  deleteItem:boolean=false;
  imageUrl: string | ArrayBuffer = '';
  identificationNumber;
  receiverAddress;
  receiverName;
  receiverEmail;
  StationName;
  receiverNumber;
  senderAddress;
  itemId = 1;
  internationalShipment: boolean = false;
  identificationType: String = '';
  identificationTypeData;
  internationalStores;
  countrySelected: boolean = false;
  public file;
  public imageUpload;
  logisticsHub;
  destinationCenter;
  destinationCountry;
  destinationCountryName;
  destinationCenterId=4;
  receiverCity;
  pickUpType;
  pickUpTypeValue;
  unsuccesful: boolean = false;
  succesful: boolean = false;
  validation: boolean = false;
  validationMessage: string;
  terminalOption: boolean = false;
  homeDelivery: boolean = false;
  serviceCenterStationName;
  successfulInternationalShipment=false;
  x = 'True';
  myControl = new FormControl();
  viewStores:boolean=false;
  internationalShipmentTermsandConditions:boolean=true;
  termsandCondition;
  displayTermsMessage=false;
  requestNumber;
  indent;
  // filteredOptions: Observable<string[]>;
  // options: string[] = [];
  // activates after radio button has been selected
  radioButton() {
    this.radioButtonSelected = true;
  }
  overseasShipping() {
    this.overseaShipping = false;
    this.internationalShipment = true;
  }
  // responsible for google address suggestion
  public AddressChange(address: any) {
    //setting address from API to local variable
    this.receiverAddress = address.formatted_address;
  }
  // gets item id
  getItemId() {
    let newId;
    for (let i = 1; i <= this.shipment.length; i++) {
      newId = i + 1;
    }
    return newId;
  }
  successfulCopy() {
    alert('Copied Successfully');
  }
  // gets identification type
  getIdentificationType() {
    this.shipmentTransactionApi.getIdentificationTypes().subscribe((data) => {
      this.identificationTypeData = data;
    });
  }
  // registers user for international shipment
  submit(e: any) {
    this.spinning = true;

    const payload = {
      IdentificationNumber: this.identificationNumber,
      IdentificationImage: this.imageUpload.url,
      IdentificationType: this.identificationType,
    };

    this.shipmentTransactionApi.updateInternationalProfile(payload).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.succesfulUpload = true;
      }
    });
  }
  // back button
  returnBack() {
    this.internationalShipment = false;
    this.overseaShipping = true;
  }
  // responsible for internal shipment after user is verified
  closePopUpInternational() {
    this.succesfulUpload = false;
    this.Registration = false;
    this.internationalShipment = true;

    this.dataAccess.setLocalStorageValue('isInternational', this.x);
    this.access = this.x = this.dataAccess.getLocalStorageValue(
      'isInternational'
    );
    this.createShipment();
  }
  // redirects to login page
  toLogin() {
    localStorage.clear();
    this.router.navigate(['login']);
  }
  // closes popup
  closePopUp() {
    this.submitShipment = false;
    this.viewStores=false;
    this.internationalShipmentTermsandConditions=false;
    this.successfulInternationalShipment=false;
    if (this.validation) this.validation = false;
  }

  pickup(event) {
    if (this.pickUpTypeOption == 'Home Delivery') {
      this.pickUpTypeValue = 'HOMEDELIVERY';
      this.terminalOption = true;
      this.homeDelivery = false;
      this.destinationCenter = null;
      this.serviceCenterStationName = null;
    } else {
      this.receiverAddress = null;
      this.pickUpTypeValue = 'SERVICECENTER';
      this.terminalOption = false;
      this.homeDelivery = true;
    }
  }
  // updateProfile(payload) {}
  // calls api for image
  loadFile(e) {
    this.spinning = true;
    if (e) {
      this.file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(this.file);

      reader.onload = (event) => {
        this.imageUrl = reader.result;
      };
    }

    // responsible for getting url for image
    const formData = new FormData();
    formData.append('image', this.file);
    this.upload.upload(formData).subscribe((res) => {
      this.imageUpload = res.data;

      this.spinning = false;
    });
  }

  public shipment = [
    {
      Description: '',
      ItemName: null,
      TrackingId: null,
      storeName: null,
      ShipmentType: 'Regular',
      Weight: 0.0,
      Nature: 'Fragile',
      
      Quantity: null,
      
    },
  ];
  // deletes shipment
  removeItem(index: number) {
    this.deleteItem=true;
    this.indent=index;
  }
  yesDeleteItem(){
this.shipment.splice(this.indent, 1);
this.deleteItem=false;


}
noDeleteItem(){
 this.deleteItem=false;
 
 
}

  getactivecountries() {
    this.shipmentTransactionApi.getActiveCountries().subscribe((data) => {
      if (data.Code == 200) this.spinning = false;
      this.countryList = data.Object;
    });
  }

  getStationByCountry(id) {
    this.spinning = true;
    this.shipmentTransactionApi.getStationByCountry(id).subscribe((data) => {
      if (data.Code == 200) this.spinning = false;
      this.stationList = data.Object;
     
      
    });
  }

  getDestinationServiceCenter(id) {
    this.spinning = true;
    this.shipmentTransactionApi.getServiceCenterByCountry(id).subscribe((data) => {
      if (data.Code == 200) this.spinning = false;
      this.destinationServiceCenterList = data.Object;
    });
  }
  indicator = {
    ItemName: 'Item Name',
    Quantity: 'Quantity',
    TrackingId: 'Tracking Id',
    storeName: 'Store Name',
  };
  addShipment = () => {
    //this.getItemId();

    // let internationalShipment = {
    //   Description: '',
    //   ItemName: null,
    //   Nature: 'NORMAL',
    //   Quantity: null,
    //   ShipmentType: 'Regular',
    //   StoreName: null,
    //   TrackingID: null,
    //   Weight: 0.0,
    //   Price: '',
    //   SerialNumber: '',
    //   IsVolumetric: true,
    //   Length: '',
    //   Width: '',
    //   Height: '',
    // };
    let internationalShipment = {
      Description: '',
      ItemName: null,
      TrackingId: null,
      storeName: null,
      ShipmentType: 'Regular',
      Weight: 0.0,
      Nature: 'Fragile',
      Quantity: null,
    };
    this.shipment.push(internationalShipment);
    // this.getItemId();

    this.shipmentSubmit = true;
  };

  createShipment() {
    for (let i = 0; i < this.shipment.length; i++) {
      for (let itemObj in this.shipment[i]) {
        if (this.shipment[i][itemObj] === null) {
          this.validation = true;
          this.validationMessage = `${this.indicator[itemObj]} cannot be empty, Please complete all your forms`;
          return;
        }
      }
    }
    if (this.pickUpTypeValue == null) {
      this.validation = true;
      this.validationMessage = `You have to select either home delivery or pickup terminal`;
      return;
    }
    if (this.terminalOption == true && this.receiverAddress == null) {
      this.validation = true;
      this.validationMessage = 'Please enter receiver address';

      return;
    }
    if (this.terminalOption == true && this.receiverAddress == '') {
      this.validation = true;
      this.validationMessage = 'Please enter receiver address';

      return;
    }
    if (this.terminalOption == true && this.serviceCenterStationName == '') {
      this.validation = true;
      this.validationMessage = 'Please select a station';

      return;
    }
    if (this.terminalOption == true && this.serviceCenterStationName == null) {
      this.validation = true;
      this.validationMessage = 'Please select a station';

      return;
    }
    if (this.homeDelivery == true && this.destinationCenter == null) {
      this.validation = true;
      this.validationMessage = 'Please Select a Terminal';
      return;
    }
    if (this.homeDelivery == true && this.destinationCenter == '') {
      this.validation = true;
      this.validationMessage = 'Please Select a Terminal';
      return;
    }
    if (this.access != 'True') {
      this.Registration = true;
      this.internationalShipment = false;
      return;
    }
    this.spinning = true;
    const payload2 = {
      UserId: this.userId,
      ShipmentRequestItems: this.shipment,
      Value: 0,
      DestinationServiceCentreId: this.destinationCenterId,
      DestinationServiceCentre: null,
      DestinationCountryId: 1,
      ReceiverName: this.receiverName,
      ReceiverPhoneNumber: this.receiverNumber,
      ReceiverEmail: this.receiverEmail,
      ReceiverAddress: this.receiverAddress,
      ReceiverCity: '',
      ReceiverState: '',
      ReceiverCountry: 'Nigeria',
      PickupOptions: this.pickUpTypeValue,
      GrandTotal: 0,
      Total: 0,
      PaymentMethod: '',
      SenderAddress: '',
      SenderState: '',
      StationId: this.stationId,
      ApproximateItemsWeight: 0,
    };
   
   

    return this.shipmentTransactionApi.getInternationalShipment(payload2).subscribe((data) => {
      this.spinning = true;
      if (data.Code == 200) {
        this.spinning = false;
        this.successfulInternationalShipment=true;
        this.requestNumber=data.Object.RequestNumber;
      } else {
        this.spinning = false;

        this.submitShipment = data;
      }
    });
  }

  onchangeCenter(e) {
    this.countrySelected = true;

    let serviceCenterFilter = this.serviceCenterList.filter(
      (x) => x.ServiceCentreId == this.selectedCenter
    );

    this.StationName = serviceCenterFilter[0].StationName;
    this.selectedStation = serviceCenterFilter[0].StationId;

    this.selectedCenterName = serviceCenterFilter[0].Address;
  }
  // responsible for getting  service center and serviceCenter id
  onchangeDestinationHub(e) {
    this.countrySelected = true;
    let serviceCenterFilter = this.serviceCenterList.filter(
      (x) => x.ServiceCentreId == this.destinationCenter
    );

    this.destinationCenterId = serviceCenterFilter[0].ServiceCentreId;
    this.stationId = serviceCenterFilter[0].StationId;
    this.StationName = serviceCenterFilter[0].StationName;
  }
  // responsible for getting station name and station Id
  onChangeServiceCenterName(e) {
    let servicecenterNameFilter = this.stationList.filter(
      (x) => x.StationId == this.serviceCenterStationName
    );
  
    
    this.stationId = servicecenterNameFilter[0].StationId;
  
    
    this.StationName = servicecenterNameFilter[0].StationName;
  }
  reload() {
    location.reload();
  }

 

  getServiceCenter(id) {
    this.spinning = true;
    this.shipmentTransactionApi.getServiceCenterByCountry(id).subscribe((data) => {
      if (data.Code == 200) {
        this.spinning = false;
        this.serviceCenterList = data.Object;
        
        
      }
    });
  }
  linkArray=[];
  newStoresLink;
  internationalStoreName;
 getInternationalStores=()=>{
   this.shipmentTransactionApi.getInternationalStores(207).subscribe(
     data=>{
       this.internationalStores=data.Object;
       for(let i=0;i<this.internationalStores.length;i++){
         this.internationalStoreName=this.internationalStores[i].StoreName;
         if(this.internationalStores[i].URL.substring(0,3)==='www'){
           let otherurl='https://'
           let lastUrl='/'
          this.newStoresLink = otherurl + this.internationalStores[i].URL + lastUrl
         }
         else{
           this.newStoresLink=this.internationalStores[i].URL
         }
         let objectArray={
           stores:this.internationalStoreName,
           links:this.newStoresLink
         }

        this.linkArray.push(objectArray)
        
       }
       
       
     }
   )
 }
 viewMoreStores=()=>{
   this.viewStores=true;
 }
 getTermsandConditions=()=>{
   this.spinning=true;
   this.shipmentTransactionApi.getInternationalShipmentTerms().subscribe(
     data=>{
     
       if(data.Code==200){
         this.spinning=false;
         this.termsandCondition=data.Object.Body;
    
         
         document.querySelector('.termsMessage').innerHTML=data.Object.Body;
         this.displayTermsMessage=true;
        
         
       }
       
     }
   )
 }
  onchangeDestinationCountry(e) {
  
    let countryfilter = this.countryList.filter(
      (x) => x.CountryId == this.destinationCountry
    );
    this.destinationCountryName = countryfilter[0].CountryName;

    this.getDestinationServiceCenter(this.destinationCountry);
  }

  onchangeStation(e) {
    let stationFilter = this.stationList.filter(
      (x) => x.StationId == this.selectedStation
    );
    this.StationName = stationFilter[0].StationName;

    this.getServiceCenter(this.selectedStation);
  }
  get primaryEmail() {
    return this.createShipmentForm.get('receiver_email');
} 
  get primaryNumber() {
    return this.createShipmentForm.get('receiver_number');
} 

  ngOnInit(): void {
    this.spinning = true;

    this.getactivecountries();
    this.getIdentificationType();
    this.getServiceCenter(1);
    this.getStationByCountry(1);
    this.getInternationalStores();
    this.getTermsandConditions();
    // form builder
    
    this.shipmentTransactionForm = this.fb.group({
      identification_number: ['', [Validators.required]],
      image: ['', [Validators.required]],
      identification: ['', [Validators.required]],
    });
    this.serviceCenterForm = this.fb.group({
      station_select: [''],
      service_center: [''],
      station: [''],
    });

    this.createShipmentForm = this.fb.group({
      tracking_id: [''],
      item_name: [''],
      quantity: [''],
      store_name: [''],
      select_destination_center: [''],
      delivery_option_home: [''],
      select_station: [''],
      receiver_name: ['', [Validators.required]],
      delivery_option: ['', [Validators.required]],
      receiver_address: [''],
      receiver_number: ['', [Validators.required]],
      receiver_email: ['', [Validators.email]],
    });
  }
}
