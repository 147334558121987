import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCall } from '../services/api-call';
import { MatPaginator } from '@angular/material/paginator';
import { DataAccess } from '../helpers/dataAccess';
import { AuthHelpers } from '../helpers/auth';
import { Router } from '@angular/router';
import { TrackingApi } from './tracking-api';
@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
})

export class TrackingComponent implements OnInit {
  apiError: boolean = false;
  errorMessage;
  trackingData;
  tracking: boolean = false;
  dataAccess = new DataAccess();
  spinning: boolean = false;
  dataSource: MatTableDataSource<any>;
  waybill;
  waybillwidth = this.dataAccess.getLocalStorageValue('waybillNumber') || null;
  displayWaybill;
  destinationAddress;
  departureAddress;
  // responsible for table column
  displayedColumns: string[] = [
    'position',
    'waybill',
    'date',
    'time',
    'location',
    'status',
    
  ];
  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  waybillNumberInvoice: any;
  constructor(private apiCall: ApiCall, private router: Router,private trackingApi:TrackingApi) {}
  closePopUp() {
    this.apiError = false;
  }
  trackShipment() {
    this.spinning = true;
    this.trackingApi.getShipmentTracking(this.waybill).subscribe((data) => {
      this.spinning = true;
      if ((data.Code == 200)) {
        this.spinning = false;
        this.tracking = true;
    
        this.trackingData = data.Object.MobileShipmentTrackings;
        this.displayWaybill=data.Object.MobileShipmentTrackings[0].Waybill;
         this.destinationAddress=data.Object.Destination;
          this.departureAddress=data.Object.Origin;
     
        this.waybill.substring(0,3)==='AWR' ?  this.departureAddress='Houston, USA': null
       
        
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.trackingData);
    });
  }

  trackShipmentWith(waybill) {
    this.spinning = true;
    this.trackingApi.getShipmentTracking(waybill).subscribe((data) => {
      this.spinning = true;
      if ((data.Code == 200)) {
        this.spinning = false;
        this.tracking = true;
        this.trackingData = data.Object.MobileShipmentTrackings;
        this.displayWaybill=data.Object.MobileShipmentTrackings[0].Waybill;
        this.destinationAddress=data.Object.Destination;
        this.departureAddress=data.Object.Origin;
         waybill.substring(0,3)==='AWR' ?  this.departureAddress='Houston, USA': null
        
        
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.trackingData);
    });
  }
  // stores waybill number and navigates to print invoice page
  getWaybill(invoiceId) {
    this.waybillNumberInvoice = this.trackingData[invoiceId].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      'waybillNumberInvoice',
      this.waybillNumberInvoice
    );
    this.router.navigate(['/mainpage/printInvoice']);
  }
  ngOnInit(): void {
    if (this.waybillwidth !== null) {
      this.trackShipmentWith(this.waybillwidth);
    }
  }
}
