import { Injectable } from '@angular/core';
import { AuthHelpers } from '../helpers/auth';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Dashboard } from '../models/dashboard';
import { TransactionHistory } from '../models/transactionhistory';
import { ChangePassword } from '../models/changePassword';
import { ForgotPassword } from '../models/forgotPassword';
import { QuickQuotes } from '../models/quickquote';

@Injectable({
  providedIn: 'root',
})
export class ApiCall {
  public authHelpers = new AuthHelpers();
  public API2 = 'https://agilitysystemapidevm.azurewebsites.net/api';
  public API1 = 'https://mobile.gigl-go.com/api';
  SERVER_URL: string =
    'https://giglgodev.herokuapp.com/api/s3upload/image-upload';
  token = this.authHelpers.getAccessToken('accessToken');
  tokenFlutter ='FLWPUBK_TEST-0797c1579353fe90e2a60da4c7586fff-X';

  constructor(private httpClient: HttpClient) {}

  authHeader() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
 
    headers = headers.append('Authorization', `Bearer ${this.token}`);
    return { headers };
  }

  //   calls the api for dashboard
  getDashboard(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/dashboard`,
      this.authHeader()
    );
  }

 
  getTransaction(dashboard: Dashboard): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/portal/transaction`,
      dashboard,
      this.authHeader()
    );
  }

  getWaybillDetails(id): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/bywaybill/${id}`,
      this.authHeader()
    );
  }
 getInternationalStores(id):Observable<any>{
   return this.httpClient.get(`${this.API1}/portal/storesbycountry/${id}`,this.authHeader());
 }
 getInternationalShipmentTerms():Observable<any>{
   return this.httpClient.get(`${this.API1}/portal/intlshipmentsmessage`,this.authHeader());
 }
  updateInternationalProfile(value): Observable<any> {
    return this.httpClient.put(
      `${this.API1}/portal/profileinternationaluser`,
      value,
      this.authHeader()
    );
  }

  payForShipment(waybill: number): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/paymentforshipment/${waybill}`,
      this.authHeader()
    );
  }

  getActiveCountries(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/getactivecountries`,
      this.authHeader()
    );
  }

  getStationByCountry(id): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/getstationsbycountry/${id}`,
      this.authHeader()
    );
  }

  getServiceCenterByStation(id): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/servicecentresbystation/${id}`,
      this.authHeader()
    );
  }

  getServiceCenterByCountry(id): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/servicecentresbycountry/${id}`,
      this.authHeader()
    );
  }
  getTransactionHistory2(transaction: TransactionHistory): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/portal/transaction`,
      transaction,
      this.authHeader()
    );
  }
  getTransactionHistory(transaction: TransactionHistory): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/portal/getwallettransactionandpreshipmenthistory`,
      transaction,
      this.authHeader()
    );
  }
  getForgotPassword(forgotPassword: ForgotPassword): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/portal/forgotpassword`,
      forgotPassword,
      this.authHeader()
    );
  }
  getQuickQuotes(quickquote): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/portal/getPrice`,
      quickquote,
      this.authHeader()
    );
  }

  identificationTypes(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/identificationtypes`,
      this.authHeader()
    );
  }

  getInternationalShipment(payload): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/portal/AddIntlShipmentTransactions`,
      payload,
      this.authHeader()
    );
  }
  getIdentificationTypes(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/identificationtypes`,
      this.authHeader()
    );
  }
  getChangePassword(changePassword: ChangePassword): Observable<any> {
    return this.httpClient.put(
      `${this.API1}/portal/changepassword/${changePassword.userid}/${changePassword.currentPassword}/${changePassword.newPassword}`,
      changePassword,
      this.authHeader()
    );
  }

  getPaymentLog(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/walletpaymentlog`,
      this.authHeader()
    );
  }

  getWallet(): Observable<any> {
    return this.httpClient.get(`${this.API1}/portal/wallet`, this.authHeader());
  }

  getCod(): Observable<any> {
    return this.httpClient.get(`${this.API1}/portal/cod`, this.authHeader());
  }

  getCodRemmitance(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/GetPaidCODByCustomer`,
      this.authHeader()
    );
  }

  getPickupRequest(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/getpreshipmenthistory`,
      this.authHeader()
    );
  }

  getShipmentTracking2(waybill: number): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/${waybill}`,
      this.authHeader()
    );
  }


  getShipmentTracking(waybill:number):Observable<any>{
    return this.httpClient.get(`${this.API1}/portal/trackshipment/${waybill}`,this.authHeader())
  }

  getInvoice(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/invoice`,
      this.authHeader()
    );
  }

  getVerifyPayment(referenceCode: any): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/verifypayment/${referenceCode}`,
      this.authHeader()
    );
  }

  getByWaybill(waybill: number): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/bywaybill/${waybill}`,
      this.authHeader()
    );
  }

  getPickupRequestDetails(waybill: number): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/getpreshipmentmobiledetails/${waybill}`,
      this.authHeader()
    );
  }

  getUserProfile(userid): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/user/${userid}`,
      this.authHeader()
    );
  }

  //get banks
  gatewayCode(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/gatewaycode`,
      this.authHeader()
    );
  }

  initiateUSSD(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/portal/initiatepaymentusingussd`,
      payload,
      this.authHeader()
    );
  }

  addwalletpaymentlog(payload: any): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/portal/addwalletpaymentlog`,
      payload,
      this.authHeader()
    );
  }
  getimageUpload(formData): Observable<any> {
    return this.httpClient.post<any>(
      this.SERVER_URL,
      formData,
      this.authHeader()
    );
  }
  getServiceCenter(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/getStations`,
      this.authHeader()
    );
  }
  getDeliveryOptions(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/deliveryoption`,
      this.authHeader()
    );
  }

  getProcessTerminalShipment(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/outstandingpayments`,
      this.authHeader()
    );
  }
  getHaulage(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/haulage`,
      this.authHeader()
    );
  }
  getVat(): Observable<any> {
    return this.httpClient.get(`${this.API1}/portal/vat`, this.authHeader());
  }
  getInsurance(): Observable<any> {
    return this.httpClient.get(
      `${this.API1}/portal/insurance`,
      this.authHeader()
    );
  }
  addwaybillpaymentlog(payload: any): Observable<any> {
    return this.httpClient.post(`${this.API1}/portal/addwaybillpaymentlog`, payload, this.authHeader())
  }
  getUpgradeToEcommerce(payload:any): Observable<any>{
    return this.httpClient.post(`${this.API1}/portal/upgradetoecommerce`, payload,this.authHeader())
  }
}
