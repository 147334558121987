<div class="overlays" *ngIf="spinning">
    <div class="centers">
        <div class="spinner-grow spin" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
<div class="container-class">
    <div class="container-content">
        <div class="page-text">
            <h2>Transaction History Detail</h2>
        </div>
        <div class="request-detail">
            <div class="request-detail-one">
                <div class="pickup-table">
                    <table>
                        <tr>
                            <td>Waybill</td>
                            <td>{{ waybillNumber }}</td>
                        </tr>
                   
                        <tr>
                            <td>Pickup Options</td>
                            <td>{{pickupOptions}}</td>
                        </tr>
                        <tr>
                            <td>Shipment Status</td>
                            <td>{{shipmentStatus}}</td>
                        </tr>
                        <tr>
                            <td>Delivery Code</td>
                            <td>{{receiverCode}}</td>
                        </tr>
                        <!-- <tr>
                            <td></td>
                            <td><img width="100"    object-fit="contain" src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/20201127141355_1348002344AI.png" alt=""></td>
                        </tr> -->
                        <!-- <tr>
                            <td>Total</td>
                            <td>{{currencySymbol}}{{ total | number }}</td>
                        </tr> -->
                        <tr>
                            <td>Grand Total</td>
                            <td>{{currencySymbol}}{{ grandTotal | number }}</td>
                        </tr>
                        <tr>
                            <td>Value</td>
                            <td>{{currencySymbol}}{{ value | number }}</td>
                        </tr>
                        <tr *ngIf="showAmount">
                            <td>Cash On Delivery</td>
                            <td>{{currencySymbol}}{{ cashOnDelivery | number }}</td>
                        </tr>
                        
                        <tr>
                            <td>Date Created</td>
                            <td>{{ date | date }}</td>
                        </tr>
                    </table>
                </div>
                <div class="shipment-items">
                    <div class="table">
                        <table mat-table [dataSource]="dataSource">
                            <!-- Position Column -->
                            <ng-container matColumnDef="position">
                                <mat-header-cell *matHeaderCellDef>SN</mat-header-cell>
                                <mat-cell *matCellDef="let element; let i = index">
                                    {{ i + 1 }}
                                </mat-cell>
                            </ng-container>
                            <!-- items column -->
                            <ng-container matColumnDef="description">
                                <mat-header-cell *matHeaderCellDef>DESCRIPTION</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{ element.Description }}</mat-cell>
                            </ng-container>

                            <!-- Departure Column -->
                            <ng-container matColumnDef="nature">
                                <mat-header-cell *matHeaderCellDef>NATURE</mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    {{ element.ShipmentType }}</mat-cell>
                            </ng-container>

                            <!-- Destination Column -->
                            <ng-container matColumnDef="weight">
                                <mat-header-cell *matHeaderCellDef>WEIGHT
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">{{ element.Weight }}kg
                                </mat-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                        </table>
                    </div>
                </div>
            </div>
            <div class="request-detail-two">
                <div class="customer-information">
                    <div class="customer-information-header">
                        <span>Customer Information</span>
                    </div>
                    <div class="customer-information-table">
                        <table>
                            <tr>
                                <td>Name</td>
                                <td>{{ customerName }}</td>
                            </tr>
                            <!-- <tr>
                                <td>Email</td>
                                <td></td>
                            </tr> -->
                            <tr>
                                <td>Phone Number</td>
                                <td>{{ customerNumber }}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{{ customerAddress }}</td>
                            </tr>
                            <tr>
                                <td>State</td>
                                <td>{{customerState}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="receiver-information">
                    <div class="receiver-information-header">
                        <span>Receiver Information</span>
                    </div>
                    <div class="customer-information-table">
                        <table>
                            <tr>
                                <td>Name</td>
                                <td>{{ receiverName }}</td>
                            </tr>
                            <!-- <tr>
                                <td>Email</td>
                                <td>{{ receiverEmail }}</td>
                            </tr> -->
                            <tr>
                                <td>Phone Number</td>
                                <td>{{ receiverNumber }}</td>
                            </tr>
                            <tr>
                                <td>Address</td>
                                <td>{{ receiverAddress }}</td>
                            </tr>
                            <tr>
                                <td>State</td>
                                <td>{{ receiverState }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <!-- <div class="shipment-description">
                    <div class="shipment-description-header">
                        <span>Shipment Description</span>
                    </div>
                    <div class="shipment-description-table"></div>
                </div> -->
            </div>
        </div>
    </div>
</div>