import { Injectable } from '@angular/core';
import { Login } from './../models/login';
import { Config } from '../models/utility';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public API2 = 'https://agilitysystemapidevm.azurewebsites.net/api';
  public API1 = 'https://mobile.gigl-go.com/api';
  public API3 ='https://nodeapi.gigl-go.com/apimerchant' // dev zimble
  constructor(private httpClient: HttpClient) {}
  public getLogin(loginModel: Login): Observable<any> {
    return this.httpClient.post(
      `${this.API1}/portal/customerLogin`,
      loginModel
    );

  }
  public getCountry(): Observable<any> {
    return this.httpClient.get(
      `${this.API3}/country`,
    );
  }
  public verifyMerchant(body: any): Observable<any> {
    return this.httpClient.post(
      `${this.API3}/merchant/verify`, body
    );
  }
  
}

