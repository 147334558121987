import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // checks local storage if access token is there
  public isAutenticated() {
    return localStorage.getItem('accessToken');
  }
  constructor() {}
}
