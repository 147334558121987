export class AuthHelpers {
  // saves access token to local storage
  setAccessToken(key: any, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }
  //   returns saved access token
  getAccessToken(key: any) {
    return JSON.parse(localStorage.getItem(key));
  }
}
