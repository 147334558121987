<div class="container-class">
  <div class="container-content">
    <div class="page-text">
      <h2>SLA Detail</h2>
    </div>
    <div class="sla-table display-tbl">
      <div class="sla-table-container">
        <div class="sla-table-content table-content"></div>
      </div>
    </div>
  </div>
</div>
