<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="apiError">
  <div class="password-message">
    <div class="message">{{errorMessage}}</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div>
<div class="container-class transaction">
  <div class="container-content transaction-content">
    <div class="display-card transaction-options">
      <h4 class="transaction-text">Search By Transaction Date</h4>

      <div class="transaction-input-content">
        <div class="transaction-input-container">
          <label for="">Start Date:</label>
          <input
            class="input-class"
            type="date"
            [(ngModel)]="transactionModel.StartDate"
          />
        </div>
        <div class="transaction-input-container">
          <label for="">End Date:</label>
          <input
            class="input-class"
            type="date"
            [(ngModel)]="transactionModel.EndDate"
          />
        </div>
        <div class="transaction-input-container">
          <button class="button button-submit" (click)="getTransactionByDate()">
            SEARCH
          </button>
        </div>
      </div>
    </div>
    <div class="function-button">
      <div class="table-buttons">
        <span
          ><button
            class="table-button"
            printSectionId="print-section"
            ngxPrint
            [useExistingCss]="true"
          >
            <i class="fas fa-print"></i>Print
          </button></span
        >
        <span
          ><button
            class="table-button"
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Transaction History',
                sheet: 'sheet_name',
                Props: { Author: 'GIGL' }
              })
            "
          >
            <i class="far fa-file-excel"></i>Excel
          </button></span
        >
        <!-- <span
          ><button class="table-button">
            <i class="far fa-file-pdf"></i>PDF
          </button></span
        > -->
      </div>
    </div>
    <div class="table-content transaction-table">
      <div class="transaction-table-content-header table-header"></div>
      <div class="transaction-table-content">
          <div class="table-filter-container">
            <div class="table-filter">
              <i class="fas fa-search"></i>
              <input class="filter-line" matInput (keyup)="applyFilter($event)" placeholder="Search" />
            </div>
          </div>
        <div class="table">
          <table
            id="print-section"
            mat-table
            matTableExporter
            #exporter="matTableExporter"
            [dataSource]="dataSource"
            hiddenColumns="[9]"
          >
            <!-- Position Column -->
            <ng-container matColumnDef="position">
              <mat-header-cell *matHeaderCellDef>SN</mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index">
              {{(paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1)}}
              </mat-cell>
            </ng-container>
            <!-- items column -->
            <ng-container matColumnDef="waybill">
              <mat-header-cell *matHeaderCellDef>WAYBILL</mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Waybill">
                {{ element.Waybill }}
              </mat-cell>
            </ng-container>

            <!-- Departure Column -->
            <ng-container matColumnDef="departure">
              <mat-header-cell *matHeaderCellDef>SENDER ADDRESS</mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Departure">
                {{ element.SenderAddress }}
              </mat-cell>
            </ng-container>

            <!-- Destination Column -->
            <ng-container matColumnDef="destination">
              <mat-header-cell *matHeaderCellDef>DESTINATION</mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Destination">
                {{ element.ReceiverAddress }}
              </mat-cell>
            </ng-container>


            <ng-container matColumnDef="description">
              <mat-header-cell *matHeaderCellDef>DESCRIPTION</mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Destination">
                {{ element.PreShipmentItems[0].Description }}
              </mat-cell>
            </ng-container>
            <!-- Amount column -->
            <ng-container matColumnDef="amount">
              <mat-header-cell *matHeaderCellDef>AMOUNT</mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Amount">
                {{currencySymbol}} {{ element.GrandTotal | number }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="receivername">
              <mat-header-cell *matHeaderCellDef>RECEIVER NAME</mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="receivername">
                 {{  element.ReceiverName  }}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="receiverphonenumber">
              <mat-header-cell *matHeaderCellDef>PHONE NUMBER</mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="receivernamephoneumber">
                 {{  element.ReceiverPhoneNumber  }}
              </mat-cell>
            </ng-container>

            ReceiverPhoneNumber
            <!-- options column -->
            <!-- date column -->
            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef>DATE</mat-header-cell>
              <mat-cell *matCellDef="let element" data-label="Date">
                {{ element.DateCreated | date }}
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="button">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let element; let i = index" class="track">
                <span class="print-button"
                  ><i class="fas fa-print" (click)="getWaybill(paginatorRef.pageIndex * paginatorRef.pageSize + (i))"></i
                ></span>
                <a [routerLink] = "['/mainpage/shipmenttracking',  element.Waybill]" class="track-button">
                  <i
                    class="fas fa-map-marker-alt"
                    
                  ></i>
                  <span class="track-text">Track</span>
                 
                </a>
                <a [routerLink]="['/mainpage/transactionhistorydetail', element.Waybill]">
                <span class="more-details"><i class="far fa-eye"></i></span>
                </a>
               
              </mat-cell>
            </ng-container>

            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
          </table>
          <mat-paginator
            #paginatorRef
            [pageSizeOptions]="[10, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
