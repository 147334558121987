
<section class="step"  *ngIf="isSubscription !== 'No'">
  <div class="overlay" *ngIf="apiError">
    <div class="errorMessage">
      <div class="ShowErrormessage">{{errorMessage}}
        <p> <button class="error_btn" (click)="closePopUp()">Ok</button></p>
      </div>
     
    </div>
  </div>
  <app-spinner *ngIf="isLoading"></app-spinner>
    <div class="innersection container" *ngIf="isSubscription == 'active' || isSubscription == 'pending'" >
      <div class="col-md-8 mt-4 mb-4 p-0 ">
        <p class="_main_sub_heading _bold p-0 m-0">Subscription & Payment</p>
        <p class="_inner_sub_heading p-0 m-0">Keep track of your subscription and payment</p>
      </div>
      
    </div>
    <div class="innersection container mt-3" *ngIf="isSubscription == 'active' && isCancelledUser !== true " >
      <div class="col-md-8 al_bg_color">
      <div class="inner_innersection">
        <p class="m-0 p-0 _inner_text_14 _bold">Billing Information</p>
        <p class="m-0 p-0 _inner_text_12 mt-4 mb-4"> Your next monthly payment of {{planData?.symbol}}{{planData?.price}} for GIGL Class is 
          scheduled for {{subDetail?.nextDueDate *1000 | date : "MM/dd/yyyy" }}. </p>
      </div> 
  
      <div class="inner_innersection mt-3">
        <p class="m-0 p-0 _inner_text_14 _bold">Manage Payment Details</p>
        <div class="row m-0 p-0 mt-2">
          <div class="col-xs-4 text-center _card_bg"><img class="_credit_img" src="../../assets/new-image/credit-card.png" alt=""></div>
          <div class="col col-md-6 position-relative">
            <p class="_inner_text_14 _bold"> {{cardDetail?.card_type}}***** {{cardDetail?.last4}}</p>
            <p class="_inner_text_14">EXP {{cardDetail?.exp_month}}/{{cardDetail?.exp_year}} </p>
            <img class="edit_card" data-toggle="modal" data-target="#myModal3" src="../../assets/new-image/edit pencil.png" alt="">
          
          </div>
         
        </div>
      </div>
      <div class="inner_innersection mt-4">
        <p class="m-0 p-0 _inner_text_14 _bold">Manage Subscription</p>
        <p class="m-0 p-0 _inner_text_12 mt-4 mb-4">You’re currently on GIGL Class subscription until {{subDetail?.nextDueDate * 1000 |date : "MM/dd/yyyy" }}. </p>
        <p class=" _inner_text_12 succuss_payment _bold">Successful payment <span> <img class="succ_check_img" src="../../assets/new-image/square checkbox solid.png" alt=""></span> </p>
        <p class="_canel_bold _cursor"  data-toggle="modal" data-target="#myModal1"  >CANCEL SUBSCRIPTION</p>
      </div> 
      </div>
      
    </div>
    <div class="innersection container mt-3" *ngIf="isSubscription == 'active' && isCancelledUser == true ">
      <div class="col-md-8 al_bg_color">
        <div class="inner_innersection">
          <p class="m-0 p-0 _inner_text_14 _bold">Billing Information</p>
          <p class="m-0 p-0 _inner_text_12 mt-4 mb-4"> Your subscription has been cancelled, however your class merchant subscription is still active until {{subDetail?.nextDueDate * 1000 | date : "MM/dd/yyyy" }}. if you wish to restore your subscription, you can select the RESTORE SUBSCRIPTION link below. </p>
        </div>
    
        <div class="inner_innersection mt-3">
          <p class="m-0 p-0 _inner_text_14 _bold">Manage Payment Details</p>
          <div class="row m-0 p-0 mt-2">
            <div class="col-xs-4 text-center _card_bg"><img class="_credit_img" src="../../assets/new-image/credit-card.png"
                alt=""></div>
            <div class="col col-md-6 position-relative">
              <p class="_inner_text_14 _bold"> {{cardDetail?.card_type}}***** {{cardDetail?.last4}}</p>
              <p class="_inner_text_14">EXP {{cardDetail?.exp_month}}/{{cardDetail?.exp_year}} </p>
              <img class="edit_card" data-toggle="modal" data-target="#myModal3"
                src="../../assets/new-image/edit pencil.png" alt="">
    
            </div>
    
          </div>
        </div>
        <div class="inner_innersection mt-4">
          <p class="m-0 p-0 _inner_text_14 _bold">Manage Subscription</p>
          <p class="m-0 p-0 _inner_text_12 mt-4 mb-4">You’re still an active GIGL Class merchant until
            {{subDetail?.nextDueDate * 1000 |date : "MM/dd/yyyy" }}. </p>
          <p class=" _inner_text_12 cancel-subscription succuss_payment _bold">Subscription Cancelled <span> <img class="succ_check_img"
                src="../../assets/new-image/square checkbox solid.png" alt=""></span> </p>
          <p class="_canel_bold _cursor" data-toggle="modal" data-target="#myModal5">RESTORE SUBSCRIPTION</p>
        </div>
      </div>
    
    </div>
    <div class="innersection container mt-3" *ngIf="isSubscription == 'pending'">
      <p class="pending_status">Your Subscription is pending. Please check after a few minutes</p>

    </div>

  </section>
<section class="plan"  *ngIf="isSubscription == 'No'">
  <div class="overlay" *ngIf="apiError">
    <div class="errorMessage">
      <div class="ShowErrormessage">{{errorMessage}}
        <p> <button class="error_btn" (click)="closePopUp()">Ok</button></p>
      </div>
     
    </div>
  </div>
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="innersection container">
    <div class="col-md-8 offset-md-2" *ngIf="planData?.isClassAvailable == true">
      <div class="row m-0 p-0">
        <div class="col-md-12 col-lg-12 mb-5">
          <div class="plan_heading text-center mt-5">
            <p class="plan_hd_1 ">Choose an <span class="_bold">e-commerce merchant</span> plan</p>
            <p class="_inner_text_14">Opting for an e-commerce merchant plan is a great way to provide excellent services to your customers irrespective of the size of your business (small, medium or large). At GIGL we have the <span class="bold-text">Basic</span>  and <span class="bold-text">Class</span> e-commerce merchant plans. However, if you want to serve more customers efficiently and maximize , subscribing to the GIG  <span class="bold-text">CLASS</span> plan is the better option.</p>
          </div>
          <form action="">
            <div class="row">
              <div class="col-md-12 offset-md-3 col-lg-6 mt-4">
                <div class="outer_box _add_shadow">
                  <div class="inner_box">
                    <p class="plan_hd_30 mt-3"><img src="../../../assets/new-image/class_icon.png" alt=""></p> 
                    <p class="_inner_text_14 recommend-text">(Recommended)</p>
                  </div>
                  <div class="inner_out">
                    <div class="inner_heading text-center">
                      <p class="_inner_text_16 mt-5 mb-5 _bold" style="color: #020607;">Benefits</p>
                    </div>
                    <ul class="class-recommendation">
                      <li>  <span class="_cl_green"> 15% </span>  off all shipping cost</li>
                      <li>Free insurance cover</li>
                      <li>Priority Shipping</li>
                      <li>No charge on COD(Cash on Delivery)</li>
                      <li>Free bulk pick up for interstate deliveries</li>
                      <li>E-commerce business growth kit</li>
                      <li>A dedicated account officer</li>
                    </ul>
                    <div class="text-center">
                      <span>{{planData?.symbol}}</span> <span class="_bold"> {{planData?.price}}</span>
                      <p>
                         <button
                         class="input_btn_subscibe mt-4 mb-5"
                         data-toggle="modal" data-target="#myModal2" 
                         >UPGRADE</button></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-md-8 offset-md-2" *ngIf="planData?.isClassAvailable == false">
      <div class="row m-0 p-0">
        <div class="col-md-12 col-lg-12 mb-5">
         <p>No Subscription Plan  Available</p>
        </div>
      </div>
    </div>
  </div>
</section>


  


  <div class="modal fade" id="myModal1">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close cl_btn _add_shadow" data-dismiss="modal" aria-label="Close">
            <span class="cross_" aria-hidden="true">&times;</span>
          </button>
        </div>
      
        <!-- Modal Header -->
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row m-0 p-0 col-md-12 text-center justify-content-center">
             <p> Are you sure you want to cancel your subscription</p>
            </div>
            <div class="row offset-md-4 col-md-4 text-center mt-4 mb-4">
              <button class="input_btn canelBtn" data-dismiss="modal" (click)="CancelSub()" >Confirm</button>
            </div>
  
          </div>
      </div>
  
      </div>
    </div>
  </div>
  <div class="modal fade" id="myModal2">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close cl_btn _add_shadow" data-dismiss="modal" aria-label="Close">
            <span class="cross_" aria-hidden="true">&times;</span>
          </button>
        </div>
      
        <!-- Modal Header -->
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row col-md-12 text-center" *ngIf="isBVN == false" >
              <label>Enter BVN</label>
              <input class="al_input" (input)="validateBVN()" [(ngModel)]="BVN" (keypress)="onlyNumberKey($event)"  maxlength="11" [ngClass]="{ invalid_: isVerified == false }"  type="text">
              <p class="_text">**This is crucial and required for validation purpose only.</p>
              <div class="errorShow" *ngIf="showError" > Invalid BVN</div>
            </div>
            <div class="row col-md-12 justify-content-center" *ngIf="isBVN == true" >
              
              <p class="_text">Are you sure you want to updgrade Your Plan</p>
            </div>
            <div class="row offset-md-4 col-md-4 text-center mt-4 mb-4">
              <button class="input_btn canelBtn" data-dismiss="modal"  [disabled]="isVerified !== true" (click)="upgradePlan()" >Confirm</button>
            </div>
  
          </div>
      </div>
  
      </div>
    </div>
  </div>
  <div class="modal fade" id="myModal3">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close cl_btn _add_shadow" data-dismiss="modal" aria-label="Close">
            <span class="cross_" aria-hidden="true">&times;</span>
          </button>
        </div>
      
        <!-- Modal Header -->
        <div class="modal-body">
          <div class="container-fluid">
            <p class="_text text-center">{{planData?.editCardMessage}}</p>
            <div class="row offset-md-4 col-md-4 text-center mt-4 mb-4">
              <button class="input_btn canelBtn" data-dismiss="modal" (click)="EditCardDetail('class')">Change Card</button>
            </div>
  
          </div>
      </div>
  
      </div>
    </div>
  </div>
  <div class="modal fade" id="myModal5">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close cl_btn _add_shadow" data-dismiss="modal" aria-label="Close">
            <span class="cross_" aria-hidden="true">&times;</span>
          </button>
        </div>
  
        <!-- Modal Header -->
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row m-0 p-0 col-md-12 text-center justify-content-center">
              <p> Are you sure you want to restore your payment subscription?</p>
            </div>
            <div class="row offset-md-4 col-md-4 text-center mt-4 mb-4">
              <button class="input_btn canelBtn" data-dismiss="modal" (click)="RenewSub()">Restore</button>
            </div>
  
          </div>
        </div>
  
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf='payment'>
    <div class="validation-message">
      <div class="close-payment-popup"><i class="far fa-times-circle" (click)="closePaymentOptions()"></i></div>
      <div class="message-text">
        <div class="payment-pop-up-message">Pay With</div>
      </div>
      <div class="image-container">
        <div class="paystack-image">
          <img
            src="https://res.cloudinary.com/gig-logistics/image/upload/v1613385787/Customer%20Portal/paystack_bqmyjl.png"
            alt="" (click)="payWithPaystack()">
        </div>
        <div class="flutterwave-image">
          <img
            src="https://res.cloudinary.com/gig-logistics/image/upload/v1613385788/Customer%20Portal/flutterwave_hrenda.png"
            alt="" (click)="payWithFlutterWave()">
        </div>
      </div>

    </div>
  </div>