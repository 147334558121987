import { Component, OnInit } from '@angular/core';
import { Login } from './../models/login';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';
import { AuthHelpers } from '../helpers/auth';
import { DataAccess } from '../helpers/dataAccess';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public LoginModel: Login = new Login();
  public authHelpers = new AuthHelpers();
  
  public dataAccess = new DataAccess();
  public errorMessage: string;
  visaCheck;
  visa;

  public returnUrl: string;
  customerName;
  currencyCode;
  currencySymbol;
  countryId;
  customerFullName;

  spinning: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private login: LoginService
  ) {

    // this.visa = this.route.snapshot.queryParams;
    // this.visaCheck = '' + this.route.snapshot.queryParams['visa'] + '';
   
  }

  public getLogin() {
    localStorage.clear();
    this.spinning = true;
    this.login.getLogin(this.LoginModel).subscribe(
      (data) => {
        this.spinning = true;
        if (data.Code == 200) {
          this.spinning = false;
          this.dataAccess.setLocalStorageValue('Selection', 0);
          this.customerName = data.Object.FirstName;
          this.customerFullName=data.Object.FirstName + ' ' + data.Object.LastName;
         this.dataAccess.setLocalStorageValue('CustomerName', this.customerName);
         this.dataAccess.setLocalStorageValue('CustomerFullName', this.customerFullName);
          this.currencyCode = data.Object.Country.CurrencyCode;
          this.currencySymbol = data.Object.Country.CurrencySymbol;
          this.countryId = data.Object.Country.CountryId;
          localStorage.setItem('currencyCode', this.currencyCode);
          localStorage.setItem('currencySymbol', this.currencySymbol);
          localStorage.setItem('Country', data.Object.Country.CountryCode);
          localStorage.setItem('countryId', this.countryId);
        
          
          const { access_token } = data.Object;
          //console.log(data.Object);
          

          this.dataAccess.setLocalStorageValue(
            'CustomerType',
            data.Object.UserChannelType
          );
          this.authHelpers.setAccessToken(
            'phoneNumber',
            data.Object.PhoneNumber
          );

          this.authHelpers.setAccessToken('email', data.Object.Email);
          this.authHelpers.setAccessToken('accessToken', access_token);
          this.authHelpers.setAccessToken('userId', data.Object.UserId);
          this.authHelpers.setAccessToken('userCode', data.Object.UserName);
          this.authHelpers.setAccessToken('firstName', data.Object.FirstName);
          this.authHelpers.setAccessToken('lastName', data.Object.LastName);

          this.authHelpers.setAccessToken('userChannelCode', data.Object.UserChannelCode);
          this.authHelpers.setAccessToken('rank', data.Object.Rank);

          this.authHelpers.setAccessToken(
            'isInternational',
            data.Object.IsInternational
          );
          if (access_token) {
            if (this.returnUrl !== '/') {
              this.router.navigateByUrl(this.returnUrl);
            } else {
              this.router.navigate(['mainpage']);
            }
          }
        } else {
          this.spinning = false;
          this.errorMessage = data.ShortDescription;
        }
      },
      (error) => {
        if (error.status == 0) {
          this.spinning = false;
          this.errorMessage = 'No internet connection';
        }
      }
    );
  }
  SubmitLogin(event)  {
    this.getLogin();
    event.preventDefault();
  }
  signUp() {
    this.router.navigate(['signup']);
  }
  forgotPassword() {
    this.router.navigate(['forgotpassword']);
  }
  ngOnInit(): void {
    if (this.visaCheck !== 'true') {
     this.router.navigate([''])
    }
    localStorage.clear();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    // this.dataAccess.setLocalStorageValue('Visa', this.visaCheck)
    
  }
}
