import { Component, OnInit ,ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCall } from '../services/api-call';
import { MatPaginator } from '@angular/material/paginator';
import { DataAccess } from '../helpers/dataAccess';
import { Router,ActivatedRoute } from '@angular/router';
import { TrackingWaybillApi } from './track-by-waybill-api';
@Component({
  selector: 'app-track-by-waybill',
  templateUrl: './track-by-waybill.component.html',
  styleUrls: ['./track-by-waybill.component.scss']
})

export class TrackByWaybillComponent implements OnInit {
  apiError: boolean = false;
  errorMessage;
  trackingData;
  tracking: boolean = false;
   dataAccess = new DataAccess();
  spinning: boolean = false;
  dataSource: MatTableDataSource<any>;
  waybill;
  shipmentWaybill;
  displayWaybill;
  destinationAddress;
  departureAddress;
   waybillNumberInvoice: any;
   // responsible for table column
  displayedColumns: string[] = [
    'position',
    'waybill',
    'date',
    'time',
    'location',
    'status',
    
  ];
   // paginator
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
 
  constructor(private apiCall: ApiCall, private router: Router,private route:ActivatedRoute,private trackingWaybillApi:TrackingWaybillApi ) {
    this.route.params.subscribe(params=>this.shipmentWaybill = params.waybill)
  }
  closePopUp() {
    this.apiError = false;
  }
  
  trackShipment() {
    this.spinning = true;
    this.router.navigate([`/mainpage/shipmenttracking/${this.waybill}`])
    this.trackShipmentWith(this.waybill)
  
  }
   trackShipmentWith(waybill) {
    this.spinning = true;
   
    this.trackingWaybillApi.getShipmentTracking(waybill).subscribe((data) => {
      this.spinning = true;
      if ((data.Code == 200)) {
        this.spinning = false;
        this.tracking = true;
        this.trackingData = data.Object.MobileShipmentTrackings;
        this.displayWaybill=data.Object.MobileShipmentTrackings[0].Waybill;
        this.destinationAddress=data.Object.Destination;
        this.departureAddress=data.Object.Origin;
        waybill.substring(0,3)==='AWR' ?  this.departureAddress='Houston, USA': null
       
        
                
      } else {
        this.spinning = false;
        this.apiError = true;
        this.errorMessage = data.ShortDescription;
      }
      this.dataSource = new MatTableDataSource(this.trackingData);
    });
  }
  // stores waybill number and navigates to print invoice page
  getWaybill(invoiceId) {
    this.waybillNumberInvoice = this.trackingData[invoiceId].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      'waybillNumberInvoice',
      this.waybillNumberInvoice
    );
    this.router.navigate(['/mainpage/printInvoice']);
  }
  ngOnInit(): void {
    this.trackShipmentWith(this.shipmentWaybill);
  }

}
