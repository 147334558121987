
<div class="overlays" *ngIf="spinning">
    <div class="centers">
      <div class="spinner-grow spin" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
</div>

<div class="overlays" *ngIf="openPopUp">
    <div class="password-message">
      <div class="message">{{succesfulUpload.ShortDescription}}</div>
      <button *ngIf="succesfulUpload.Code == 200" (click)='reload()' >Ok</button>
      <button *ngIf = "succesfulUpload.Code !=200" (click)='closePopUp()'>close</button>
    </div>
  </div>
<div class="overlays" *ngIf="errorPopUp">
    <div class="password-message">
      <div class="message">Kindly Login again</div>
      <button (click)='returnToLogin()'>Ok</button>
    </div>
  </div>
<div class="overlays" id="validatepaymentpage">
    <div class="password-message">
      <div class="message">Payment Successful</div>
      <button (click)='verifyPayment()'>Ok</button>
    </div>
  </div>
<div class="overlays" *ngIf="openPopup">
    <div class="password-message payment-option-popup">
        <div class="message">Select Payment Option</div>
        <span class="close-popup" (click)="closePaymentPopup()">X</span>
        <div class="payment-buttons">
            <span class="button1 payment-option">
                <button (click)= "processPayment()">Pay with Wallet</button>
            </span>
            <span class="button2 payment-option"><button (click)="activatePaymentOptions()" >Pay with Card</button></span>
        </div>

    </div>
</div>
<div class="overlay" *ngIf='payment'>
    <div class="validation-message">
        <div class="close-payment-popup"><i class="far fa-times-circle" (click)="closePaymentOptions()"></i></div>
        <div class="message-text">
            <div class="payment-pop-up-message">Pay With</div>
        </div>
        <div class="image-container">
            <div class="paystack-image">
                <img src="https://res.cloudinary.com/gig-logistics/image/upload/v1613385787/Customer%20Portal/paystack_bqmyjl.png"
                    alt="" (click)="payWithCard(1)">
            </div>
            <div class="flutterwave-image">
                <img src="https://res.cloudinary.com/gig-logistics/image/upload/v1613385788/Customer%20Portal/flutterwave_hrenda.png"
                    alt="" (click)="payWithCard(0)">
            </div>
        </div>
    </div>
</div>

<div class="container-class">
    <div class="container-content">

        <div class="payment-container">
            <div class="payment-div" *ngIf="dataCode != 200">
                Waybill does not exist
            </div>
            <div class="payment-div" *ngIf="dataCode == 200 ">
                <div *ngIf="waybillDetails.PaymentStatus =='Paid' ">
                    <div class="payment-container-content">
                        <div class="text-center">Payment already made for the shipment with waybill: </div>
                        <div class="waybill"> {{waybill}}</div>
                    </div>
                </div>
                <div *ngIf="waybillDetails.PaymentStatus !='Paid' ">
                    <div class="payment-container-content margin-bottom">
                        <label>Waybill - </label>
                        <span>{{waybill}}</span>
                    </div>
                    <div class="payment-container-content amount-div"> 
                        <label>Amount</label>
                        <span>{{waybillDetails.Country.CurrencySymbol}} {{waybillDetails.Shipment.GrandTotal | number}}</span>
                    </div>
                </div>
                <div class="button-container" *ngIf="waybillDetails.PaymentStatus !='Paid'">
                    <button class="button-pay" (click)="openPaymentPopup()" >Pay</button>
                </div>
            </div>
           
        </div>
    </div>
</div>