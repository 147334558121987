import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCall } from '../services/api-call';
import { Router } from '@angular/router';
import { DataAccess } from '../helpers/dataAccess';
@Component({
  selector: 'app-pick-up-request-detail',
  templateUrl: './pick-up-request-detail.component.html',
  styleUrls: ['./pick-up-request-detail.component.scss'],
})
export class PickUpRequestDetailComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  waybillNumber;
  spinning: boolean = true;
  departure;
  destination;
  delivery;
  pickUp;
  total;
  grandTotal;
  value;
  vat;
  discount;
  invoiceDiscount;
  offInvoice;
  shipmentPackagePrice;
  date;
  customerName;
  customerEmail;
  customerNumber;
  customerAddress;
  customerState;
  receiverName;
  receiverEmail;
  receiverNumber;
  receiverAddress;
  receiverState;
  pickUpRequestdetailData;
  cashOnDelivery;
  showAmount = false;

  dataAccess = new DataAccess();
  // responsible for table column
  displayedColumns: string[] = ['position', 'description', 'nature', 'weight'];
  constructor(private router: Router, private apiCall: ApiCall) {}

  ngOnInit(): void {
    this.waybillNumber = this.dataAccess.getLocalStorageValue(
      'pickUpRequestNumber'
    );
    this.apiCall
      .getPickupRequestDetails(this.waybillNumber)
      .subscribe((data) => {
        if (data.Code == 200) {
          this.spinning = false;
          this.pickUpRequestdetailData = data.Object;
          this.customerName = this.pickUpRequestdetailData.SenderName;
          this.customerNumber = this.pickUpRequestdetailData.SenderPhoneNumber;
          this.customerAddress = this.pickUpRequestdetailData.SenderAddress;
          this.receiverName = this.pickUpRequestdetailData.ReceiverName;
          this.receiverEmail = this.pickUpRequestdetailData.ReceiverEmail;
          this.receiverNumber = this.pickUpRequestdetailData.ReceiverPhoneNumber;
          this.receiverState = this.pickUpRequestdetailData.ReceiverState;
          this.receiverAddress = this.pickUpRequestdetailData.ReceiverAddress;
          this.grandTotal = this.pickUpRequestdetailData.GrandTotal;
          this.total = this.pickUpRequestdetailData.Total;
          this.vat = this.pickUpRequestdetailData.Vat;
          this.cashOnDelivery = this.pickUpRequestdetailData.CashOnDeliveryAmount;
          this.discount = this.pickUpRequestdetailData.DiscountValue;
          this.invoiceDiscount = this.pickUpRequestdetailData.InvoiceDiscountValue_display;
          this.offInvoice = this.pickUpRequestdetailData.offInvoiceDiscountvalue_display;
          this.shipmentPackagePrice = this.pickUpRequestdetailData.ShipmentPackagePrice;
          this.date = this.pickUpRequestdetailData.DateCreated;
          this.value = this.pickUpRequestdetailData.Value;
          this.dataSource = new MatTableDataSource(
            this.pickUpRequestdetailData.PreShipmentItems
          );
          if (this.pickUpRequestdetailData.CashOnDeliveryAmount) {
            this.showAmount = true;
          } else {
            this.showAmount = false;
          }
        }
      });
  }
}
