import { ApiCall } from "../services/api-call";
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class ShipmentTransactionApi{
constructor(private apiCall:ApiCall,private httpClient:HttpClient) {}
   
baseUrl1=this.apiCall.API1;
baseUrl2=this.apiCall.API2;
baseHeader=this.apiCall.authHeader();

 getInternationalShipmentTerms():Observable<any>{
     return this.httpClient.get(`${this.baseUrl1}/portal/intlshipmentsmessage`,this.baseHeader)
 }
 getInternationalStores(id):Observable<any>{
     return this.httpClient.get(`${this.baseUrl1}/portal/storesbycountry/${id}`,this.baseHeader)
 }
getServiceCenterByCountry(id): Observable<any>{
      return this.httpClient.get(`${this.baseUrl1}/portal/servicecentresbycountry/${id}`,this.baseHeader)
  }
getInternationalShipment(payload): Observable<any>{
      return this.httpClient.post(`${this.baseUrl1}/portal/AddIntlShipmentTransactions`,payload,this.baseHeader)
   }
getStationByCountry(id): Observable<any>{
     return this.httpClient.get(
      `${this.baseUrl1}/portal/getstationsbycountry/${id}`,
      this.baseHeader
    );
}
getActiveCountries(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl1}/portal/getactivecountries`,
      this.baseHeader
    );
  }
updateInternationalProfile(value): Observable<any> {
    return this.httpClient.put(
      `${this.baseUrl1}/portal/profileinternationaluser`,
      value,
      this.baseHeader
    );
  }
  
  getIdentificationTypes(): Observable<any> {
    return this.httpClient.get(
      `${this.baseUrl1}/portal/identificationtypes`,
      this.baseHeader
    );
  }
}