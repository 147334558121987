import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ApiCall } from '../services/api-call';
import { MatPaginator } from '@angular/material/paginator';
import { DataAccess } from '../helpers/dataAccess';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pick-up-reequest',
  templateUrl: './pick-up-reequest.component.html',
  styleUrls: ['./pick-up-reequest.component.scss'],
})
export class PickUpReequestComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  pickUpRequestData;
  spinning: boolean = true;
  dataAccess = new DataAccess();
  waybillNumber;

  // paginator
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // responsible for table column
  displayedColumns: string[] = [
    'position',
    'waybill',
    'name',
    'submitted',
    'calculated',
    'status',
    'date',
    'button',
  ];
  constructor(private apiCall: ApiCall, private router: Router) {}
  pickupRequestDetail(i) {
    this.waybillNumber = this.pickUpRequestData[i].Waybill;
    // stores waybill number to local storage
    this.dataAccess.setLocalStorageValue(
      'pickUpRequestNumber',
      this.waybillNumber
    );
    this.router.navigate(['/mainpage/pickuprequestdetail']);
  }
  ngOnInit(): void {
    this.apiCall.getPickupRequest().subscribe((data) => {
      this.spinning = true;
      if (data.Code == 200) {
        this.spinning = false;
        this.pickUpRequestData = data.Object;
      }
      this.dataSource = new MatTableDataSource(this.pickUpRequestData);
    });
  }
}
