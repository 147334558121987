<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="overlays" *ngIf="submit">
    <div class="password-message">
        <div class="message">{{message}}</div>
        <button (click)='reload()'>Ok</button>
    </div>
</div>
<div class="overlay" *ngIf='payment'>
  <div class="validation-message">
    <div class="close-payment-popup"><i class="far fa-times-circle" (click)="closePaymentOptions()"></i></div>
    <div class="message-text">
      <div class="payment-pop-up-message">Pay With</div>
    </div>
    <div class="image-container">
      <div class="paystack-image">
        <img
          src="https://res.cloudinary.com/gig-logistics/image/upload/v1613385787/Customer%20Portal/paystack_bqmyjl.png"
          alt="" (click)="payWithCard(1)">
      </div>
      <div class="flutterwave-image">
        <img
          src="https://res.cloudinary.com/gig-logistics/image/upload/v1613385788/Customer%20Portal/flutterwave_hrenda.png"
          alt=""  (click)="payWithCard(0)">
      </div>
    </div>
  </div>
</div>
<div class="overlays" *ngIf="openPopup">
    <div class="password-message payment-option-popup">
        <div class="message">Select Payment Option</div>
        <span class="close-popup" (click)="closePaymentPopup()">X</span>
        <div class="payment-buttons">
        <span class="button1 payment-option">
          <button (click)="processPayment()">Pay with Wallet</button>
        </span>
      <span class="button2 payment-option" ><button (click)="activatePaymentOptions()">Pay with Card</button></span>
        </div>
        
    </div>
</div>
<div class="overlays" *ngIf="noTerminalShipment">
    <div class="password-message">
        <div class="message">You have no pending shipment</div>
        <button (click)='closePopUp()'>Ok</button>
    </div>
</div>
<div class="overlays" id="validatepaymentprocess">
  <div class="password-message">
    <div class="message">Payment Successful</div>
    <button (click)='verifyPayment()'>Ok</button>
  </div>
</div>

<div class="container-class">
  <div
    class="process-terminal-shipment"
    *ngFor="let transaction of processTerminalShipmentData; let i = index"
  >
    <div class="waybill-amount">
      <div class="terminal-waybill">Waybill: {{ transaction.Waybill }}</div>
      <div class="terminal-amount">
        Amount: {{ transaction.CurrencySymbol }}
        {{ transaction.Amount | number }}
      </div>
    </div>

    <div class="terminal-payment-button"><button (click)="openPaymentPopup(i)">pay</button></div>
  </div>
</div>

