import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  test = false;
  trial = true;
  testing;
  IndividualSignup($event) {}
  CompanySignup($event) {}
  toggleSignUpCompany() {
    this.test = true;
    this.trial = false;
  }
  toggleSignUpIndividual(event) {
    this.test = false;
    this.trial = true;
  }
  returnToLogin() {
    this.router.navigate(['login']);
  }
  constructor(private router: Router) {}

  ngOnInit(): void {}
}
