import { Component, OnInit } from '@angular/core';
import { ApiCall } from '../services/api-call';
import { DataAccess } from '../helpers/dataAccess';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  dataAccess = new DataAccess();
  userCode = this.dataAccess.getLocalStorageValue('userId');
  spinning: boolean = true;
  profileObj:any;
  customerName;
  customerAddress;
  customerEmail;
  customerCode;
  customerNumber;
  constructor(private apiCall: ApiCall) {}

  ngOnInit(): void {
    this.apiCall.getUserProfile(this.userCode).subscribe((data) => {
      if (data.Code == 200) {
       
        this.spinning = false;
        this.profileObj = data.Object;
        
      }
    });
  }
}
