<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="success">
  <div class="password-message">
    <div class="message">Successful. Please check your email</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div>
<!-- <div id="login-page-full">
  <div id="login-full-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-xs-12">
          <div id="login-box">
            <div id="login-box-holder">
              <div class="row">
                <div class="col-xs-12">
                  <header id="login-header">
                    <div id="login-logo">
                      <img src="./assets/images/GIGLogin.png" alt="" />
                      <img src="./assets/images/GIGLoginLine.png" alt="" />
                    </div>
                  </header>
                 
                  <div id="login-box-inner" class="ngdialog-theme-default">
                    <form
                      [formGroup]="resetPasswordForm"
                      (submit)="SubmitPassword($event)"
                    >
                      <div class="input-group">
                        <span class="input-group-addon"
                          ><i class="fa fa-user"></i
                        ></span>

                        <input
                          class="form-control"
                          name="Username"
                          type="text"
                          formControlName="email"
                          id="email"
                          placeholder="Email address"
                          [(ngModel)]="password.Email"
                          [class.inValid]="
                            resetPasswordForm.get('email').invalid &&
                            resetPasswordForm.get('email').touched
                          "
                        />
                      </div>
                      <div class="row">
                        <div class="col-xs-12">
                          <span class="submit-password">
                            <button
                              type="submit"
                              class="btn btn-danger col-xs-12"
                              [disabled]="!resetPasswordForm.valid"
                            >
                              Submit
                            </button></span
                          >

                          <div
                            style="
                              margin-top: 60;
                              text-align: center;
                              font-weight: 700;
                              color: red;
                            "
                          >
                            {{ errorMessage }}
                          </div>
                          <div class="success-message" *ngIf="success">
                            Successful. Please check your email
                          </div>
                          <div class="return-login" (click)="returnToLogin()">
                            return to login page
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

    
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="container-class login-page">
  <div class="container-content">
    <div class="image">
      <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/forgotPassword.png" alt="" />
    </div>
    <div class="content">
      <div class="login-form">
        <div class="logo">
          <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/GIGLogin.png" alt="" />
          <h3 class="header">Merchant Portal</h3>
        </div>
        <div class="form-group">
          <form
            action=""
            [formGroup]="resetPasswordForm"
            (submit)="SubmitPassword($event)"
          >
            <div class="flex">
              <label>Email Address</label>
              <input
                name="Username"
                type="text"
                formControlName="email"
                id="email"
                placeholder="Email address"
                [(ngModel)]="password.Email"
                [class.inValid]="
                  resetPasswordForm.get('email').invalid &&
                  resetPasswordForm.get('email').touched
                "
              />
            </div>

            <div class="button-container">
              <button type="submit" [disabled]="!resetPasswordForm.valid">
                RESET PASSWORD
              </button>
            </div>
            <div
              style="
                text-align: center;
                color: rgb(236, 62, 18);
                margin-top: 10px;
              "
            >
              {{ errorMessage }}
            </div>
            <div class="return-login">
              <span class="forgot" (click)="returnToLogin()"
                >Return to Login Page</span
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
