<div class="overlays" *ngIf="spinning">
  <div class="centers">
    <div class="spinner-grow spin" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
<div class="overlay" *ngIf="apiError">
  <div class="password-message">
    <div class="message">{{errorMessage}}</div>
    <button (click)="closePopUp()">Ok</button>
  </div>
</div>
<div class="overlays" id="validatepaymentweb">
  <div class="password-message">
    <div class="message">Payment Successful</div>
    <button (click)='verifyPayment()'>Ok</button>
  </div>
</div>
<div class="overlay" *ngIf='payment'>
  <div class="validation-message">
    <div class="close-payment-popup"><i class="far fa-times-circle" (click)="closePaymentOptions()"></i></div>
    <div class="message-text">
      <div class="payment-pop-up-message">Pay With</div>
    </div>
  <div class="image-container">
    <div class="paystack-image">
      <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/paystack (1).png"
        alt="" (click)="paymentLog(1)">
    </div>
    <div class="flutterwave-image">
      <img
        src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/flutterwave (1).png"
        alt="" (click)="paymentLog(0)">
    </div>
  </div>
  </div>
</div>

<!-- <div class="overlays centralize" *ngIf="ussdPay">
  <div class="ussd">
    <div class="line"><h3 class="text-center">Pay with USSD</h3></div>
    <div class="cover-bg">
      <div class="select-bank">
        <select
          (change)="onOptionsSelected($event)"
          [(ngModel)]="gatewaycode"
          id=""
        >
          <option value="">Select A Bank</option>
          <option
            [ngValue]="gateway.gateway_code"
            *ngFor="let gateway of gatewayCode"
          >
            {{ gateway.gateway_name }}</option
          >
        </select>
      </div>
      <div class="phone-number">
        <div>Phone Number</div>
        <input type="number" name="" placeholder="Enter phone number" id="" />
      </div>
      <div class="generate-button">
        <button class="btn button-submit" (click)="payWithUssd()">
          Generate
        </button>
      </div>
    </div>
    <div class="ussd-code">
      {{ ussdData }}
    </div>
    <h3 class="dial-info desktop-text">
      Dial this code your mobile phone to make payment
    </h3>
    <h3 class="dial-info mobile-text">Dial to make payment</h3>
  </div>
</div> -->

<div class="container-class wallet">
  <div class="container-content">
    <div class="wallet-welcome-message">
      <!-- <span class="wallet-welcome-name">Hello {{ walletOwnerName }}!</span
      > -->
      <span class="wallet-welcome-number"
        >Wallet Number: <span class="wallet-number">{{ walletNumber }}</span>
      </span>
    </div>
      <div class="wallet-terms-message">
        Funds credited to your wallet are non refundable and cannot be withdrawn. They shall be applied solely towards
          your transactions within GIGL Ecosystem.
      </div>
    <div class="wallet-cards">
      
      <div
        class="wallet-cards-item display-card wallet-card wallet-card-container new-color"
      >
        <span class="border-line2"></span>
        <div class="display-card-text flex-direction-change">
          <p>wallet Balance</p>
          <h2>{{ currencySymbol }}{{ walletBalance | number }}</h2>
        </div>
        <div class="flex-container">
          <div class="display-icon-container">
            <img src="https://agilityblobstorage.blob.core.windows.net/webappstoragedotnet-imagecontainer/customerportalimages/wallet-icon.png" alt="">
          </div>
        </div>
      </div>
      
      <div class="dashboard-cards-item default-width">
      
        <div class="fund-section">
           
          <div class="card-section-header">
            <h4>Fund Wallet</h4>
          </div>
          <div class="card-section-form">
            <div class="input-box">
              <input
                type="number"
                (keypress)="keyPress($event)"
                (keyup)="amountChange($event)"
                [(ngModel)]="amount"
                placeholder="Enter Amount"
              />
            </div>

            <div class="btn-box">
              <button (click)="activatePaymentOptions()" class="some-fund">Pay</button>
            </div>
           
          </div>
        <small class="wallet-error-message" style="color: rgb(236, 62, 18)">
          {{walletError}}
        </small>
        </div>
      </div>

      <div class="fund-amount" *ngIf="fundWallet">
        <input
          type="number"
          placeholder="Enter Amount"
          (keyup)="amountChange($event)"
          [(ngModel)]="amount"
        />
        <span (click)="fundWalletButton()"
          ><i class="fa fa-close wallet"></i
        ></span>
      </div>
      <!-- <div class="wallet-button">
        <span
          class="wallet-btn fund-wallet"
          *ngIf="fund"
          (click)="fundWalletButton()"
          >Fund your Wallet</span
        >
        <span class="wallet-btn pay" *ngIf="pay" (click)="paymentLog()">Fund Now</span>
      </div> -->
    </div>
    <!-- <div class="wallet-payment-options flex" *ngIf="paymentOptions">
      <div class="payment-card card-payment flex" (click)="paymentLog(0)">
        <i
          class="fa fa-credit-card-alt wallet"
          (click)="paymentLog(0)"
          aria-hidden="true"
        ></i>
        Pay with Card
      </div>
    </div> -->
    <div class="function-button">
      <div class="table-buttons">
        <span
          ><button
            class="table-button"
            printSectionId="print-section"
            ngxPrint
            [useExistingCss]="true"
          >
            <i class="fas fa-print"></i>Print
          </button></span
        >
        <span
          ><button
            class="table-button"
            (click)="
              exporter.exportTable('xlsx', {
                fileName: 'Wallet',
                sheet: 'sheet_name',
                Props: { Author: 'GIGL' }
              })
            "
          >
            <i class="far fa-file-excel"></i>Excel
          </button></span
        >
        <!-- <span
          ><button class="table-button">
            <i class="far fa-file-pdf"></i>PDF
          </button></span
        > -->
      </div>
    </div>
    <div class="Wallet-table display-tbl">
      <div class="Wallet-table-container">
        <div class="Wallet-table-content table-content">
          <div class="table-filter-container">
            <div class="table-filter">
              <i class="fas fa-search"></i>
              <input class="filter-line" matInput (keyup)="applyFilter($event)" placeholder="Search" />
            </div>
          </div>

          <div class="table">
            <table
              id="print-section"
              mat-table
              [dataSource]="dataSource"
              matTableExporter
              #exporter="matTableExporter"
            >
              <!-- Position Column -->
              <ng-container matColumnDef="sn">
                <mat-header-cell *matHeaderCellDef>SN</mat-header-cell>
                <mat-cell *matCellDef="let element; let i = index">
                {{(paginatorRef.pageIndex * paginatorRef.pageSize) + (i + 1)}}
                
                </mat-cell>
              </ng-container>
              <!-- date column -->
              <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef>DATE</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Date">
                  {{ element.DateOfEntry | date }}
                </mat-cell>
              </ng-container>

              <!-- service center Column -->
              <ng-container matColumnDef="center">
                <mat-header-cell *matHeaderCellDef
                  >SERVICE CENTER</mat-header-cell
                >
                <mat-cell *matCellDef="let element" data-label="Service Centre">
                  {{ element.ServiceCentre.Name }}
                </mat-cell>
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef>AMOUNT</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Amount">
                  {{ currencySymbol }}{{ element.Amount | number }}
                </mat-cell>
              </ng-container>
              <!-- Amount column -->
              <ng-container matColumnDef="credit">
                <mat-header-cell *matHeaderCellDef>CREDIT TYPE</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Credit Type">
                  {{ element.CreditDebitType }}
                </mat-cell>
              </ng-container>
              <!-- partner column -->
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>STATUS</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Status">
                  paid
                </mat-cell>
              </ng-container>
              <!-- date column -->
              <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef>DESCRIPTION</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Description">
                  {{ element.Description }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="payment">
                <mat-header-cell *matHeaderCellDef>PAYMENT</mat-header-cell>
                <mat-cell *matCellDef="let element" data-label="Payment">
                  {{ element.PaymentType }}
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="ref">
                <mat-header-cell *matHeaderCellDef>REFERENCE</mat-header-cell>
                <mat-cell *matCellDef="let element">
                  {{ element.PaymentTypeReference }}
                </mat-cell>
              </ng-container>

              <mat-header-row
                *matHeaderRowDef="displayedColumns"
              ></mat-header-row>
              <mat-row
                *matRowDef="let row; columns: displayedColumns"
              ></mat-row>
            </table>
            <mat-paginator
            #paginatorRef
              [pageSizeOptions]="[10, 50, 100]"
              showFirstLastButtons
            ></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
